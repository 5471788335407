import React, { useEffect, useState } from "react";
import { Row, Col, Input, Card, Button, Modal, message } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./Folders.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { AppUrl } from "../../../Constants/Constants";
import { checkElementUpdatePresence } from "../../../CommonFunctions/CommonFunctions";

const Folders = () => {
    const navigate = useNavigate();
    const [folders, setFolders] = useState([]);
    const [SearchInput, setSearchInput] = useState('')

    const userRole = sessionStorage.getItem("userrole");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [FolderName, setFOlderName] = useState("");
    const [APILOADING, setAPILOADING] = useState(false)
    const [Filtered, setFiltered] = useState([])
    const [Data, setData] = useState([]);
    const AUTH_TOKEN = useSelector((state) => state.authToken);
    const USER_DATA = useSelector((state) => state.user);
    const USER_ACCESS = useSelector(state => state.userAccess);

    console.log(USER_ACCESS, 'USER_ACCESS')

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const addObject = () => {
        let body = {
            company_id: USER_DATA?.company_id,
            folder_name: FolderName,
            folder_img_path: "string",
            user_id: USER_DATA?.user_id,
        };
        axios
            .post(`${AppUrl}Add_folders`, body, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: "application/json",
                },
            })
            .then((res) => {
                console.log(res);
                setAPILOADING(!APILOADING)
            })
            .catch((err) => {
                console.log(err);
            });

        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };




    useEffect(() => {
        axios
            .get(`${AppUrl}list_of_folders?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}`, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                if (res.data.status == 201) {
                    navigate('/sessiontimeout')
                } else {


                    if (res.data && res.data.folders) {
                        setData(res.data.folders);
                        setFiltered(res.data.folders)
                    } else {
                        setData([]); // Ensure Data is always an array
                    }
                }
            })
            .catch((err) => {
                message.error(err.message)
            })
    }, [APILOADING])

    const handleFileNavigate = (e) => {
        navigate(`/document/files/${e}`);
        // console.log(e, 'navigateId')
    };

    useEffect(() => {
        if (!SearchInput) {

            setFiltered(Data);
        } else {

            const filteredData = Data.filter((item) =>
                item.folder_name.toLowerCase().includes(SearchInput.toLowerCase()) // Match ignoring case
            );
            setFiltered(filteredData);
            console.log(filteredData, "filtered");
        }
    }, [SearchInput]);


    console.log(SearchInput, "SearchInput")

    return (
        <div className="foldersMainCLass">
            <Modal
                footer={false}
                title="Add New Folder"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Row>
                    <p className="Foldername">Folder Name</p>
                    <Input
                        className="FOlderNameInput"
                        placeholder="Enter Folder Name"
                        onChange={(e) => setFOlderName(e.target.value)}
                    />
                </Row>
                <Row className="buttonDiv">
                    <Col span={8}>
                        <Button onClick={handleCancel} className="userCancelBtn">
                            Cancel
                        </Button>
                    </Col>
                    <Col offset={1} span={8}>
                        <Button onClick={addObject} className="addNewUserBtn">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Modal>
            <Row>
                <Col span={10}>
                    <h3 className="sideMainHeading">Document</h3>
                </Col>
                <Col span={6}>
                    <Input placeholder="Search by document name"
                        onChange={(e) => setSearchInput(e.target.value)}
                        className="searchInput" />
                    <SearchOutlined className="inputSearch" />
                </Col>
                <Col span={4}>
                    <div className="filterClass">
                        <img src="/Icons/filter.svg" width={20} height={20} alt="" />
                        <h3 className="filterWord">Filter</h3>
                    </div>
                </Col>
                {
                    checkElementUpdatePresence(USER_ACCESS, 7) ?
                        <Col span={4}>
                            <Button onClick={showModal} className="addNewUserBtn">
                                <PlusOutlined />
                                Add New Folder
                            </Button>
                        </Col>
                        :
                        <></>
                }
            </Row>
            <Row className="folderRow">

                {Filtered.map((item) => (
                    <Col className="folderColumnClass" span={5} key={item.folder_id}>
                        <Card className="documentFolderCards">
                            <img src="/Icons/folder.svg" alt="" width={50} height={45.57} />
                            <h3 className="folderNameText">{item.folder_name}</h3>
                            <p className="createdTimeText">
                                Created on : <span>
                                    {new Date(item.create_date).toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: 'short', // 'short' gives the 3-letter month abbreviation (e.g., Oct)
                                        year: 'numeric',
                                    }).replace(/ /g, '-')} {/* Replaces spaces between date parts with dashes */}
                                    {' '}
                                    {new Date(item.create_date).toLocaleTimeString('en-GB', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                        hour12: false, // Use 24-hour format
                                    })}
                                </span>
                            </p>

                            <p className="cretaedTimeText">
                                Last Modified on : <span>{item.modified_at}</span>
                            </p>
                            <h3
                                className="folderOpenClass"
                                onClick={() => handleFileNavigate(item.folder_id)}
                            >
                                Open <ArrowRightAltIcon className="rightOpenArrow" />{" "}
                            </h3>
                        </Card>
                    </Col>
                ))}

            </Row>
        </div>
    );
};

export default Folders;
