import React, { useEffect, useState } from 'react'
import { Button, Col, Input, message, Modal, Row, Space, Table, Tag } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AppUrl } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';
import DocumentView from '../../Document/DocumentView/DocumentView';

const Archives = () => {
    const { Column } = Table;
    const navigate = useNavigate();
    let url = window.location.href;
    let splittedUrl = url.split('/')[4];
    console.log(splittedUrl, "url");

    const USER_DATA = useSelector((state) => state.user);
    const AUTH_TOKEN = useSelector((state) => state.authToken);

    const [archivedFileList, setArchivedFileList] = useState([]);
    const [fileViewModal, setFileViewModal] = useState(false);
    const [fileUrl, setFileUrl] = useState('');

    const handleAddUser = () => {
        navigate('/adduser')
    }
    const data = [
        {
            key: 1,
            sno: '1',
            username: [{ username: 'Material 1', date: '12-Mar-2024', image: '/Icons/Filepdfred.svg' }],
            type: "Portable Document Format",
            fileOwner: "john",
            archivedDate: '11-dec-2022',
            archivedBy: "dave"

        },
        {
            key: 2,
            sno: '2',
            username: [{ username: 'Material 2', date: '15-Jan-2024', image: '/Icons/RedVideo.svg' }],
            type: "Portable Document Format",
            fileOwner: "john",
            archivedDate: '11-dec-2022',
            archivedBy: "dave"
        },
        {
            key: 3,
            sno: '3',
            username: [{ username: 'Material 3', date: '11-Dec-2023', image: '/Icons/Filepdfred.svg' }],
            type: "Portable Document Format",
            fileOwner: "john",
            archivedDate: '11-dec-2022',
            archivedBy: "dave"

        },
        {
            key: 4,
            sno: '4',
            username: [{ username: 'Material 4', date: '12-Dec-2023', image: '/Icons/YellowPPt.svg' }],
            type: "Portable Document Format",
            fileOwner: "john",
            archivedDate: '11-dec-2022',
            archivedBy: "dave"
        },
        {
            key: 5,
            sno: '5',
            username: [{ username: 'Material 5', date: '26-Mar-2024', image: '/Icons/BlueJpgImg.svg' }],
            type: "Portable Document Format",
            fileOwner: "john",
            archivedDate: '11-dec-2022',
            archivedBy: "dave"

        },
        {
            key: 6,
            sno: '6',
            username: [{ username: 'Material 6', date: '12-Mar-2024', image: '/Icons/Filepdfred.svg' }],
            type: "Portable Document Format",
            fileOwner: "john",
            archivedDate: '11-dec-2022',
            archivedBy: "dave"

        },
        {
            key: 7,
            sno: '7',
            username: [{ username: 'Material 7', date: '15-Jan-2024', image: '/Icons/RedVideo.svg' }],
            type: "Portable Document Format",
            fileOwner: "john",
            archivedDate: '11-dec-2022',
            archivedBy: "dave"
        },
        {
            key: 8,
            sno: '8',
            username: [{ username: 'Material 8', date: '11-Dec-2023', image: '/Icons/Filepdfred.svg' }],
            type: "Portable Document Format",
            fileOwner: "john",
            archivedDate: '11-dec-2022',
            archivedBy: "dave"

        },
        {
            key: 9,
            sno: '9',
            username: [{ username: 'Material 9', date: '12-Dec-2023', image: '/Icons/YellowPPt.svg' }],
            type: "Portable Document Format",
            fileOwner: "john",
            archivedDate: '11-dec-2022',
            archivedBy: "dave"
        },
        {
            key: 10,
            sno: '10',
            username: [{ username: 'Material 10', date: '26-Mar-2024', image: '/Icons/BlueJpgImg.svg' }],
            type: "Portable Document Format",
            fileOwner: "john",
            archivedDate: '11-dec-2022',
            archivedBy: "dave"

        },
    ]

    useEffect(() => {
        viewArchivedFiles();
    }, [])

    const viewArchivedFiles = () => {
        axios.get(`${AppUrl}archieved_files_view?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}`, {
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                Accept: 'application/json'
            }
        })
            .then(res => {
                console.log(res.data.deleted_files, 'archieved_files_view');
                if (res.data.status === 200) {
                    setArchivedFileList(res.data.deleted_files);
                } else {
                    setArchivedFileList([]);
                }
            })
            .catch(err => {
                console.log(err, 'archieved_files_view Error');
                message.error(err.message);
            })
    }

    const HandleView = (record) => {
        setFileViewModal(true);
        console.log(record, 'recordDetails');
        setFileUrl(record.file_path);
    }

    const handleModalClose = () => {
        setFileViewModal(false);
        setFileUrl('');
    }

    console.log(fileViewModal, 'fileViewModal')


    return (
        <div>
            <Modal className='Modal1' open={fileViewModal} 
            onOk={() => setFileViewModal(false)} 
            onCancel={handleModalClose} 
            footer={null} 
            >
                <DocumentView
                props={fileUrl}
                />
            </Modal>
            <Row>
                <Col span={14}>
                    <h3 className='sideMainHeading'>Archives</h3>
                </Col>
                <Col span={6}>
                    <Input placeholder='Search' className='searchInput' />
                    <SearchOutlined className='inputSearch' />
                </Col>
                <Col span={4}>
                    <div className='filterClass'>
                        <img src='/Icons/filter.svg' width={20} height={20} alt='' />
                        <h3 className='filterWord'>Filter</h3>
                    </div>
                </Col>

            </Row>
            <Row className='userManagementTableRow'>
                <Col span={24}>
                    <Table dataSource={archivedFileList} className='userManagementTable'>
                        <Column title="S no" dataIndex="s_no" key="s_no" align='center' />
                        <Column title="File Name" dataIndex="file_name" key="file_name" align='left' width='30%'
                            render={(_, record) => {
                                console.log(record, 'file_name')
                                return (
                                    <>
                                        <Row>
                                            {/* <Col span={4}>
                                                <img src={item.image} alt='Logo' />
                                            </Col> */}
                                            <Col span={12}>
                                                <h3 className='tableUsername'>{record.file_name}</h3>
                                                <p className='tableMail'>{record.created_at}</p>
                                            </Col>
                                        </Row>
                                    </>
                                )
                            }
                            }
                        />
                        <Column title="Archived Date" dataIndex="archieved_at" key="archieved_at" align='center' />
                        <Column title="Type" dataIndex="Archieve_type" key="Archieve_type" align='center' />
                        <Column title="Folder" dataIndex="folder_name" key="folder_name" align='center' />
                        <Column title="Archived By" dataIndex="archieved_by" key="archieved_by" align='center' />

                        <Column
                            title="Action"
                            key="action"
                            align='center'
                            render={(_, record) => (
                                <Space size="middle">
                                    <Button
                                        onClick={() => HandleView(record)}
                                        className='viewEditBtn'>
                                        View
                                    </Button>
                                </Space>
                            )}
                        />

                    </Table>
                </Col>
            </Row>
        </div>
    )
}

export default Archives