import { Button, Col, Input, Row, Select, Modal, message, Upload } from 'antd'
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './FileReview.css'
import axios from 'axios'
import Review from '../Document/Review/Review'
import { Stage, Layer, Line } from 'react-konva';
// import { Document, Page } from 'react-pdf';
// import { PDFViewer, Document, Page} from '@react-pdf/renderer';
import { Document, Page, pdfjs } from "react-pdf";


const AWS = require('aws-sdk');
const { PDFDocument, rgb } = require('pdf-lib')

const FileReview = () => {
    const { TextArea } = Input;

    const { id } = useParams();
    const { fileId } = useParams();

    const designationOptions = [
        {
            value: 'registered nurse',
            label: 'Registered Nurse',
        },
        {
            value: 'ot',
            label: 'Occupational Therapist',
        },
        {
            value: 'pharmacist',
            label: 'Pharmacist',
        },
        {
            value: 'dietitian',
            label: 'Dietitian',
        },
    ]


    const data1 = [
        {
            key: 1,
            sno: '1',
            username: [{ username: `Job Aid for Patient Navigators & Care Navigators for Opt-in/Opt-out`, date: '12-Mar-2024', image: '/Icons/Filepdfred.svg' }],
            status: ['Approved'],

        },
        {
            key: 2,
            sno: '2',
            username: [{ username: `JA-Brand Transfers Inquiries for Patient Support Center`, date: '15-Jan-2024', image: '/Icons/Filepdfred.svg' }],
            status: ['Approved'],
        }
    ]

    const data3 = [
        {
            key: 1,
            sno: '1',
            username: [{ username: `JA-Salesforce Case Comments and Chatter Functionalities for Patient Support Center`, date: '12-Mar-2024', image: '/Icons/Filepdfred.svg' }],
            status: ['Pending'],

        }
    ]



    const data2 = [
        {
            key: 1,
            sno: '1',
            username: [{ username: `WPD-CPS-PSC-004_Handling_Potential_Crisis_Calls9.1`, date: '12-Mar-2024', image: '/Icons/Filepdfred.svg' }],
            status: ['Approved'],

        },
        {
            key: 2,
            sno: '2',
            username: [{ username: `WPD-CPS-PSC-021 In-Person Welcome Visits for Drug C`, date: '15-Jan-2024', image: '/Icons/Filepdfred.svg' }],
            status: ['Approved'],
        }
    ]


    const [status, setStatus] = useState('Active');
    const [pdfUrl, setPdfUrl] = useState('https://preciumweb-dev.s3.amazonaws.com/demo/JA-063%2BBrand%2BTransfers%2B%2BInquiries%2Bfor%2BNovartis%2BPatient%2BSupport%2BCenter+(2).pdf');
    const [signatureImage, setSignatureImage] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [PageNumber, setPageNumber] = useState(0);
    const [file, setFile] = useState(null);
    const [SignedUrl, setSignedUrl] = useState('')
    const [Loading, setLoading] = useState(false)

    const [lines, setLines] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [ data, setData ] = useState( JSON.parse(sessionStorage.getItem('fileData')) || []);
    const canvasRef = useRef();
    const navigate = useNavigate();


    const s3 = new AWS.S3({
        accessKeyId: 'AKIAVMTVZLEFBXHWQL72',
        secretAccessKey: '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',
        region: 'us-east-1'
    });


    console.log( data, 'dataJson')

    const downloadPdfFromS3 = async (s3Url) => {
        try {
            const response = await axios.get(s3Url, { responseType: 'arraybuffer' });
            console.log(s3Url, 'originalPdf')
            // setPdfUrl(response.data)
            return response.data;
        } catch (error) {
            console.error('Error downloading PDF from S3:', error);
            throw error;
        }
    };



    const addImageToPdf = async (pdfBytes, imageData) => {
        try {
            const pdfDoc = await PDFDocument.load(pdfBytes);
            const image = await pdfDoc.embedPng(imageData);

            // Get the number of pages in the PDF
            const pageCount = pdfDoc.getPageCount();

            // Iterate through each page and add the image at the bottom
            for (let i = 0; i < pageCount; i++) {
                const page = pdfDoc.getPage(i);
                const { width, height } = page.getSize();
                page.drawImage(image, {
                    x: 2,
                    y: 1,
                    width,
                    height,
                });
            }

        

            // Save the modified PDF
            return await pdfDoc.save();
        } catch (error) {
            console.error('Error adding image to PDF:', error);
            throw error;
        }
    };


    const arrayBufferToBase64 = (arrayBuffer) => {
        const binary = new Uint8Array(arrayBuffer);
        const bytes = [];
        binary.forEach((byte) => {
            bytes.push(String.fromCharCode(byte));
        });
        return btoa(bytes.join(''));
    };



    async function isPdfDataValid(pdfBytes) {
        try {
            // Attempt to load the PDF document
            await PDFDocument.load(pdfBytes);
            console.log('the Given PDF is valid')
            // If loading succeeds, return true
            return true;
        } catch (error) {
            console.log('the Given PDF is not valid')
            // If loading fails, return false
            return false;
        }
    }

    useEffect(() => {
    if(file) uploadFileToS3(file)
    }, [file])

    const uploadFileToS3 = (dataURL) => {
      if(dataURL){

        const params = {
          Bucket: "preciumweb-dev",
          Key: `signature`,
          Body: dataURL,
        };

        return new Promise((resolve, reject) => {
          s3.upload(params, (err, data) => {
            console.log(err, data, "chan");
            if (err) {
              reject(err);


            } 
            else 
            {
              resolve(data);    
              let options = {
                'method': 'POST',
                'url': 'https://api.pdf.co/v1/pdf/edit/add',
                'headers': {
                  'Content-Type': 'application/json',
                  'x-api-key': 'patientsupport@valuehealthsol.com_LAAtd35rDae9ws8Osm1KO053615L5753Fcw2SMPqy1m6w4tCLOqw5CbefVVaN3h8'
                },
                'body':JSON.stringify({
                    "name": "result.pdf",
                    "password": "",
                    "url": "https://preciumweb-dev.s3.amazonaws.com/demo/JA-Salesforce+Case+Comments+and+Chatter+Functionalities+for+Patient+Support+Center.pdf",
                    "images": [{
                        "url": "https://preciumweb-dev.s3.amazonaws.com/signature",
                        "x": 500,
                        "y": 690,
                        "width": 100,
                        "height": 75,
                        "pages": ""
                    }]
                })
            }
            console.log(options)
              fetch(`https://api.pdf.co/v1/pdf/edit/add`,options)
              .then(response => response.json())
              .then((res) => {
                console.log(res,"chan")
                setSignedUrl(res.url)
                setIsModalVisible(false)
                setIsModalOpen(true);
                message.success('Sign Added Successfully!')
              })
              .catch((err) => {
                console.log(err)
              })




            }
          });
        //   setPreviewVisible(false);
        });
    }

      };

   


    console.log(file, "dataURL1")

    const generateImage = async () => {
        try {
          if (lines.length === 0) {
            console.log('Canvas is empty. No image generated.');
            message.error('Please Sign!');
            return;
          } else {
            const canvas = canvasRef.current.getStage().toCanvas();
            const context = canvas.getContext('2d');
            const dataURL = canvas.toDataURL();
            
            // Create an image object and set its source to the data URL
            const imageObj = new window.Image();
            imageObj.src = dataURL;
      
            // Wait for the image to load
            imageObj.onload = () => {
              // Draw the image onto the canvas
              context.drawImage(imageObj, 0, 0);
              
              // Convert the canvas content to a Blob
              canvas.toBlob((blob) => {
                // Set the Blob as the file state variable
                setFile(blob);
              });
            };
      
            // Optionally, you can perform additional actions here
          }
        } catch (error) {
          console.error('Error generating image:', error);
        }
      };
      
      const data4 = [
        {
            key: 1,
            sno: '1',
            username: [{ username: `JA-Salesforce Case Comments and Chatter Functionalities for Patient Support Center.pdf`, date: '12-Mar-2024', image: '/Icons/Filepdfred.svg' }],
            status: ['Approved'],

        }
    ]


      const handleFileApprove = () => {
        sessionStorage.setItem('newFolderItem', JSON.stringify(data4));
        sessionStorage.setItem('fileHide', true);
        navigate('/document')
      }


    const handleMouseDown = (event) => {
        setIsDrawing(true);
        const pos = event.target.getStage().getPointerPosition();
        setLines([...lines, { points: [pos.x, pos.y] }]);
    };

    const handleMouseMove = (event) => {
        if (!isDrawing || lines.length === 0) return;

        const stage = event.target.getStage();

        const point = stage.getPointerPosition();
        // console.log(point, 'mouseMoveStage')
        let lastLine = lines[lines.length - 1];

        if (!lastLine) return;

        lastLine.points = lastLine.points.concat([point.x, point.y]);

        setLines([...lines.slice(0, -1), lastLine]);
    };

    const handleMouseUp = () => {
        setIsDrawing(false);
    };

    const clearDrawing = () => {
        setLines([])
    }

    const handleModalCancel = () => {
        // Close the modal and reset state
        setIsModalVisible(false);
        setPdfUrl('');
        setLines([]);
        setSignatureImage(null);
    };

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };


    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    const [DOCURL, setDOCURL] = useState('https://preciumweb-dev.s3.amazonaws.com/dev/042e34e3-2d19-47ce-bd69-9532fe42d798/59296da0-9aa0-44c4-9ac5-34adcfc970b8/JA-020+Salesforce+Case+Comments+and+Chatter+Functionalities+for+Novartis+Patient+Support+Center.pdf')
    const showModal = (e, val) => {
        const updateFileName = DOCURL.replace(/ded2w7tw5rnvn.cloudfront.net/, 'preciumweb-dev.s3.amazonaws.com')

        if( id === '3' ){
            setDOCURL(updateFileName);
        } 
        else {
            const fileName = data.username[0].username;
            setDOCURL(`/pdf/${fileName}.pdf`)
        }
        setIsModalOpen(true);
    };

    console.log(data.username[0].username, 'fileDataReviewPage')
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel1 = () => {
        setIsModalOpen(false);
    };
    return (
        <div className='reviewClass' >
            <Modal
                className="Modal1"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel1}
                footer={null}
            >
                <div>
                    <Document
                    
                        file={SignedUrl? SignedUrl : DOCURL}
                        onLoadSuccess={onDocumentLoadSuccess}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                    >
                        {
                            PageNumber === 0 ? (
                                Array.from({ length: numPages }, (_, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))
                            )
                                :
                                <Page pageNumber={PageNumber} />


                        }
                    </Document>
                </div>
            </Modal>
            <Modal
                centered={true}
                footer={false}
                className='addSignatureModal'
                title="Signature"
                open={isModalVisible}
            >
                <>
                    <p className='modalText' style={{ fontSize: '16px', fontWeight: 600, color: '#2D6FAF' }}>Draw</p>
                    <Stage className='reactKonvaMainClass'
                        ref={canvasRef}
                        width={700}
                        height={260}
                        onMouseDown={handleMouseDown}
                        onMousemove={handleMouseMove}
                        onMouseup={handleMouseUp}
                    >
                        <Layer>
                            {lines.map((line, index) => (
                                <Line
                                    key={index}
                                    points={line.points}
                                    stroke="#000"
                                    strokeWidth={3}
                                    lineCap="round"
                                    lineJoin="round"
                                />
                            ))}
                        </Layer>
                    </Stage>
                </>

                <div className='modalButtons'>
                    <Button className='modalClearBtn' onClick={() => clearDrawing()}>Clear</Button>
                    <Button className='modalCancelBtn' onClick={handleModalCancel}>Cancel</Button>
                    <Button className='modalSubmitBtn' onClick={generateImage}>Submit</Button>
                </div>
            </Modal>
            <Row>
                <Col span={10}>
                    <h3 className='sideMainHeading'>Review/Approve</h3>
                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3 style={{ marginBottom: 65 }} className='reviewFormtext'>Folder Name</h3>
                    <h3 style={{ marginBottom: 65 }} className='reviewFormtext'>File Name</h3>
                    <h3 style={{ marginBottom: 75 }} className='reviewFormtext'>Owner Name</h3>
                    <h3 style={{ marginBottom: 115 }} className='reviewFormtext'>Comments</h3>
                    <h3 style={{ marginBottom: 65 }} className='reviewFormtext'>E-signature</h3>
                </Col>
                <Col span={13}>
                    <Select className='reviewSelect' options={designationOptions} placeholder='Select your Folder Name' />
                    <Input className='reviewInput' placeholder='Enter your File Name' />
                    <Input className='reviewInputUserId' placeholder='Enter Owner Name' defaultValue='NV09298333' />
                    <TextArea rows={4} className='reviewTextArea' placeholder='Enter your Email Id' />
                    <Row>
                        <Col span={12}>
                            <Upload>
                                <Button className='UploadBTN'>
                                    <img src='\Icons\uploadImg.svg' alt='Logo' />
                                    Upload File
                                </Button>
                            </Upload>
                            <p>
                                Maximum 5MB only PDF, JPEG, PNG
                            </p>
                        </Col>
                        <Col span={12}>
                            <Button className='SignatureBtn' onClick={() => setIsModalVisible(true)}>
                                <img src='\Icons\SIgnatureImg.svg' alt='Logo' />
                                Sign Now
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col offset={1} span={7}>
                    <div onClick={showModal} className='MaterialSide'>
                        <img style={{ display: 'flex', justifyContent: 'center' }} src='\Icons\materials.svg' alt='Logo' />
                        <h3>{data.username[0].username}.pdf</h3>
                    </div>
                </Col>

            </Row>
            <Row className='addCancelSaveBtnRow'>
                <Col span={3}>
                    <Button className='userCancelBtn'>Reject</Button>
                </Col>
                <Col offset={1} span={3}>
                    <Button className='addNewUserBtn' onClick={handleFileApprove}>Approve</Button>
                </Col>
            </Row>
        </div>
    )
}

export default FileReview;