import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Modal,
  Upload,
  message,
  Card,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { useSelector } from "react-redux";
import { AppUrl } from "../../../Constants/Constants";
import { checkElementUpdatePresence } from "../../../CommonFunctions/CommonFunctions";
import DocumentView from "../../Document/DocumentView/DocumentView";
import "./DocsList.css";

const { Dragger } = Upload;

const DocsList = () => {
  const { Column } = Table;
  const navigate = useNavigate();

  const { id } = useParams();

  const userRole = sessionStorage.getItem("userrole");
  const USER_ACCESS = useSelector((state) => state.userAccess);

  let url = window.location.href;
  let splittedUrl = url.split("/")[5];
  console.log(splittedUrl, "url", typeof splittedUrl);
  const USER_DATA = useSelector((state) => state.user);
  const AUTH_TOKEN = useSelector((state) => state.authToken);
  console.log(typeof id, "useparams");

  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [folderData, setfolderData] = useState([]);
  const [fileViewModal, setFileViewModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${AppUrl}folder_list_libraries?company_id=${USER_DATA.company_id}&user_id=${USER_DATA?.user_id}`,
        {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data.folders, "folderLibraryFolders");
          setFolders(res.data.folders);
          // setFiltered(res.data.folders);
        } else if (res.data.status === 201) {
          navigate("/sessiontimeout");
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }, []);

  const viewAllFiles = () => {
    axios
      .get(
        `${AppUrl}view_library_files?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}&folder_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data.files, "libraryFiles");
        if (res.data.status === 200) {
          setfolderData(res.data.files);
        } else {
          setfolderData([]);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    viewAllFiles();
  }, [id]);
  const HandleView = (e) => {
    sessionStorage.setItem("fileData", JSON.stringify(e));
    // navigate(`review/${e.key}`);
    console.log(e, "viewCard");
    setFileViewModal(true);
    setFileUrl(e.file_path);
  };

  const handleViewCancel = () => {
    setFileViewModal(false);
    setFileUrl("");
  };

  const openUpload = () => {
    setModalOpen(true);
  };

  const modalClose = () => {
    setModalOpen(false);
    setUploadedFiles([]);
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileName, setFileName] = useState("");

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const handleUpload = (info) => {
    let fileList = [...info.fileList];
    // Limit to only one file
    fileList = fileList.slice(-1);
    setUploadedFiles(fileList);
    setFileName(fileList[0].name);
    console.log(fileList, "fileList");
  };

  const handleApprove = (e) => {
    console.log(e, "archiveFolderRecord");

    axios
      .delete(
        `${AppUrl}archieve_file?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}&file_id=${e.file_id}&folder_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data, "responseOfDelete");
        if (res.data.status === 200) {
          message.success("File Archieved Successfully!");
          viewAllFiles();
        } else {
          message.error("Error archieving file");
        }
      })
      .catch((err) => {
        console.log(err, "archieve_file api catch");
        message.error(err.message);
      });
  };

  console.log(folders, "folderData");

  return (
    <div>
      <Modal
        className="Modal1"
        open={fileViewModal}
        onCancel={handleViewCancel}
        footer={null}
      >
        <DocumentView props={fileUrl} />
      </Modal>
      <Row>
        <Col span={14}>
          <h3 className="sideMainHeading">Document</h3>
        </Col>
        <Col span={6}>
          <Input placeholder="Search" className="searchInput" />
          <SearchOutlined className="inputSearch" />
        </Col>
        <Col span={4}>
          <div className="filterClass">
            <img src="/Icons/filter.svg" width={20} height={20} alt="" />
            <h3 className="filterWord">Filter</h3>
          </div>
        </Col>
      </Row>
      <Row className="userManagementTableRow">
        <Col span={5}>
          <Card className="listOfFilderCard">
            <h2 style={{ color: "#235789", marginBottom: "30px" }}>Folders</h2>
            {folders.map((item) => (
              <div
                key={item.folder_id}
                className={
                  id === item.folder_id
                    ? "folderListActiveDiv"
                    : "folderListDiv"
                }
                onClick={() => navigate(`/folder/${item.folder_id}`)}
              >
                <img src="/Icons/folder.svg" alt="" width={28} height={28.57} />
                <h4 className="folderNamePara">{item.folder_name}</h4>
              </div>
            ))}
          </Card>
        </Col>
        <Col span={18} offset={1}>
          <Table dataSource={folderData} className="userManagementTable">
            <Column title="S no" dataIndex="S.No" key="S.No" align="center" />
            <Column
              title="File Name"
              dataIndex="file_name"
              key="file_name"
              align="left"
            />
            <Column
              title="File Owner"
              dataIndex="Created_by"
              key="Created_by"
              align="center"
            />
            <Column
              title="Created at"
              dataIndex="Uploaded_at"
              key="Uploaded_at"
              align="center"
            />
            {/* {checkElementUpdatePresence(USER_ACCESS, 7) ? ( */}
            <Column
              title="Action"
              key="action"
              align="center"
              render={(_, record) => (
                <Space size="middle">
                  <Button
                    onClick={() => HandleView(record)}
                    className="viewEditBtn"
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => handleApprove(record)}
                    className="viewEditBtn"
                  >
                    Archive
                  </Button>
                </Space>
              )}
            />
            {/* ) : (
               <></>
            )} */}
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default DocsList;
