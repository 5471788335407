import React, { useEffect, useRef } from "react";
import "./BotPreview.css";
import { useState, useContext } from "react";
// import { Player, ControlBar, BigPlayButton } from 'video-react';
import {
  Input,
  Row,
  message,
  Upload,
  Card,
  Modal,
  Progress,
  Tooltip,
  notification,
  Button,
  Select,
} from "antd";
import {
  AppUrl,
  BaseUrl,
  MicroUrl3,
  REACT_APP_ACESS_KEY_ID,
  REACT_APP_SECRET_ACESS_KEY
}
  from '../../../Constants/Constants'
import axios from "axios";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  GlobalOutlined,
  UploadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
// import Speech from "react-speech";
import { Document, Page, pdfjs } from "react-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { LottiePlayer } from "@lottiefiles/lottie-player";
import {
  faMicrophone,
  faPaperPlane,
  faPlus,
  faThumbsDown,
  faThumbsUp,
  faVolumeDown,
  faVolumeHigh,
  faVolumeLow,
  faVolumeUp,
  faPlay
} from "@fortawesome/free-solid-svg-icons";
// import { faMicrophone as fasMicrophone } from "@fortawesome/free-solid-svg-icons";
// import { faMicrophone as farMicrophone } from "@fortawesome/free-regular-svg-icons";
// import { regular } from "@fortawesome/free-regular-svg-icons";
import {
  parseUrlsToLinks,
  urlify,
} from "../../../CommonFunctions/CommonFunctions";
import HTMLReactParser from "html-react-parser";
import TypeWriter from "./TypeWriter/TypeWriter";
// import io from "socket.io-client";
import AWS from "aws-sdk";
// import { useWebSocketContext } from "../Components/Reducer/SocketContext";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import { useParams } from "react-router-dom";
import qaJson from '../../../JSONData/QuestionData.json'
import { useSelector } from 'react-redux'


// const { Server } = require("socket.io");

// const microphone = regular("microphone");


const BotPreview = (props) => {
  //   const { socket, message, error } = useWebSocketContext()
  //   const [socketMessage, setSocketMessage] = useState(null)
  //   useEffect(() => {
  //     setSocketMessage(JSON.parse(message))
  //   }, [message])

  // console.log(socketMessage, 'Message from serve1')
  const date = new Date().toLocaleTimeString();
  const splitDate = date.split(':');
  const hours = splitDate[0];
  const minutes = splitDate[1];
  const dayTime = splitDate[2].split(' ')[1];
  const exactTime = `${hours}:${minutes} ${dayTime}`;

  // const hours = date.getHours();
  // const minutes = date.getMinutes();

  console.log(`${hours}:${minutes} ${dayTime}`, 'dateObj', splitDate)

  const { TextArea } = Input;
  const [SocketValue, setSocketValue] = useState(0)
  const [LoadingClassname, setLoadingClassname] = useState('Bot-main')
  const [BotLoading, setBotLoading] = useState(false)
  const [messages, setMessages] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [botAns, setBotAns] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [thumbsUpClass, setThumbsupClass] = useState("notActive");
  const [Disabled, setDisabled] = useState(false);
  const [thumbsDownClass, setThumbsDownClass] = useState("notActive");
  const [speakStates, setSpeakStates] = useState(false);
  const [speechIndex, setSpeechIndex] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewFilename, setPreviewFilename] = useState("");
  const [file, setfile] = useState([]);
  const [errorNotification, setErrorNotification] = useState(null);
  const [DOCURL, setDOCURL] = useState("");
  const [PageNumber, setPageNumber] = useState("");
  const [Suggession, setSuggession] = useState([]);
  const [Feedback, setFeedback] = useState([]);
  const [ThumbsDownres, setThumbsDownres] = useState("");
  const [SelectedFeedback, setSelectedFeedback] = useState("Out of content");
  const [comments, setComments] = useState('');
  const [Index, setIndex] = useState("");
  console.log(Feedback[0], 'feedBackIndex')

  const [selectedFile, setSelectedFile] = useState([]);

  const storedFile = localStorage.getItem("uploadedFile");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const recognitionRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [feedbackVal, setFeedbackVal] = useState(null);
  const [likedRow, setLikedRow] = useState(null);
  const [acceptFile, setAcceptFile] = useState('');

  const chatBotref = useRef(null);
  let url = window.location.href;
  let BotID = url.split("/").slice(-1);
  const UserID = localStorage.getItem("UserID");

  let { type } = useParams();

  console.log(type, 'useParamsType')

  const { text, voice } = props;
  const name = props.Botname;
  const Bubbleclr = props.BubbleClr;
  const WelcomeMsg = props.WelcomeMsg;
  const ChatImg = props.ProfileImg;
  const icon = props.botProfile;
  const MessageClr = props.MessageClr;
  const promptMsg = props.Prompt;
  const alignmentIcon = props.iconAlignment;
  const initialicon = "/Home/Frame 10.svg";
  const welcomeImage = props.welcomeImg;
  const PublicRes = props.PublicRes;
  const folder_id =props.folder_id
  const Reference = props.reference;
  const USER_DATA = useSelector((state) => state.user);
  const AUTH_TOKEN = useSelector((state) => state.authToken);
  console.log(props.BubbleClr, "PublicRes");

  useEffect(() => {
    if (type === 'teaching-assistant' || type === 'agreement-assistant') {
      // setFileFormats('.pdf');
      setAcceptFile(".pdf");
    } else if (type === 'data-assistant') {
      // setFileFormats('.csv')
      setAcceptFile(".csv")
    } else {
      // setFileFormats('.pdf, .docx, .txt, .mp3, .mp4, .weba, .wav, .ppt, .pptx');
      setAcceptFile("*");
    }
  }, [])


  const userRole = sessionStorage.getItem('userrole');
  // console.log(socketMessage?.percentage != null, "messstate", SocketValue)
  // useEffect(() => {
  //   if (socketMessage?.percentage != null) {

  //     setLoadingClassname('Bot-main-Loading')
  //     setBotLoading(true)
  //     setSocketValue(socketMessage.percentage)
  //   }
  // }, [message])


  useEffect(() => {
    if (SocketValue === 100) {
      setLoadingClassname('Bot-main')
      setBotLoading(false)
      openNotificationWithIconSuccess('Bot is ready to use')
    }
  }, [SocketValue])

  useEffect(() => {
    if (loadingImageRef.current && loading) {
      loadingImageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [loading]);

  const msg = `Welcome to Avatar Bot, your intelligent companion! I'm here to assist you and provide you with valuable information and support`;

  const handleSubmit = (e) => {
    stopListening();
    setInputVal("");
    // e.preventDefault();
    if (inputVal.trim() !== "") {
      sendMessage(inputVal);
      console.log("clicked");
      setInputVal("");
    }
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconSuccess = (type) => {
    console.log("getsSuccess");
    api["success"]({
      message: "Success!!!",
      description: type,
    });
  };

  const openNotificationWithIconError = (type) => {
    api["error"]({
      message: "Oops!!!",
      description: type,
    });
  };
  console.log(isHovered, "isHovered")
  const sendFeedback = (index, val, message) => {

    // setLikedRow(index);

    setIsHovered(false);

    if (val === true) {
      setFeedbackVal(val);
    } else if (val === false) {
      setFeedbackVal(val);
    } else {
      setFeedbackVal(null);
    }

    setMessages((prevMessages) =>
      prevMessages.map((message, i) =>
        i === index ? { ...message, feedback: val } : message
      )
    );
    setMessages((prevMessages) =>
      prevMessages.map((message, i) =>
        i === index ? { ...message, feedback: val } : message
      )
    );
    console.log(message.content, "valye");
    let url = `${BaseUrl}/user_action?user_id=${UserID}&bot_id=${BotID}&response=${message.content}&action=${val}`;
    axios
      .post(url)
      .then((res) => {
        console.log(res.data, "userInput");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    // if (chatBotref.current) {
    chatBotref.current.scrollTop = chatBotref.current.scrollHeight;
    // }
  };

  // useEffect(() => {

  //   const socket = io('wss://api-dev.precium.ai/ws');
  //   // https://api-qa.precium.ai/
  //   // Event listener for receiving messages from the server
  //   socket.on( (data) => {
  //     console.log('Received message:', data);
  //   });

  //   // Clean up the socket connection on component unmount
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = false;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      handleSpeechResult(event);
    };

    recognition.onerror = (event) => {
      console.log("Speech recognition error:", event.error);
      setIsListening(false);
    };

    recognition.onend = () => {
      console.log("Speech recognition ended");
      setIsListening(false);
    };

    recognitionRef.current = recognition;

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, []);

  const startListening = () => {
    setIsListening(true);

    if (recognitionRef.current) {
      recognitionRef.current.start();
    }
  };

  const stopListening = () => {
    setIsListening(false);

    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
  };

  const handleSpeechResult = (event) => {
    const { transcript } = event.results[0][0];
    setInputVal(transcript);
    // setIsListening(false);
  };
  const loadingImageRef = useRef(null);

  const agentAssign = sessionStorage.getItem('assign');
  const fileHide = sessionStorage.getItem('fileHide')
  console.log(fileHide, 'agentAssign')

  const sendMessage = (message) => {

    setStartTime(0)
    setLoading(true);
    setDisabled(true);
    // let URL = `${MicroUrl3}/vector_retrieval`;
    // let URL = `${MicroUrl3}/vector_retrieval`;
    let URL = `${AppUrl}vector_retrivel`;

    const trimmedMessage = message.replace(/\s/g, "");
    let question = trimmedMessage.toLowerCase();

    const matchedQuestion = qaJson.questions.find((q) => {
      console.log(q.question.replace(/\s/g, "").toLocaleLowerCase(), '')
      return (
        q.question.replace(/\s/g, "").toLowerCase() == question
      )
    });

    const matchedQuestionManager = qaJson.managers.find((q) => {
      console.log(q.question.replace(/\s/g, "").toLocaleLowerCase(), '')
      return (
        q.question.replace(/\s/g, "").toLowerCase() == question
      )
    });

    const matchedQuestionAgent = qaJson.AgentQuestions.find((q) => {
      console.log(q.question.replace(/\s/g, "").toLocaleLowerCase(), '')
      return (
        q.question.replace(/\s/g, "").toLowerCase() == question
      )
    });
    


    if( userRole === 'psca' ){

      if( matchedQuestion && agentAssign === 'false' && fileHide === 'true'  ){
        setLoading(true);

        
        setTimeout(() => {
  
          const botMessage = {
            content: matchedQuestion.answer,
            // content: parseUrlsToLinks(linkText),
            sender: "answer",
            side: "left",
            speak: false,
            url: null,
            pageNo: null,
            DocUrl: null,
            copytext: matchedQuestion.answer,
            file_name: null,
            start_time: null
          };
  
          setLoading(false);
          setMessages((prevMessages) => [...prevMessages, botMessage]);
        }, 3000)
  
        setSuggession([]);
  
        setDisabled(false);
      }
      else if( matchedQuestionAgent && agentAssign === 'false' && fileHide === 'false' ){
        setLoading(true);

        
        setTimeout(() => {
  
          const botMessage = {
            content: matchedQuestionAgent.answer,
            // content: parseUrlsToLinks(linkText),
            sender: "answer",
            side: "left",
            speak: false,
            url: null,
            pageNo: null,
            DocUrl: null,
            copytext: matchedQuestionAgent.answer,
            file_name: null,
            start_time: null
          };
  
          setLoading(false);
          setMessages((prevMessages) => [...prevMessages, botMessage]);
        }, 3000)
  
        setSuggession([]);
  
        setDisabled(false);
      }
      else{
        let body = {
          company_id: USER_DATA?.company_id,
          user_id: USER_DATA?.user_id,
           bot_id: BotID[0],
           folder_id: folder_id,
           question: message,
        };
        console.log("entered")
        console.log( AUTH_TOKEN,"channnn");
        axios
          .post(URL, body,{
            headers: {
              Authorization: `Bearer ${AUTH_TOKEN}`,
              Accept: 'application/json'
            }
          })
          .finally((res) => {
            setLoading(false);
            setDisabled(false);
          })
          .then((res) => {
            console.log(res, "vectorRetrievalRes");
            const decoded = atob(res.data);
            const decodedData = JSON.parse(decoded);
    
            console.log(decodedData, "decodedData");
            if (decodedData.status == 200) {
              if (decodedData.suggested_questions) {
                setSuggession(decodedData.suggested_questions);
              }
              let linkText =
                "Find me at http://www.example.com and also at https://stackoverflow.com";
    
              setTimeout(() => {
                const botMessage = {
                  content:
                    parseUrlsToLinks(decodedData.answer) ||
                    parseUrlsToLinks(decodedData),
                  // content: parseUrlsToLinks(linkText),
                  sender: "answer",
                  side: "left",
                  speak: false,
                  url: decodedData.link,
                  pageNo: decodedData.page_no,
                  DocUrl: decodedData.pdf_url,
                  time: exactTime,
                  file_name: decodedData.file_name,
                  start_time: decodedData.start_time
                };
    
                setMessages((prevMessages) => [...prevMessages, botMessage]);
              }, 0);
            } else {
              setTimeout(() => {
                const botMessage = {
                  content: decodedData.response,
                  sender: "answer",
                  side: "left",
                  speak: false,
                  url: decodedData.link,
                  pageNo: decodedData.page_no,
                  DocUrl: decodedData.pdf_url,
                  time: exactTime
                };
    
                setMessages((prevMessages) => [...prevMessages, botMessage]);
              }, 0);
            }
          })
          .catch((err) => {
            console.log(err);
            const userMessage = {
              content: "error",
              sender: "answer",
              side: "left",
              url: null,
              pageNo: null,
              DocUrl: null,
              time: exactTime
            };
    
            setMessages((prevMessages) => [...prevMessages, userMessage]);
            // message.error(err.code);
          });
      }
    }
    else if( userRole === 'pscm' ){

      if( matchedQuestionManager && fileHide === 'false' ){
        setLoading(true);

        
        setTimeout(() => {
  
          const botMessage = {
            content: matchedQuestionManager.answer,
            // content: parseUrlsToLinks(linkText),
            sender: "answer",
            side: "left",
            speak: false,
            url: null,
            pageNo: null,
            DocUrl: null,
            copytext: matchedQuestionManager.answer,
            file_name: null,
            start_time: null
          };
  
          setLoading(false);
          setMessages((prevMessages) => [...prevMessages, botMessage]);
        }, 3000)
  
        setSuggession([]);
  
        setDisabled(false);
      } else{
        let body = {
          company_id: USER_DATA?.company_id,
          user_id: USER_DATA?.user_id,
           bot_id: BotID[0],
           folder_id: folder_id,
           question: message,
        };
    
        axios
          .post(URL, body, {
            headers: {
              Authorization: `Bearer ${AUTH_TOKEN}`,
              Accept: 'application/json'
            }
          })
          .finally((res) => {
            setLoading(false);
            setDisabled(false);
          })
          .then((res) => {
            console.log(res, "vectorRetrievalRes");
            const decoded = atob(res.data);
            const decodedData = JSON.parse(decoded);
    
            console.log(decodedData, "decodedData");
            if (decodedData.status == 200) {
              if (decodedData.suggested_questions) {
                setSuggession(decodedData.suggested_questions);
              }
              let linkText =
                "Find me at http://www.example.com and also at https://stackoverflow.com";
    
              setTimeout(() => {
                const botMessage = {
                  content:
                    parseUrlsToLinks(decodedData.answer) ||
                    parseUrlsToLinks(decodedData),
                  // content: parseUrlsToLinks(linkText),
                  sender: "answer",
                  side: "left",
                  speak: false,
                  url: decodedData.link,
                  pageNo: decodedData.page_no,
                  DocUrl: decodedData.pdf_url,
                  time: exactTime,
                  file_name: decodedData.file_name,
                  start_time: decodedData.start_time
                };
    
                setMessages((prevMessages) => [...prevMessages, botMessage]);
              }, 0);
            } else {
              setTimeout(() => {
                const botMessage = {
                  content: decodedData.response,
                  sender: "answer",
                  side: "left",
                  speak: false,
                  url: decodedData.link,
                  pageNo: decodedData.page_no,
                  DocUrl: decodedData.pdf_url,
                  time: exactTime
                };
    
                setMessages((prevMessages) => [...prevMessages, botMessage]);
              }, 0);
            }
          })
          .catch((err) => {
            console.log(err);
            const userMessage = {
              content: "error",
              sender: "answer",
              side: "left",
              url: null,
              pageNo: null,
              DocUrl: null,
              time: exactTime
            };
    
            setMessages((prevMessages) => [...prevMessages, userMessage]);
            // message.error(err.code);
          });
      }
    }
     else{
      let body = {
        company_id: USER_DATA?.company_id,
        user_id: USER_DATA?.user_id,
         bot_id: BotID[0],
         folder_id: folder_id,
         question: message,
      };
  
      axios
        .post(URL, body, {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
            Accept: 'application/json'
          }
        })
        .finally((res) => {
          setLoading(false);
          setDisabled(false);
        })
        .then((res) => {
          console.log(res, "vectorRetrievalRes");
          const decoded = atob(res.data);
          const decodedData = JSON.parse(decoded);
  
          console.log(decodedData, "decodedData");
          if (decodedData.status == 200) {
            if (decodedData.suggested_questions) {
              setSuggession(decodedData.suggested_questions);
            }
            let linkText =
              "Find me at http://www.example.com and also at https://stackoverflow.com";
  
            setTimeout(() => {
              const botMessage = {
                content:
                  parseUrlsToLinks(decodedData.answer) ||
                  parseUrlsToLinks(decodedData),
                // content: parseUrlsToLinks(linkText),
                sender: "answer",
                side: "left",
                speak: false,
                url: decodedData.link,
                pageNo: decodedData.page_no,
                DocUrl: decodedData.pdf_url,
                time: exactTime,
                file_name: decodedData.file_name,
                start_time: decodedData.start_time
              };
  
              setMessages((prevMessages) => [...prevMessages, botMessage]);
            }, 0);
          } else {
            setTimeout(() => {
              const botMessage = {
                content: decodedData.response,
                sender: "answer",
                side: "left",
                speak: false,
                url: decodedData.link,
                pageNo: decodedData.page_no,
                DocUrl: decodedData.pdf_url,
                time: exactTime
              };
  
              setMessages((prevMessages) => [...prevMessages, botMessage]);
            }, 0);
          }
        })
        .catch((err) => {
          console.log(err);
          const userMessage = {
            content: "error",
            sender: "answer",
            side: "left",
            url: null,
            pageNo: null,
            DocUrl: null,
            time: exactTime
          };
  
          setMessages((prevMessages) => [...prevMessages, userMessage]);
          // message.error(err.code);
        });
    }


    

    const userMessage = {
      content: message,
      sender: "question",
      side: "right",
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    // Change the delay to your desired value (in milliseconds)
  };

  const handleInput = (e) => {
    setInputVal(e.target.value);
  };

  // console.log(inputVal, "inputVal");

  const handleKeySubmit = (e) => {
    // e.preventDefault();
    if (e.keyCode === 13) {
      handleSubmit();
      setIsListening(false);
    }
  };

  // text to audio

  // const handleSpeech = () => {
  //   const speech = new SpeechSynthesisUtterance(text);
  //   speechSynthesis.speak(speech);
  // };

  const handleSpeech = (content, index) => {
    setSpeechIndex(index);
    const speech = new SpeechSynthesisUtterance(content);

    // Listen for the 'end' event and set speak to false when speech completes
    speech.addEventListener("end", () => {
      setSpeakStates(false);
    });

    speechSynthesis.speak(speech);
    setSpeakStates(true);

    // const speech = new SpeechSynthesisUtterance(content);

    // // Listen for the 'end' event and set speak to false when speech completes
    // speech.addEventListener("end", () => {
    //   setSpeakStates((prevStates) => ({
    //     ...prevStates,
    //     [messageId]: false,
    //   }));
    // });

    // speechSynthesis.speak(speech);

    // // Reset the speak state for all other messages
    // const updatedSpeakStates = Object.keys(speakStates).reduce((acc, id) => {
    //   return { ...acc, [id]: id === messageId ? true : false };
    // }, {});
    // setSpeakStates(updatedSpeakStates);
  };
  // console.log(REACT_APP_SECRET_ACESS_KEY, "channn")
  AWS.config.update({
    region: "us-east-1",
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
    }),
  });

  const s3 = new AWS.S3();
  const fileType = previewFilename.split(".").pop();

  const uploadFileToS3 = () => {
    const userMessage = {
      content: previewFilename,
      sender: "question",
      side: "right",
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setLoading(true);
    const params = {
      Bucket: "preciumweb-dev",
      Key: `dev/${UserID}/${BotID}/${previewFilename}`,
      Body: file,
    };

    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        console.log(err, data, "chan");
        if (err) {
          reject(err);
          setLoading(false);
          setPreviewVisible(false);
          const userMessage = {
            content: "error",
            sender: "answer",
            side: "left",
          };

          setMessages((prevMessages) => [...prevMessages, userMessage]);
        } else {
          resolve(data);
          setLoading(false);
          setPreviewVisible(false);
          let body = {
            urls: [`https://ded2w7tw5rnvn.cloudfront.net/dev/${UserID}/${BotID}/${previewFilename}`],
          };
          axios
            .post(`${BaseUrl}/upload_S3_file`, body)

            .then((res) => {
              console.log(res, "s3 res");
              const userMessage = {
                content: "Uploaded successfully! Vector creation started!",
                sender: "answer",
                side: "left",
                url: null,
                pageNo: null,
                DocUrl: null,
              };
              setMessages((prevMessages) => [...prevMessages, userMessage]);
            })
            .catch((err) => {
              const userMessage = {
                content: "Uploaded failed",
                sender: "answer",
                side: "left",
                url: null,
                pageNo: null,
                DocUrl: null,

              };
              setMessages((prevMessages) => [...prevMessages, userMessage]);
              console.log(err, "s3err");
            });
        }
      });
      setPreviewVisible(false);
    });
    // .catch((err) => {
    //   console.log(err,"upload err")
    //   const userMessage = {
    //     content: 'error',
    //     sender: "answer",
    //     side: "left",
    //   };

    //   setMessages((prevMessages) => [...prevMessages, userMessage]);
    // })
  };

  const fileDelete = (filePath, index) => {
    console.log(filePath, "fileIndex");

    let deleteUrl = `${BaseUrl}/delete_files`;

    axios.post(deleteUrl, { file_path: filePath }).then((res) => {
      let status = res.data.status;

      const defaultUploadedFiles = [...uploadedFiles];

      if (status == 200) {
        defaultUploadedFiles.splice(index, 1);

        setUploadedFiles(defaultUploadedFiles);
      } else {
        // message.error(res.data.response);
        openNotificationWithIconError(res.data.response);
        setUploadedFiles(defaultUploadedFiles);
      }
    });
  };

  const stopSpeech = (messageId) => {
    setSpeechIndex(messageId);
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setSpeakStates(false);
    }

    // if (speechSynthesis.speaking) {
    //   speechSynthesis.cancel();
    //   setSpeakStates((prevStates) => ({
    //     ...prevStates,
    //     [messageId]: false,
    //   }));
    // }
  };


  const isPdf = file => {
    return file.type === 'application/pdf';
  };

  const isCsv = file => {
    return file.type === 'text/csv' || file.name.endsWith('.csv');
  };

  console.log(type, 'botTypePreview');

  const beforeUploadClear = (file) => {
    if (!previewVisible) {
      setfile([]);
    }
    return true;
  }


  useEffect(() => {
    if (errorNotification != null) {
      openNotificationWithIconError(errorNotification);
    }
  }, [errorNotification])


  const handlePreview = (files) => {

    const lastFileType = files.file.name.toLowerCase().split('.').slice(-1)[0];
    console.log(lastFileType, "uploadedfile");
    setPreviewVisible(true);

    if (type === 'teaching-assistant' || type === 'agreement-assistant') {
      const csvFiles = files.fileList.filter(file => isPdf(file.originFileObj));

      if (csvFiles.length === files.fileList.length) {
        const csvFiles = files.fileList.map(item => item.originFileObj);
        setPreviewFilename(csvFiles[csvFiles.length - 1].name);
        setfile(csvFiles[csvFiles.length - 1]);
        setPreviewImage(files.file.url || files.file.thumbUrl);
      } else if (lastFileType === 'pdf') {
        const csvFiles = files.fileList.map(item => item.originFileObj);
        setPreviewFilename(csvFiles[csvFiles.length - 1].name);
        setfile(csvFiles[csvFiles.length - 1]);
        setPreviewImage(files.file.url || files.file.thumbUrl);
      }
      else {
        // openNotificationWithIconError('You can only upload PDF files & other formats will not be uploaded!');
        setErrorNotification("You can only upload PDF files & other formats will not be uploaded!");
        setfile([]);
        setPreviewVisible(false);
      }
    } else if (type === 'data-assistant') {

      const csvFiles = files.fileList.filter(file => isCsv(file.originFileObj));
      console.log(csvFiles, 'csvFileslength')

      if (csvFiles.length === files.fileList.length) {
        const csvFiles = files.fileList.map(item => item.originFileObj);
        setfile(csvFiles[csvFiles.length - 1]);
        setPreviewFilename(csvFiles[csvFiles.length - 1].name);
        setPreviewImage(files.file.url || files.file.thumbUrl);
      }
      else if (lastFileType === 'csv') {
        const csvFiles = files.fileList.map(item => item.originFileObj);
        setfile(csvFiles[csvFiles.length - 1]);
        setPreviewFilename(csvFiles[csvFiles.length - 1].name);
        setPreviewImage(files.file.url || files.file.thumbUrl);
      }
      else {
        // openNotificationWithIconError('You can only upload CSV files & other formats will not be uploaded!');
        setErrorNotification("You can only upload CSV files & other formats will not be uploaded!");
        setfile([]);
        setPreviewVisible(false);
      }
    } else {
      const files1 = files.fileList.map((item) => item.originFileObj);
      setPreviewImage(files.file.url || files.file.thumbUrl);
      setPreviewFilename(files1[files1.length - 1].name);
      setfile(files1[files1.length - 1])
    }


  };

  console.log(file.file, 'previewFile')





  // =========================== Previous Upload function =====================
  // const handlePreview = (file) => {
  //   console.log(file.file, "uploadedfile");
  //   setPreviewImage(file.file.url || file.file.thumbUrl);
  //   setPreviewFilename(file.file.name);
  //   setPreviewVisible(true);
  //   // convertToBase64(file.file.originFileObj)
  //   setfile(file.file.originFileObj);
  // };


  const clearFileState = () => {
    setfile([]);
  };

  const handleCancel = () => {
    setfile((...prev) => []);
    // clearFileState();
    setPreviewVisible(false);
  }

  const customRequest = ({ file, onSuccess }) => {
    // Your custom logic to handle file upload
    // Call onSuccess when upload is successful
    onSuccess();
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        // setfile(fileReader.result)
      };
    });
  };

  // const fileType = previewFilename.split(".").pop();
  // const handleOK = ({visible, onCancel, imageUrl}) => {
  //   setLoading(true);
  //   let url ='https://angnygfzmofslbngjccbwuexni0xreii.lambda-url.us-east-1.on.aws/'
  //   let body ={
  //     file:file,
  //     filename:UserID+"/"+BotID+"/"+previewFilename,
  //     fileType:fileType
  //   }
  //   console.log(fileType,"fileType")
  //   console.log(body,'body')
  //   axios
  //   .post(url,JSON.stringify(body))
  //   .then((res) => {
  //     console.log(res,"responseee")
  //     if(res.status === 200){
  //       let url = res.data.uploadResult.Location

  //       axios
  //       .post(`${BaseUrl}/upload_S3_file?url=${url}`)
  //       .then((res)=> {
  //         console.log(res,"uploadedres")
  //       })
  //       .catch((err) => {
  //         const userMessage = {
  //           content: 'error uploading file',
  //           sender: "answer",
  //           side: "left",
  //         };

  //         setMessages((prevMessages) => [...prevMessages, userMessage]);
  //       })
  //     }

  //     setLoading(false);
  //   })
  //   .catch((res) => {
  //     console.log(res)
  //     setLoading(false);
  //   })
  //   setPreviewVisible(false)
  //   const userMessage = {
  //     content: previewFilename,
  //     sender: "question",
  //     side: "right",
  //   };

  //   setMessages((prevMessages) => [...prevMessages, userMessage]);
  // }
  const ImagePreviewModal = ({ visible, onCancel, imageUrl, filename }) => {
    console.log(filename, "fileee");
    return (
      <Modal
        open={visible}
        onCancel={onCancel}
        okText="submit"
        onOk={(filename) => uploadFileToS3(filename)}
      >
        <h4>{previewFilename}</h4>
        <lottie-player
          src="https://lottie.host/90850875-0857-40cf-9a98-d908a58adaca/ds8aYRnqZd.json"
          background="#FFFFFF"
          speed="1"
          style={{ width: 200, height: 200 }}
          loop
          autoplay
          direction="1"
          mode="normal"
        ></lottie-player>

        {/* <img src={imageUrl} alt={previewFilename} style={{ width: '100%' }} /> */}
        {/* <Progress percent={100} /> */}
      </Modal>
    );
  };
  const [SrcType, setSrcType] = useState('video')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [VideoSrc, setVideoSrc] = useState('')
  const [StartTime, setStartTime] = useState(0)

  const [num, setnum] = useState("");
  const showModal = (e, val) => {
    const updateFileName = e.replace(/ded2w7tw5rnvn.cloudfront.net/, 'preciumweb-dev.s3.amazonaws.com')
    setIsModalOpen(true);
    setDOCURL(updateFileName);
    setPageNumber(parseInt(val));
    setVideoSrc(e)
    setStartTime(val)

    console.log(e, "DOCURL", val);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setStartTime(0)
  };
  const handleCancel1 = () => {
    setIsModalOpen(false);
    setStartTime(0)
  };

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // console.log(speak, "speakingOrNot");
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const [isthumbsDown, setisthumbsDown] = useState(false);
  console.log(SelectedFeedback, "SelectedFeedback");
  const thumbsDownOK = () => {
    setMessages((prevMessages) =>
      prevMessages.map((message, i) =>
        i === Index ? { ...message, feedback: false } : message
      )
    );
    let url = `${BaseUrl}/user_action?user_id=${UserID}&bot_id=${BotID}&response=${ThumbsDownres}&action=false&reason=${SelectedFeedback}&expected_response=${comments}`;
    axios
      .post(url)
      .then((res) => {
        setisthumbsDown(false);
        console.log(res.data, "userInput");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const thumbsDownCancel = () => {
    setisthumbsDown(false);
  };
  const ShowThumbsDown = (e, val, message) => {
    setisthumbsDown(true);
    setIndex(e);
    console.log(e, "ShowThumbsDown", message);
    setThumbsDownres(message.content);
  };

  const handleKeyPress = (e) => {
    // Define a regular expression to allow only alphanumeric characters and spaces
    // const regex = /^[a-zA-Z0-9\s?!%$.&,]*$/;
    const regex = /^[a-zA-Z0-9 !@#$%^&*()_+{}\[\]:;<>,.=`'"?/~\\/-]+$/;

    // Check if the pressed key's character matches the regular expression
    if (!regex.test(e.key)) {
      // If it's a special character, prevent it from being entered
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {

    e.preventDefault();

    const pastedText = (e.clipboardData || window.clipboardData).getData('text');

    const regex = /^[a-zA-Z0-9 !@#$%^&*()_+{}\[\]:;<>,.=`'"?/~\\/-]+$/;

    if (regex.test(pastedText)) {
      setInputVal(pastedText);
    }

  }

  const handleLikeDisplay = (index) => {
    // console.log(index,'chan')
    setIsHovered(true);
    setHoveredRow(index);
    // setLikedRow(index);
  };

  const handleLikeOutDisplay = (index) => {
    setIsHovered(false);
    setHoveredRow(index)
  };


  const handleFeedBack = (index) => {

  }
  const playerRef = useRef(null);

  useEffect(() => {
    if (playerRef.current) {
      // Access the player instance using playerRef.current
    }
  }, []);



  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);


  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {

    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const progressPercent = (currentTime / duration) * 100;
    setProgress(progressPercent);
  };

  //  console.log(parseInt(StartTime),"StartTime",videoRef.current.currentTime,isModalOpen)
  // useEffect(() => {
  //   // Seek to a specific time (e.g., 30 seconds) when the component mounts
  //   if (isModalOpen === true) {

  //     videoRef.current.currentTime = StartTime;
  //   }
  // }, [isModalOpen]);
  return (
    <>

      {/* <h1 className="demoBot">Preview</h1> */}
      {
        BotLoading === true ? <img className="loaderimg" src="/loader/puff.svg">
        </img>
          : <></>
      }
      <div className={LoadingClassname}>
        {contextHolder}
        <Modal
          className="Modal1"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel1}
        >
          {/* {SrcType === 'video' ?

            <div>
              <h2>
                Video Source
              </h2>
              <video
                ref={videoRef}
                controls
              // onTimeUpdate={handleTimeUpdate}
              >
                <source className="Video"
                  src={VideoSrc}
                  type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
              {/* <div
      style={{ width: '100%', height: '15px', background: '#ddd', cursor: 'pointer' }}
      onClick={handleSeek}
    >
      <div
        style={{ width: `${progress}%`, height: '100%', background: '#4CAF50' }}
      ></div>
    </div> */}
              {/* <button onClick={togglePlay}>
      {isPlaying ? 'Pause' : 'Play'}
    </button> */}
            {/* </div>
            : */}


            <div>
              <Document file={DOCURL} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={PageNumber} />
              </Document>
              <p>
                Page {1} of {20}
              </p>
            </div>
          
        </Modal>
        <Modal
          style={{ width: 180 }}
          open={isthumbsDown}
          onOk={thumbsDownOK}
          onCancel={thumbsDownCancel}
        >
          <h3>Response</h3>
          <p>{HTMLReactParser(ThumbsDownres)}</p>
          <h3 style={{ marginTop: 10 }}>Feedback</h3>

          <Select
            style={{ width: "50%" }}
            onChange={(e, val) => setSelectedFeedback(val.label)}
            defaultValue={Feedback[0]}
            value={SelectedFeedback}
            options={Feedback}
          />

          <h3 style={{ marginTop: 10 }}>Comments</h3>
          <TextArea
            onChange={(e) => setComments(e.target.value)}
            value={comments}
            placeholder="Place your comments"
            rows={4}
            style={{ resize: 'none' }}
          />
        </Modal>

        <div className="Bot-Header" style={{ backgroundColor: Bubbleclr, border: `1px solid ${Bubbleclr}` }}>
          <Row>
            {!ChatImg ? <></> : <img src={ChatImg} className="BotProfile"></img>}
            <p className="BotName" style={{ color: MessageClr }}>
              {name}
            </p>
          </Row>
        </div>
        <div className="Bot-Body">
          <div className="Bot-conversation"
            ref={chatBotref}
          >
            <Row className="welcomeMsgRow">
              <div className="answer-Div">
                {welcomeImage && welcomeImage != "" ? (
                  <img
                    src={welcomeImage}
                    alt="welcome-img"
                    width={320}
                    height={200}
                  />
                ) : (
                  <></>
                )}
                <div className="botResponseDiv">
                  {
                    !ChatImg ?
                      <img src="/Logo/P-Logo.png" width={35} height={35} />
                      :
                      <img src={ChatImg} width={35} height={35} />
                  }
                  <p className="answer-Text">Hi! How can i help you ?</p>
                </div>
              </div>
            </Row>
            {messages.map((message, index) => {
              const lastIndex = messages.length - 1;
              console.log(messages, "retrievingMessage");
              return (
                <Row key={index} className={`${message.side}PositionDiv`} >
                  <div className="messageBubbleWidth">
                    <div
                      className={`${message.sender}-Div`}
                      // onMouseEnter={ () =>
                      //   message.sender === "answer" ? handleLikeDisplay(index) : null
                      // }
                      // onMouseLeave={ (index) =>
                      //   message.sender === "answer" ? handleLikeOutDisplay(index) : null
                      // }
                      style={{
                        backgroundColor:
                          // message.sender === "question" ? Bubbleclr : "#ffffff",
                          message.sender === "question" ? "#3d3d3d" : "#2b2b2b",
                      }}
                    >
                      {/* { 
                    isHovered != false && message.sender === "answer" && index === hoveredRow  ?
                      <div  className="feedBackImageDiv">
                        <div className="feedBackImageInsideDiv">
                          <img
                            onClick={() => sendFeedback(index, true, message)}
                            src="/Emoji/thumbsUp.png"
                            width={19}
                            height={18}
                          />
                          <img
                            onClick={() => ShowThumbsDown(index, false, message)}
                            src="/Emoji/thumbsDown.png"
                            width={19}
                            height={18}
                          />
                        </div>
                      </div>
                  :
                  <></>  
                  } */}
                      <div className="botResponseDiv">
                        {
                          message.sender === 'answer' ?
                            <img src={!ChatImg ? "/Logo/P-Logo.png" : ChatImg} width={35} height={35} />
                            :
                            <img src='/Logo/user-logo-3.png' alt="" width={35} height={35} />
                        }
                        <p
                          // dangerouslySetInnerHTML={{ __html: message.content }}
                          className={`${message.sender}-Text`}
                          style={{
                            color:
                              //   message.sender === "question" ? MessageClr : "#000000",
                              message.sender === "question" ? "#ffffff" : "#ffffff",
                          }}
                        >
                          {message.sender == "answer" ? (
                            <>
                              {message.url != null ? (
                                <Tooltip placement="right" title="view source">
                                  <InfoCircleOutlined
                                    className="viewSrc"
                                    onClick={() =>
                                      window.open(message.url, "_blank")
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                <></>
                              )}
                              <TypeWriter text={message.content} />

                              {
                                message.file_name != null ?
                                  <p
                                    onClick={(e) =>
                                      showModal(message.file_name, message.start_time,)
                                    }
                                    className="pagenumber"
                                    style={{ color: Bubbleclr }}
                                  >
                                    Show Source Video
                                    <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faPlay} />
                                  </p>
                                  :
                                  <></>
                              }
                              {message.pageNo != null  ? (
                                <p
                                  onClick={(e) =>
                                    showModal(message.DocUrl, message.pageNo)
                                  }
                                  className="pagenumber"
                                  style={{ color: "#6b24d6" }}
                                >
                                  Refer page number : {message.pageNo}
                                </p>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <p>{HTMLReactParser(message.content)}</p>
                          )}
                        </p>
                      </div>

                      {/* <p className="dayTimeClassAnswer">{message.time}</p> */}
                      {
                        message.sender == 'answer' ?
                          <div className="feedbackGaveDiv">
                            <div style={{ display: 'flex', margin: '3px 0 0 66px' }}>
                              <div
                                className={`feedbackGaveInsideDiv ${message.feedback === true ? 'active' : 'notActive'}-Likes`}
                                onClick={() => {
                                  if (message.feedback === true) {
                                    sendFeedback(index, null, message)
                                  } else {
                                    sendFeedback(index, true, message)
                                  }
                                }}
                              >
                                <ThumbUpAltOutlinedIcon />
                              </div>
                              <div
                                className={`feedbackGaveInsideDiv ${message.feedback === false ? 'active' : 'notActive'}-disLikes`}
                                onClick={() => {
                                  if (message.feedback === false) {
                                    sendFeedback(index, null, message)
                                  } else {
                                    ShowThumbsDown(index, false, message)
                                  }
                                }
                                }
                              >
                                <ThumbDownAltOutlinedIcon />
                              </div>
                              <>
                                {/* ==================  Text to Speech ====================== */}
                                <div>
                                  {
                                    speakStates == false
                                      //   && index == speechIndex
                                      ?
                                      <div className="feedbacks">
                                        <FontAwesomeIcon
                                          onClick={() => {
                                            handleSpeech(message.content, index)
                                            setSpeakStates(true);
                                          }}
                                          className="speakerIcons"
                                          icon={faVolumeHigh} />
                                      </div>
                                      :
                                      <div className="feedbacks">
                                        <FontAwesomeIcon
                                          onClick={() => {
                                            stopSpeech(index);
                                            setSpeakStates(false);
                                          }}
                                          className="speakerIcons"
                                          icon={faVolumeHigh} />

                                      </div>
                                  }
                                </div>
                              </>
                            </div>
                          </div>
                          :
                          <></>
                      }
                      {
                        message.sender === 'answer' ?
                          <div className="SuggestedQuestionsDiv">
                            {Suggession.map((m, i) => {
                              console.log(m, 'suggestedQuestions')
                              console.log(i, 'indexofMessage')
                              return (
                                <div className="SuggestedQuestions">
                                  {
                                    index === lastIndex && m != "" &&
                                    <Button
                                      disabled={Disabled}
                                      value="hi"
                                      onClick={(e) => sendMessage(e.target.innerText)}
                                      className="SuggestedQuestionsBTN"
                                    >
                                      {m}
                                    </Button>
                                  }
                                </div>
                              );
                            })}
                          </div>
                          :
                          <></>
                      }

                      {/* { 
                     message.sender === 'answer' 
                     && message.feedback === false
                     ?
                      <div  className="feedbackGaveDiv" 
                      // ref={chatBotref}
                      >
                        <div className="feedbackGaveInsideDiv" onClick={() => sendFeedback(index, null, message)}>
                        <img src="/Emoji/thumbsDown.png" width={19} height={18} />
                        </div>
                      </div>
                      :
                     <></> 
                    }
                    { 
                      message.sender === 'answer' 
                      && message.feedback === true
                      ?
                      <div className="feedbackGaveDiv" 
                      // ref={chatBotref}
                      >
                        <div className="feedbackGaveInsideDiv" onClick={() => sendFeedback(index, null, message)}>
                        <img src="/Emoji/thumbsUp.png" width={19} height={18} />
                        </div>
                      </div>
                      :
                     <></>
                    } */}

                      {/* {message.sender === "answer" && (
                        <>
                          <div>
                            {
                              speakStates == false
                                ?
                                <div className="feedbacks">
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      handleSpeech(message.content, index)
                                      setSpeakStates(true);
                                    }}
                                    className="speakerIcons"
                                    icon={faVolumeHigh} />
                                </div>
                                :
                                <div className="feedbacks">
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      stopSpeech(index);
                                      setSpeakStates(false);
                                    }}
                                    className="speakerIcons"
                                    icon={faVolumeHigh} />

                                </div>
                            }
                          </div>
                        </>
                      )} */}
                    </div>
                  </div>
                </Row>
              );
            })}
            {loading === true ? (
              <div className="leftPositionDiv">
                {" "}
                <div className="answer-Div" ref={loadingImageRef}>
                  <div className="botResponseDiv">
                    <img src={!ChatImg ? "/Logo/P-Logo.png" : ChatImg} width="35" height="35" />
                    <div className="loading-animation">
                      <div className="loading-dots">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>



          {/* <div className="SuggestedQuestions">
          {Suggession.map((m) => {
            return (
              <Tooltip placement="top" title={m}>
                <Button
                  disabled={Disabled}
                  value="hi"
                  onClick={(e) => sendMessage(e.target.innerText)}
                  className="SuggestedQuestionsBTN"
                >
                  {m}
                </Button>
              </Tooltip>
            );
          })}
        </div> */}




          <div className="botInputDiv">
            <TextArea
              // onKeyDown={ (e) => {
              //     e.preventDefault();
              //     if(e.key ==='Enter') {
              //         handleSubmit();
              // } } }
              rows={1}
              onKeyPress={handleKeyPress}
              disabled={Disabled || BotLoading}
              onKeyDown={handleKeySubmit}
              onPaste={handlePaste}
              value={inputVal}
              // disabled={Disabled}
              onChange={handleInput}
              className="Bot-input"
              placeholder="Enter Your Message"
            />
            {PublicRes == true ? (
              <GlobalOutlined
                style={{ fontSize: 21, bottom: 38 }}
                onClick={handleSubmit}
                className="sendButton"
              />
            ) : (
              <FontAwesomeIcon icon={faPaperPlane}
                className="sendButton"
                onClick={handleSubmit}
                style={{ color: '#6b24d6' }}
              />
            )}
          </div>
        </div>
        
      </div>
    </>
  );
};

export default BotPreview;