// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folderRow{
    margin-top: 2%;
}
.documentFolderCards.ant-card-bordered{
    border: 1px solid #CBCBCB !important;
}
.folderNameText{
    color: #235789;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
}
.cretaedTimeText{
    color: #535C64;
    font-size: 12px;
    font-weight: 600;
}
.cretaedTimeText >span{
    color: #235789;
    font-weight: 700;
}
.folderOpenClass{
    font-size: 16px;
    font-weight: 700;
    color: #ED1C24;
    cursor: pointer;
    width: 80px;
}
.rightOpenArrow{
    position: relative;
    top: 7px;
}
.folderColumnClass{
    margin-right: 3%;
    margin-bottom: 2%;
}`, "",{"version":3,"sources":["webpack://./src/Components/Document/Folders/Folders.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IACI,oCAAoC;AACxC;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,QAAQ;AACZ;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".folderRow{\n    margin-top: 2%;\n}\n.documentFolderCards.ant-card-bordered{\n    border: 1px solid #CBCBCB !important;\n}\n.folderNameText{\n    color: #235789;\n    font-size: 16px;\n    font-weight: 700;\n    text-transform: capitalize;\n}\n.cretaedTimeText{\n    color: #535C64;\n    font-size: 12px;\n    font-weight: 600;\n}\n.cretaedTimeText >span{\n    color: #235789;\n    font-weight: 700;\n}\n.folderOpenClass{\n    font-size: 16px;\n    font-weight: 700;\n    color: #ED1C24;\n    cursor: pointer;\n    width: 80px;\n}\n.rightOpenArrow{\n    position: relative;\n    top: 7px;\n}\n.folderColumnClass{\n    margin-right: 3%;\n    margin-bottom: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
