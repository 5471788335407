import { Button, Col, Input, Row, Space, Table, Tag } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
// import './UserList.css'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { AppUrl } from '../../../Constants/Constants';

const { Column } = Table;

const dataTable = [
    {
        key : 1,
        sno: '1',
        // companyname: [{ companyname : 'User 1', mail : 'user234@gmail.com' }],
        companyname: 'VHS',
        email : 'vhs@valuehealthsol.com',
        status : ['Active'],
        jobrole : 'PSC Agent'
    },
    {
        key : 2,
        sno: '2',
        // companyname: [{ companyname : 'User 2', mail : 'user234@gmail.com' }],
        companyname: 'Regeneron',
        email : 'admin@regeneron.com',
        status : ['Inactive'],
        jobrole : 'PSC Agent'
    },
    {
        key : 3,
        sno: '3',
        // companyname: [{ companyname : 'User 3', mail : 'user234@gmail.com' }],
        companyname: 'Novartis',
        email : 'admin@novartis.com',
        status : ['Active'],
        jobrole : 'PSC Agent'
    },
    {
        key : 4,
        sno: '4',
        // companyname: [{ companyname : 'User 4', mail : 'user234@gmail.com' }],
        companyname: 'Cotiviti',
        email : 'admin@cotiviti.com',
        status : ['Inactive'],
        jobrole : 'PSC Agent'
    },
    {
        key : 5,
        sno: '5',
        // companyname: [{ companyname : 'User 5', mail : 'user234@gmail.com' }],
        companyname: 'Kat',
        email : 'admin@kat.com',
        status : ['Active'],
        jobrole : 'PSC Agent'
    },
]

const CompanyList = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [ companyData, setCompanyData ] = useState([]);


    const dispatch = useDispatch();

    const AUTH_TOKEN = useSelector( state => state.authToken );


    useEffect(() => {
        axios.get(`${AppUrl}get_company`, {
            headers:{
                Authorization: `Bearer ${AUTH_TOKEN}`,
                Accept: 'application/json'
            }})
            .then((res) => {
                console.log(res, 'responseGetCompany');
                if( res.data.status == 200 ){
                    setCompanyData(res.data.response);
                } else{
                    setCompanyData([]);
                }
            })
            .catch(( err ) => {
                console.log(err.message)
            })
    }, [])

    useEffect(() => {
        const savedData = localStorage.getItem('UserDatass');
        if (savedData) {
            setData(JSON.parse(savedData));
        }
    }, []);

    const handleAddUser = () => {
        navigate('/company/addcompany')
    }

    const handleEditNavigate = (e) => {
        navigate(`/usermanagement/edituser/${e.key}`)
    }


    return (
        <div className='userManagementClass'>
            <Row>
                <Col span={10}>
                    <h3 className='sideMainHeading'>User List</h3>
                </Col>
                <Col span={6}>
                    <Input placeholder='Search' className='searchInput' />
                    <SearchOutlined className='inputSearch' />
                </Col>
                <Col span={4}>
                    <div className='filterClass'>
                        <img src='/Icons/filter.svg' width={20} height={20} alt='' />
                        <h3 className='filterWord'>Filter</h3>
                    </div>
                </Col>
                <Col span={3}>
                    <Button className='addNewUserBtn' onClick={handleAddUser}><PlusOutlined className='plusIconClass' /> Add Company</Button>
                </Col>
            </Row>
            <Row className='userManagementTableRow'>
                <Col span={24}>
                    <Table dataSource={companyData} className='userManagementTable' >
                        <Column title="S no" dataIndex="s.no" key="s.no" align='center' />
                        <Column title="Company Name" dataIndex="company_name" key="company_name" align='center'
                            render={(text) => (
                                <Space size='middle'>
                                    <h3 className='tableNormalText' style={{ textTransform: 'capitalize' }} >{text}</h3>
                                </Space>
                            )}
                        />
                        <Column title='Email' dataIndex='email' key='email' align='center' 
                        render={(text) => (
                            <Space size='middle'>
                                <h3 className='tableNormalText'>{text}</h3>
                            </Space>
                        )}
                        />
                    </Table>
                </Col>
            </Row>
        </div>
    )
}

export default CompanyList