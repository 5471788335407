import {useEffect,useState} from 'react'
import { Row, Col, Input, Card,Rate, Button, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from 'react-router-dom';
import './Chatbot.css'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { AppUrl } from '../../Constants/Constants';
import { checkElementUpdatePresence } from '../../CommonFunctions/CommonFunctions';

const Chatbots = () => {
    const navigate = useNavigate();
    const [Data, setData] = useState([])
    const [SearchInput, setSearchInput] = useState('')
    const [Filtered, setFiltered] = useState([])
    const USER_ACCESS = useSelector(state => state.userAccess);

    const AUTH_TOKEN = useSelector(state => state.authToken);
    const USER_DATA = useSelector(state => state.user);

    useEffect(() => {
     axios
     .get(`${AppUrl}list_of_bots?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}`,{
        headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
            Accept: 'application/json'
        }
     })
     .then((res) => {
        console.log(res.data.bots)
        setData(res.data.bots)
        setFiltered(res.data.bots)
     })
     .catch((err) => {
        console.log(err)
     })
    }, [])


    
    const folders = [
        {
            id : 1,
            name: 'PSC Assistant',
            created: '12-Feb-2024',
            image:"Icons/botImage1.svg",
            modified: '24-Mar-2024',
            created: '11-Feb-2024',
        },
        // {
        //     id : 2,
        //     name: 'Chat Bot 2',
        //     created: '11-Feb-2024',
        //     image:"Icons/botImage2.svg",
        //     modified: '25-Mar-2024'
        // },
        // {
        //     id : 3,
        //     name: 'Chat Bot 3',
        //     created: '10-Feb-2024',
        //     image:"Icons/botImage3.svg",
        //     modified: '21-Mar-2024'
        // },
        // {
        //     id : 4,
        //     name: 'Chat Bot 4',
        //     created: '09-Feb-2024',
        //     image:"Icons/botImage4.svg",
        //     modified: '12-Mar-2024'
        // },
    ]

    const handleFileNavigate = (e) => {
        navigate(`/chatbots/${e}`);
    }
    const HandleCreate =() => {
        axios
        .post(`${AppUrl}get_bot_id`,{},{
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                Accept: 'application/json'
            }
        })
        .then((res) => {
            console.log(res)
            navigate(`/createchatbots/${res.data.response}`)
        })
        .catch((err) => {
message.error(err.message)
        })
    }
    useEffect(() => {
        if (!SearchInput) {
            
            setFiltered(Data);
        } else {
           
            const filteredData = Data.filter((item) => 
                item.name.includes(SearchInput.toLowerCase()) // Match ignoring case
            );
            setFiltered(filteredData);
            console.log(filteredData, "filtered");
        }
    }, [SearchInput]);

    
  return (
    <div className='foldersMainCLass'>
    <Row>
        <Col span={10}>
            <h3 className='sideMainHeading'>AI Assistants</h3>
        </Col>
        <Col span={6}>
            <Input placeholder='Search by Botname' onChange={(e) => setSearchInput(e.target.value)} className='searchInput' />
            <SearchOutlined className='inputSearch' />
        </Col>
        <Col span={4}>
            <div className='filterClass'>
                <img src='/Icons/filter.svg' width={20} height={20} alt='' />
                <h3 className='filterWord'>Filter</h3>
            </div>
        </Col>
        <Col span={4}>
        {   
        checkElementUpdatePresence(USER_ACCESS,16) ? 
                <Button
                onClick={HandleCreate}
                className='CreateChatbot'
                >Create New Chatbot</Button>
                :
                <></>
            }
                </Col>
    </Row>
    <Row className='folderRow'>
        {
            Filtered.map(item => (
                
                    <Col className='folderColumnClass' span={5} key={item.id}>
                        <Card className='ChatbotCards'>
                            <img src={item.image ? item.image: 'Icons/botImage1.svg' } alt='Bot Icon' width={50} height={45.57} />
                        {/* <Rate className='Star' count={1} /> */}
                            <h3 style={{margin:0}} className='folderNameText'>{item.name}</h3>
                            <p style={{margin:0}} className='cretaedTimeText'>Created on : <span>{item.created}</span></p>
                            <p  style={{margin:0}} className='cretaedTimeText'>Last Modified on : <span>{item.modified}</span></p>
                            <h3 className='folderOpenClass' onClick={() => handleFileNavigate(item.id)} >Open <ArrowRightAltIcon className='rightOpenArrow' /> </h3>
                        </Card>
                    </Col>
                
            ))
        }
    </Row>
</div>
  )
}

export default Chatbots