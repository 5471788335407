import { Button, Col, Input, Row, Space, Table, Skeleton } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import './UserList.css'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppUrl } from '../../../Constants/Constants';
import { checkElementUpdatePresence } from '../../../CommonFunctions/CommonFunctions';



const UserList = () => {
    const USER_ACCESS = useSelector(state => state.userAccess);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [Loading, setLoading] = useState(false)
    const { Column } = Table;
    const [SearchInput, setSearchInput] = useState('')
    const [Filtered, setFiltered] = useState([])

    const AUTH_TOKEN = useSelector(state => state.authToken);
    const USER_DATA = useSelector(state => state.user);

    console.log(USER_DATA, "data")

    const navigate = useNavigate();

    const handleAddUser = () => {
        navigate('/usermanagement/adduser')
    }

    const handleEditNavigate = (e) => {
        navigate(`/usermanagement/edituser/${e.user_id}`);
    }
    const handleViewNavigate = (e) => {
        navigate(`/usermanagement/viewuser/${e.user_id}`)
    }

    useEffect(() => {
        setLoading(true)
        axios
            .get(`${AppUrl}user_list_based_on_company?company_id=${USER_DATA?.company_id}`, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                console.log(res)
                // setData(res.data)
                if (res.status == 201) {
                    navigate('/sessiontimeout')
                } else {


                    let newData = [];
                    for (let i = 0; i < res.data.response.length; i++) {
                        newData.push({ s_no: i + 1, ...res.data.response[i] });
                    }
                    setData(newData)
                    setLoading(false)
                    setFiltered(newData)
                    return newData;
                }
            })

            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (!SearchInput) {

            setFiltered(data);
        } else {

            const filteredData = data.filter((item) =>
                item.email.toLowerCase().includes(SearchInput.toLowerCase()) // Match ignoring case
            );
            setFiltered(filteredData);
            console.log(filteredData, "filtered");
        }
    }, [SearchInput]);

    return (
        <div className='userManagementClass'>
            <Row>
                <Col span={10}>
                    <h3 className='sideMainHeading'>User List</h3>
                </Col>
                <Col span={6}>
                    <Input placeholder='Search by mail'
                        onChange={(e) => setSearchInput(e.target.value)}
                        className='searchInput' />
                    <SearchOutlined className='inputSearch' />
                </Col>
                <Col span={4}>
                    <div className='filterClass'>
                        <img src='/Icons/filter.svg' width={20} height={20} alt='' />
                        <h3 className='filterWord'>Filter</h3>
                    </div>
                </Col>
                <Col span={3}>
                    {checkElementUpdatePresence(USER_ACCESS, 4) ?

                        <Button className='addNewUserBtn' onClick={handleAddUser}><PlusOutlined className='plusIconClass' /> Add New User</Button>
                        :
                        <></>
                    }
                </Col>
            </Row>
            <Row className='userManagementTableRow'>
                <Col span={24}>
                    {
                        Loading ?
                            <Skeleton active />
                            :



                            <Table dataSource={Filtered} className='userManagementTable'>
                                <Column title="S.no" dataIndex="s_no" key="s_no" align='center' />
                                <Column title="Email" dataIndex="email" key="email" align='center' />
                                <Column title="User Name" dataIndex="user_name" key="user_name" />
                                <Column title="Job Role" dataIndex="jobrole" key="jobrole" align='center'
                                    render={(text) => (
                                        <Space size='middle'>
                                            <h3 className='tableNormalText'>{text}</h3>
                                        </Space>
                                    )}
                                />
                                <Column title="User Role" dataIndex="role" key="role" align='center'
                                    render={(text) => (
                                        <Space size='middle'>
                                            <h3 className='tableNormalText'>{text}</h3>
                                        </Space>
                                    )}
                                />
                                <Column
                                    title="Action"
                                    key="action"
                                    align='center'
                                    render={(_, record) => (
                                        <Space size="middle">
                                            {checkElementUpdatePresence(USER_ACCESS, 4) ?

                                                <>

                                                    <Button className='viewEditBtn' onClick={() => handleViewNavigate(record)}>View</Button>
                                                    <Button className='viewEditBtn' onClick={() => handleEditNavigate(record)}>Edit</Button>
                                                </>
                                                :
                                                <Button className='viewEditBtn' onClick={() => handleViewNavigate(record)}>View</Button>
                                            }
                                        </Space>
                                    )}
                                />
                            </Table>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default UserList