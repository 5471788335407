import React from 'react';
import Lottie from 'lottie-react';
import './SessionTimeout.css';
import animationData from '../../Animation.json'; // Import the Lottie JSON file
import { Button } from 'antd';
import { useParams,useNavigate } from 'react-router-dom'

const LottieAnimation = () => {
    const navigate = useNavigate();
  return (
    <div className='lottie-container'>
      <Lottie 
        className='lottie-animation'
        animationData={animationData} 
        loop={true} 
      />
      <div className='button-container'>
        <Button onClick={() => navigate('/login')}   type='primary'>Login</Button>
      </div>
    </div>
  );
};

export default LottieAnimation;
