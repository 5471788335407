import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Modal,
  Upload,
  Skeleton,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { useSelector } from "react-redux";
import "./Documents.css";
import { AppUrl } from "../../Constants/Constants";
import { checkElementUpdatePresence } from "../../CommonFunctions/CommonFunctions";
import DocumentView from "./DocumentView/DocumentView";
// import { USER_ACCESS } from '../../Redux/Actions';

const { Dragger } = Upload;

const Document = () => {
  const { Column } = Table;
  const navigate = useNavigate();

  const { id } = useParams();
  const AUTH_TOKEN = useSelector((state) => state.authToken);
  const USER_DATA = useSelector((state) => state.user);
  const USER_ACCESS = useSelector((state) => state.userAccess);

  const userRole = sessionStorage.getItem("userrole");
  console.log(USER_ACCESS, "USER_ACCESSUSER_ACCESS");

  let url = window.location.href;
  let splittedUrl = url.split("/")[5];
  console.log(splittedUrl, "url", typeof splittedUrl);

  console.log(typeof id, "useparams");

  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    axios
      .get(
        `${AppUrl}view_folder_files?company_id=${USER_DATA?.company_id}&folder_id=${splittedUrl}&user_id=${USER_DATA?.user_id}`,
        {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res, "responseOfViewFolderFiles");
        if (res.data.status === 200) {
          setData(res.data.files);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const HandleView = (e) => {
    setViewModal(true);
    setFileUrl(e.file_path);
    console.log(e.file_path, "e.file_path");
  };

  const openUpload = () => {
    setModalOpen(true);
  };

  const modalClose = () => {
    setModalOpen(false);
    setUploadedFiles([]);
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileName, setFileName] = useState("");

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const handleUpload = (info) => {
    let fileList = [...info.fileList];
    // Limit to only one file
    fileList = fileList.slice(-1);
    setUploadedFiles(fileList);
    setFileName(fileList[0].name);
    console.log(fileList, "fileList");
  };
  const HandleChange = () => {
    console.log("entered");
    navigate(`/document/${id}/addfile`);
  };

  const addFileToData2 = (fileName) => {
    // Generate a new key for the object
    const newKey = data.length + 1;
    // Create the new object
    const newObject = {
      key: newKey,
      sno: newKey.toString(),
      username: [
        {
          username: fileName,
          date: formatDate(new Date()),
          image: "/Icons/Filepdfred.svg",
        },
      ],
      status: ["Pending"],
    };
    // Update the data2 array by appending the new object
    setData((prevData) => [...prevData, newObject]);
  };

  const handleApprove = (e) => {
    // Store the file details in session storage for further use (or handle accordingly)
    sessionStorage.setItem("fileForApproval", e.file_id);
    console.log("fileForApproval", e.file_id);

    // Navigate to the approval page or open modal for this file
    navigate(`/document/files/approve?folderId=${id}&fileId=${e.file_id}`);
  };

  return (
    <div>
      <Modal
        title="Upload"
        footer={false}
        open={modalOpen}
        onCancel={modalClose}
      >
        <Dragger
          // {...props}
          fileList={uploadedFiles}
          onChange={handleUpload}
          multiple={false}
          customRequest={({ onSuccess }) => {
            onSuccess("ok");
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
        <Button
          style={{ marginTop: 10 }}
          onClick={modalClose}
          className="uploadCancelBtn"
          type="primary"
        >
          Cancel
        </Button>
        <Button className="approvalBtn" onClick={() => handleApprove(fileName)}>
          Send for Approval
        </Button>
      </Modal>
      <Modal
        className="Modal1"
        open={viewModal}
        onCancel={() => setViewModal(false)}
        footer={null}
      >
        <DocumentView props={fileUrl} />
      </Modal>
      <Row>
        <Col span={10}>
          <h3 className="sideMainHeading">Document</h3>
        </Col>
        <Col span={6}>
          <Input placeholder="Search" className="searchInput" />
          <SearchOutlined className="inputSearch" />
        </Col>
        <Col span={4}>
          <div className="filterClass">
            <img src="/Icons/filter.svg" width={20} height={20} alt="" />
            <h3 className="filterWord">Filter</h3>
          </div>
        </Col>

        {/* <Col span={4}>
                <Button  onClick={ HandleChange}>
                    Upload File
                </Button>
            </Col> */}
        {checkElementUpdatePresence(USER_ACCESS, 15) ? (
          <Col span={4}>
            <Button onClick={HandleChange} className="addNewUserBtn">
              <PlusOutlined />
              Add New File
            </Button>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row className="userManagementTableRow">
        <Col span={24}>
          {Loading ? (
            <Skeleton active />
          ) : (
            <Table dataSource={data} className="userManagementTable">
              <Column title="S no" dataIndex="S.No" key="S.No" align="center" />
              <Column title="File Name" dataIndex="file_name" key="file_name" />
              <Column
                title="Due Date"
                dataIndex="Document_exp_date"
                key="Document_exp_date"
                render={(date) => <p>{date != "" ? date : "-"}</p>}
              />
              <Column
                title="Created by"
                dataIndex="Created_by"
                key="Created_by"
              ></Column>
              <Column
                title="Status"
                dataIndex="file_status"
                key="file_status"
                align="center"
                render={(status) => (
                  <>
                    <Tag
                      className={
                        status === "Approved"
                          ? "userManagementActiveTag"
                          : status === "Rejected"
                          ? "userManagementNonActiveTag"
                          : "userManagementPendingTag"
                      }
                      bordered={false}
                      key={status}
                    >
                      {status}
                    </Tag>
                  </>
                )}
              />
              <Column
                title="Action"
                key="action"
                align="center"
                render={(_, record) => {
                  console.log(record, "recordValues");
                  return (
                    <>
                      <Space size="middle">
                        <Button
                          onClick={() => HandleView(record)}
                          className="viewEditBtn"
                        >
                          View
                        </Button>
                        {record.file_status !== "Approved" &&
                        checkElementUpdatePresence(USER_ACCESS, 7) ? (
                          <Button
                            onClick={() => handleApprove(record)}
                            className="viewEditBtn"
                          >
                            Approve
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Space>
                    </>
                  );
                }}
              />
            </Table>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Document;
