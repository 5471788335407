// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoUpload{
    width: 200px;
    height: 37px;
    font-size: 16px;
    font-weight: 500;
}
.uploadLogoIcon{
    color: #ed1c24;
    font-size: 20px;
}
.fieldMissErr{
    color: red;
    font-weight: 600;
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Components/SuperAdmin/AddCompany/AddCompany.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,UAAU;IACV,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".logoUpload{\n    width: 200px;\n    height: 37px;\n    font-size: 16px;\n    font-weight: 500;\n}\n.uploadLogoIcon{\n    color: #ed1c24;\n    font-size: 20px;\n}\n.fieldMissErr{\n    color: red;\n    font-weight: 600;\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
