// import React, { useState } from 'react';
// // import { AdobeSignAPI } from 'adobe-sign-sdk'; // Import Adobe Sign API
// import { Upload, message, Button, Modal } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
// import Review from './Review'; // Assuming Review component is in the same directory

// const DigitalSignatureForm = () => {
//     const [pdfFile, setPdfFile] = useState(null);
//     const [signatureImage, setSignatureImage] = useState(null);

//     const handleFileChange = (file) => {
//         setPdfFile(file);
//     };

//     const handleSignatureCapture = (imageData) => {
//         setSignatureImage(imageData);
//     };

//     const addSignatureToPdf = async () => {
//         if (!pdfFile) {
//             message.error('Please upload a PDF file before submitting.');
//             return;
//         }

//         if (!signatureImage) {
//             message.error('Please capture your signature before submitting.');
//             return;
//         }

//         try {
//             const pdfBytes = await pdfFile.arrayBuffer();
//             // const pdfDoc = await AdobeSignAPI.load(pdfBytes);

//             // Add the signature image to the PDF using Adobe Sign API
//             // await AdobeSignAPI.addSignature(pdfDoc, signatureImage);

//             // Save the modified PDF
//             // const modifiedPdfBytes = await pdfDoc.save();
//             // Handle the modified PDF bytes, e.g., download it or send it to a server
//         } catch (error) {
//             console.error('Error adding digital signature to PDF:', error);
//             message.error('Error adding digital signature to PDF. See console for details.');
//         }
//     };

//     return (
//         <div>
//             <h1>Digital Signature Form</h1>
//             <Modal>
//                 <Review onSignatureCapture={handleSignatureCapture} />
//             </Modal>
//             <Upload
//                 accept=".pdf"
//                 beforeUpload={(file) => {
//                     handleFileChange(file);
//                     return false;
//                 }}
//                 // showUploadList={false}
//             >
//                 <Button icon={<UploadOutlined />}>Upload PDF</Button>
//             </Upload>
//             <Button type="primary" onClick={addSignatureToPdf}>Add Signature to PDF</Button>
//         </div>
//     );
// };

// export default DigitalSignatureForm;


import React, { useState } from 'react';
import { Button, Modal, message } from 'antd';
import Review from './Review'; // Assuming Review component is in the same directory

const DigitalSignatureForm = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [signatureImage, setSignatureImage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSignatureCapture = (imageData) => {
    setSignatureImage(imageData);
  };

  const handleModalOk = async () => {
    if (!pdfUrl) {
      message.error('Please fetch a PDF before adding the signature.');
      return;
    }

    if (!signatureImage) {
      message.error('Please capture your signature before submitting.');
      return;
    }

    try {
      // Fetch the PDF file
      const pdfBytes = await fetch(pdfUrl).then(response => response.blob());
      const pdfData = new FormData();
      pdfData.append('file', pdfBytes, 'original.pdf');

      // Upload the PDF to your server for processing
      const response = await fetch('your-upload-url', {
        method: 'POST',
        body: pdfData
      });

      if (!response.ok) {
        throw new Error('Failed to upload PDF for processing.');
      }

      // Process the PDF on your server to add the signature
      const result = await response.json();

      // Add the signature image to the processed PDF data
      result.signatureImage = signatureImage;

      // Upload the signed PDF back to the server
      const signedPdfData = new FormData();
      signedPdfData.append('file', JSON.stringify(result), 'signed.pdf');
      const uploadResponse = await fetch('your-upload-url', {
        method: 'POST',
        body: signedPdfData
      });

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload signed PDF.');
      }

      message.success('Signature added successfully.');
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error adding digital signature to PDF:', error);
      message.error('Error adding digital signature to PDF. See console for details.');
    }
  };

  const handleModalCancel = () => {
    // Close the modal and reset state
    setIsModalVisible(false);
    setPdfUrl('');
    setSignatureImage(null);
  };

  return (
    <div>
      <h1>Digital Signature Form</h1>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>Add Signature</Button>
      <Modal
        title="Add Signature"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Review onSignatureCapture={handleSignatureCapture} />
      </Modal>
    </div>
  );
};

export default DigitalSignatureForm;
