import React from 'react'
import { Row, Col, Input, ColorPicker, Upload, Button, Select, message } from 'antd'
import './ChatSettings/ChatSettings.css'
import BotPreview from './ChatSettings/BotPreview'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { AppUrl } from '../../Constants/Constants'
import { useSelector } from 'react-redux'
import { useParams,useNavigate } from 'react-router-dom'

const CreateNewChatbot = () => {
  const [ChatBotName, setChatBotName] = useState('')
  const [BubbleClr, setBubbleClr] = useState('#6B24D6')
  const [MessageClr, setMessageClr] = useState('#fff')
  const [ChatBotIcon, setChatBotIcon] = useState('')
  const [ChatBotProfile, setChatBotProfile] = useState("")
  const [Source, setSource] = useState("")
  const [FolderData, setFolderData] = useState([])
  const AUTH_TOKEN = useSelector((state) => state.authToken);
  const USER_DATA = useSelector((state) => state.user);
  const { botId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${AppUrl}list_of_folders?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}`, {
        headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          Accept: 'application/json'
        }
      })
      .then((res) => {
        console.log(res.data.folders, "res.data.folders")
        if(res.data.status == 201){
          navigate('/sessiontimeout')
        }else{

        
        if (res.data && res.data.folders) {
          let arr = []
          for (let i of res.data.folders) {
            let obj = {
              value: i.folder_id,
              label: i.folder_name
            }
            arr.push(obj)
          }
          setFolderData(arr);
        } else {
          setFolderData([]); // Ensure Data is always an array
        }
      }
      })
      .catch((err) => {
        message.error(err.message)
      })
  }, [])
  const props = {
    onChange(info) {
      convertTo1Base64(info.file.originFileObj);
    },
  };

  const convertTo1Base64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        setChatBotProfile(fileReader.result);
      };
    });
  };

  const props1 = {
    onChange(info) {
      convertToBase64(info.file.originFileObj);
    },
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        console.log(fileReader.result, "fileReader");
        setChatBotIcon(fileReader.result);
      };
    });
  };
  const HandleCreateBot = () => {
    let body = {
      company_id: USER_DATA?.company_id,
      user_id: USER_DATA?.user_id,
      folder_id: Source,
      bot_id: botId,
      chatbot_name: ChatBotName,
      chatbot_icon: ChatBotIcon,
      bubble_colour: BubbleClr,
      message_colour: MessageClr,
      align_chatbot_bubble_icon: "right",
      referance_page_number: true,
      welcome_message: '',
      welcome_img_path: '',
      public_response: true
    }
    axios
      .post(`${AppUrl}chat_bot_details`, body, {
        headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          Accept: 'application/json'
        }
      })
      .then((res) => {
        message.success("Chatbot Created")
        console.log(res,"response")
        navigate('/chatbots')
      })
      .catch((err) => {
        message.error(err.message)
      })
  }
  console.log(botId,"sourcechan")

  return (
    <div className='addUserClass'>
      <Row>
        <Col span={16}>
          <Row>
            <Col className='chatbotSettingsHeading'>
              <h3 className='sideMainHeading'>Create Chatbot</h3>
            </Col>
          </Row>
          <Row className='createChatForm'>
            <Col span={5}>
              <h3 className='addUserFormtext'>Chatbot Name</h3>
            </Col>
            <Col span={19}>
              <Input className='addUserInput' onChange={(e) => setChatBotName(e.target.value)} placeholder='Enter Chatbot Name' />
            </Col>
          </Row>
          <Row className='createChatForm'>
            <Col span={5}>
              <h3 className='addUserFormtext'>Bubble Color</h3>
            </Col>
            <Col span={4}>
              <ColorPicker
                value={BubbleClr}
                onChange={(e) => setBubbleClr(e.toHexString())}
                defaultValue="#6B24D6"
                className="createBotColorPicker"
              />
            </Col>
            <Col span={5}>
              <h3 className='addUserFormtext'>Message Color</h3>
            </Col>
            <Col>
              <ColorPicker
                value={MessageClr}
                onChange={(e) => setMessageClr(e.toHexString())}
                className="createBotColorPicker"
              />
            </Col>
          </Row>
          <Row className='createChatForm'>
            <Col span={5}>
              <h3 className='addUserFormtext' >Chatbot Icon</h3>
            </Col>
            <Col>
              <Upload
                {...props1}
                customRequest={({ onSuccess }) => onSuccess("ok")}
              >
                <Button className='UploadBTN'>
                  <img src='\Icons\uploadImg.svg' alt='Logo' />
                  Upload File
                </Button>
              </Upload>
              <p>
                Maximum 5MB only PDF, JPEG, PNG
              </p>
            </Col>
          </Row>
          <Row className='createChatForm'>
            <Col span={5}>
              <h3 className='addUserFormtext'>Chatbot Profile Pic</h3>
            </Col>
            <Col>
              <Upload
                {...props}
                customRequest={({ onSuccess }) => onSuccess("ok")}
              >
                <Button className='UploadBTN'>

                  <img src='\Icons\uploadImg.svg' alt='Logo' />
                  Upload File
                </Button>
              </Upload>
              <p>
                Maximum 5MB only PDF, JPEG, PNG
              </p>
            </Col>
          </Row>
          <Row className='createChatForm'>
            <Col span={5}>
              <h3 className='addUserFormtext'>Source</h3>
            </Col>
            <Col span={19}>
              <Select
                onChange={(e) => setSource(e)}
                className='addUserSelect' options={FolderData} placeholder='Select Source' />
            </Col>
          </Row>
          <Row className='addCancelSaveBtnRow'>
            <Col span={6}>
              <Button className='userCancelBtn' onClick={() => navigate('/chatbots')}>Cancel</Button>
            </Col>
            <Col offset={1} span={6}>
              <Button className='addNewUserBtn' onClick={HandleCreateBot}>Save</Button>
            </Col>
          </Row>
        </Col>
        <Col className='botPreviewIntegratedClass' span={7} offset={1}>
          <BotPreview
            Botname={ChatBotName}
            BubbleClr={BubbleClr}
            ProfileImg={ChatBotProfile}
            MessageClr={MessageClr}
          //  PublicRes={PublicRes}

          />
        </Col>
      </Row>
    </div>
  )
}

export default CreateNewChatbot