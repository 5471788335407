import { Button, Col, Input, Row, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import { Switch } from 'antd'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { AppUrl } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';

const ViewUser = () => {
    const navigate = useNavigate();

    const [status, setStatus] = useState('Active')
    const [data, setData] = useState([]);
    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('');
    const [Email, setEmail] = useState('');
    const [Designation, setDesignation] = useState('');
    const [Options, setOptions] = useState([]);
    const [JobOptions, setJobOptions] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [jobRoleId, setJobRoleId] = useState('');
    let url = window.location.href;
    let splittedUrl = url.split('/')[5];


    const AUTH_TOKEN = useSelector(state => state.authToken);
    const USER_DATA = useSelector(state => state.user);


    const { id } = useParams();
    console.log(splittedUrl, "url")

   

    const switchChange = (e) => {
        console.log(`switch to ${e}`);
        if (e === false) {
            setStatus('Inactive');
        } else {
            setStatus('Active');
        }
    }

    useEffect(() => {
        setLoading(true)
        axios
            .get(`${AppUrl}user_list_based_on_company?company_id=${USER_DATA?.company_id}`, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                console.log(res)
                if(res.status == 201) {
                    navigate('/sessiontimeout')
                }
                else{

                
                for (let i of res.data.response) {
                    if (i.user_id === splittedUrl) {
                        setData(i)
                    }
                }

                setLoading(false)
            }
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])
    useEffect(() => {
        axios
            .post(`${AppUrl}get_roles`, {
                company_id: USER_DATA?.company_id,
                user_id: USER_DATA?.user_id
            }, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                console.log(res, "designationOptions")
                setOptions(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    useEffect(() => {
        axios
            .get(`${AppUrl}get_all_job_roles?company_id=${USER_DATA?.company_id}&user_id=${id}`, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                // console.log(res, "designationOptions")
                let arr = []
                for (let i of res.data.job_roles) {
                    let obj = {
                        label: i.job_name,
                        value: i.job_id
                    }
                    arr.push(obj)

                }
                setJobOptions(arr)
                console.log(arr,"array")
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    console.log(data, "chan")
    return (
        <div className='addUserClass' >
            {data.length != 0 ?
                <>
                    <Row>
                        <Col span={10}>
                            <h3 className='sideMainHeading'>View User</h3>
                        </Col>
                        <Col offset={10} >
                        <div className='statusWholeDiv'>
                                <h3 className='addUserStatustext'>Status</h3>
                                <Switch
                                    value={status}
                                    className='addUserSwitchClass'
                                    defaultChecked={data.status}
                                    onChange={(value) => setStatus(value)} />
                                <p style={{ color: status === false ? '#ED1C24' : '#16ab00' }}>{status === false ? "Inactive" : "Active"}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>User Id</h3>
                        </Col>
                        <Col span={13}>
                            <Input
                                disabled
                                className='addUserInput' 
                                value={data.user_id}
                                placeholder='' />

                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>First Name</h3>
                        </Col>
                        <Col span={13}>
                            <Input
                                disabled
                                value={data.first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                className='addUserInput' placeholder='Enter your First Name' />
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>Last Name</h3>
                        </Col>
                        <Col span={13}>
                            <Input
                                disabled
                                value={data.last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                className='addUserInput' placeholder='Enter your Last Name' />
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>Email Id</h3>
                        </Col>
                        <Col span={13}>
                            <Input
                                disabled
                                value={data.email}
                                onChange={(e) => setEmail(e.target.value)}
                                className='addUserInput' placeholder='Enter your Email Id' />
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>Job Role</h3>
                        </Col>
                        <Col span={13}>
                            <Select
                             disabled
                                className='addUserSelect'
                                options={JobOptions}
                                value={JobOptions.find(option => option.value === data.jobrole_id)}
                                placeholder='Select Job Role'
                                onChange={(val) => {
                                    console.log(val, 'dropDownValue')
                                    setJobRoleId(val)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>User Role</h3>
                        </Col>
                        <Col span={13}>
                            <Select className='addUserSelect'
                                disabled
                                value={data.role}
                                options={Options} placeholder='Enter your Job Role' />
                        </Col>
                    </Row>
                   
                    <Row className='addCancelSaveBtnRow'>
                        <Col span={3}>
                            <Button onClick={() => navigate('/usermanagement')} className='userCancelBtn'>Cancel</Button>
                        </Col>
                        {/* <Col offset={1} span={3}>
                            <Button onClick={addObject} className='addNewUserBtn'>Save</Button>
                        </Col> */}
                    </Row>
                </>
                : <>
                </>}
        </div>
    )
}

export default ViewUser