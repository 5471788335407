// export const BaseUrl = 'http://3.237.194.247:9001'  //Local Url
// export const BaseUrl = 'http://54.89.10.171:9001'  //UAT Url
// export const BaseUrl = 'https://api-dev.precium.ai' //DEV Url
// export const BaseUrl = 'https://api-qa.precium.ai' // QA Url
export const BaseUrl = 'https://api-uat.precium.ai' // UAT Url

// export const AppUrl = `https://api.kms.healthitplatform.com/`

// export const AppUrl = `http://54.162.125.231:9000/`
// export const AppUrl = `http://3.216.28.246:9000/`
// export const AppUrl = `https://api.kms.healthitplatform.com/`
export const AppUrl = `https://novatris.healthitplatform.com/`
// export const AppUrl = `http://44.212.7.174:9000/`
// export const AppUrl = `http://184.73.142.221:9000/`
// export const AppUrl = `http://54.237.192.53:9000/`

//Dev Backend Microservices URL
// export const MicroUrl1 = 'https://api-dev-s1.precium.ai'
// export const MicroUrl1 = 'https://api-qa-s1.precium.ai'
export const MicroUrl1 = 'https://api-uat-s1.precium.ai'
// export const MicroUrl1 = 'http://3.237.194.247:9002'


// export const MicroUrl2 = 'https://api-dev-s2.precium.ai'
// export const MicroUrl2 = 'https://api-qa-s2.precium.ai'
export const MicroUrl2 = 'https://api-uat-s2.precium.ai'
// export const MicroUrl2 = 'http://3.237.194.247:9003'


// export const MicroUrl3 = 'https://api-dev-s3.precium.ai'
// export const MicroUrl3 = 'https://api-qa-s3.precium.ai'
export const MicroUrl3 = 'https://api-uat-s3.precium.ai'
// export const MicroUrl3 = 'http://3.237.194.247:9004'

// export const WebSocketUrl = 'wss://api-dev.precium.ai/ws'
// export const WebSocketUrl = 'wss://api-qa.precium.ai/ws'
export const WebSocketUrl = 'wss://api-uat.precium.ai/ws'

// export const WidgetUrl = 'https://widget.precium.ai'
// export const WidgetUrl = 'https://widget-qa.precium.ai'
export const WidgetUrl = 'https://widget-uat.precium.ai'
// export const WidgetUrl = 'http://3.237.194.247:3006'

export const REACT_APP_ACESS_KEY_ID = process.env.REACT_APP_ACESS_KEY_ID
export const REACT_APP_SECRET_ACESS_KEY = process.env.REACT_APP_SECRET_ACESS_KEY
// export const REACT_APP_ACESS_KEY_ID =  "AKIAVMTVZLEFAOM3N64G"
// export const REACT_APP_SECRET_ACESS_KEY =  "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"
export const REACT_APP_REGION = "us east"
export const adobeClientId = process.env.REACT_APP_ADOBE_CLIENT_ID
// export const adobeRedirectUrl = process.env.REACT_APP_REDIRECT_URL
// export const adobeRedirectUrl = 'https://app.kms.healthitplatform.com/'
export const adobeRedirectUrl = 'https://master.d2lkva8e7zbrvk.amplifyapp.com/'