import { useEffect, useState } from 'react'
import { Row, Col, Input, Card, Rate, Button, message, Table, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from 'react-router-dom';
import './Chatbot.css'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { AppUrl } from '../../Constants/Constants';
import { checkElementUpdatePresence } from '../../CommonFunctions/CommonFunctions';

const Publish = () => {
    const [data, setData] = useState([])
    const [SearchInput, setSearchInput] = useState('')
    const [Filtered, setFiltered] = useState([])
    const USER_ACCESS = useSelector(state => state.userAccess);
    const [loading, setloading] = useState(false)
    const [selectedFolderId, setselectedFolderId] = useState('')
    const { Column } = Table;
    const AUTH_TOKEN = useSelector(state => state.authToken);
    const USER_DATA = useSelector(state => state.user);


    useEffect(() => {
        axios
            .get(`${AppUrl}ready_to_publish?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}`, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                let newData = [];
                for (let i = 0; i < res.data.folder_details.length; i++) {
                    newData.push({ s_no: i + 1, ...res.data.folder_details[i] });
                }
                console.log(res.data.folder_details, "folders", newData)
                setData(newData)
                setFiltered(newData)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    const Handlerefresh = (e) => {
        console.log(e, "event")
        setselectedFolderId(e.folder_id)
        setloading(true)
        axios
            .post(`${AppUrl}refresh_bot?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}&folder_id=${e.folder_id}`,{},{
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                console.log(res,"res")
                if(res.status == 200){
                  message.success(res.data.message  ? res.data.message: res.data.response)  
                  setloading(false)
                }else{
                    message.error(res.data.message) 
                    setloading(false)
                }

            })
            .catch((err) => {
                message.error(err.message) 
                setloading(false)
            })
    }

    useEffect(() => {
        if (!SearchInput) {

            setFiltered(data);
        } else {

            const filteredData = data.filter((item) =>
                item.folder_names.toLowerCase().includes(SearchInput.toLowerCase()) // Match ignoring case
            );
            setFiltered(filteredData);
            console.log(filteredData, "filtered");
        }
    }, [SearchInput]);
    return (
        <div>
            <Row>
                <Col span={14}>
                    <h3 className='sideMainHeading'>Ready to Publish</h3>
                </Col>
                <Col span={6}>
                    <Input placeholder='Search by folder name' onChange={(e) => setSearchInput(e.target.value)} className='searchInput' />
                    <SearchOutlined className='inputSearch' />
                </Col>
                <Col span={4}>
                    <div className='filterClass'>
                        <img src='/Icons/filter.svg' width={20} height={20} alt='' />
                        <h3 className='filterWord'>Filter</h3>
                    </div>
                </Col>

            </Row>
            <Row>
                <Col span={24}>
                <Table dataSource={Filtered} className='userManagementTable' >
    <Column
        title="S.no"
        dataIndex="s_no"
        key="s_no"
        align="center"
        width={100}  // Adjust this width as needed
    />
    <Column
        title="Folder Name"
        dataIndex="folder_name"
        key="folder_name"
        width={200}  // Adjust this width as needed
    />
    <Column
        title="User Name"
        dataIndex="user_names"
        key="user_names"
        width={200}  // Adjust this width as needed
    />
    <Column
        title="Action"
        key="action"
        align="center"
        width={250}  // Adjust this width as needed
        render={(_, record) => (
            <Space size="middle">
                {checkElementUpdatePresence(USER_ACCESS, 4) ? (
                    <>
                        <Button
                            className="viewEditBtn"
                            loading={record.folder_id === selectedFolderId ? loading : false}
                            onClick={() => Handlerefresh(record)}
                        >
                            Refresh Bot
                        </Button>
                    </>
                ) : null}
            </Space>
        )}
    />
</Table>

                </Col>
            </Row>
        </div>
    )
}

export default Publish