// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbotSettingsHeading{
    margin-bottom: 2%;
}
.createBotColorPicker{
    width: 48px;
    height: 48px;
}
.createBotColorPicker .ant-color-picker-color-block{
    width: 36px;
    height: 36px;
}
.createChatForm{
    margin-top: 4%;
}
.botPreviewIntegratedClass{
    padding-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/Components/AiAssistants/ChatSettings/ChatSettings.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,cAAc;AAClB;AACA;IACI,eAAe;AACnB","sourcesContent":[".chatbotSettingsHeading{\n    margin-bottom: 2%;\n}\n.createBotColorPicker{\n    width: 48px;\n    height: 48px;\n}\n.createBotColorPicker .ant-color-picker-color-block{\n    width: 36px;\n    height: 36px;\n}\n.createChatForm{\n    margin-top: 4%;\n}\n.botPreviewIntegratedClass{\n    padding-top: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
