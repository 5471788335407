import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Card, Button, Modal, Popover, Select, message } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import './Library.css';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { AppUrl } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';

const Library = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [folders, setFolders] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isFavUpdated, setIsFavUpdated] = useState(false);
    const [Filtered, setFiltered] = useState([])
    const [SearchInput, setSearchInput] = useState('')

    const AUTH_TOKEN = useSelector(state => state.authToken);
    const userDetails = useSelector(state => state.user);

    // Modal control functions
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // Fetch folders when component mounts or when favorite status changes
    useEffect(() => {
        axios.get(`${AppUrl}folder_list_libraries?company_id=${userDetails.company_id}&user_id=${userDetails?.user_id}`, {
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                Accept: 'application/json',
            },
        })
            .then((res) => {
                if (res.data.status === 200) {
                    setFolders(res.data.folders);
                    setFiltered(res.data.folders);
                } else if (res.data.status === 201) {
                    navigate('/sessiontimeout')
                }
            })
            .catch((err) => {
                message.error(err.message);
            });
    }, [isFavUpdated]);

    // Assign modal content
    const content = (
        <div>
            <p className='assignPara' onClick={() => setModalOpen(true)}>Assign To</p>
        </div>
    );

    const assignOptions = [
        {
            value: 'PSC Agent',
            label: 'PSC Agent',
        },
    ];

    const submitAssignee = () => {
        sessionStorage.setItem('assign', true);
        message.success('Assigned to PSC Agent!');
        setModalOpen(false);
    };

    const handleFileNavigate = (folderId) => {
        navigate(`/folder/${folderId}`);
    };

    // Handle toggle favorite
    const handleToggleFavorite = (item) => {
        const body = {
            company_id: userDetails.company_id,
            user_id: userDetails.user_id,
            folder_id: item.folder_id,
            is_favourite: !item.is_favourite,
        };

        axios.post(`${AppUrl}favourities_folder`, body, {
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                Accept: 'application/json',
            },
        })
            .then((res) => {
                message.success('Favorite status updated successfully');
                setIsFavUpdated(!isFavUpdated); // Trigger re-render by updating state
            })
            .catch((err) => {
                message.error(err.message);
            });
    };
    useEffect(() => {
        let filteredData = folders;

        // If there is a search input, filter the folders based on it
        if (SearchInput) {
            filteredData = folders.filter((item) =>
                item.folder_name.toLowerCase().includes(SearchInput.toLowerCase())
            );
        }

        // Separate favorite and non-favorite folders
        const favoriteFolders = filteredData.filter(item => item.is_favourite);
        const nonFavoriteFolders = filteredData.filter(item => !item.is_favourite);

        // Update filtered state with favorite folders first
        setFiltered([...favoriteFolders, ...nonFavoriteFolders]);

    }, [SearchInput, folders]); // Depend on both SearchInput and folders
    return (
        <div className='foldersMainClass'>
            {/* Assign Modal */}
            <Modal title='Assign' open={modalOpen} onOk={submitAssignee} onCancel={() => setModalOpen(false)}>
                <Select style={{ width: 300 }} placeholder='Select Assignee' options={assignOptions} />
            </Modal>

            {/* Add New Folder Modal */}
            <Modal title='Add New Folder' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Row>
                    <p className='Foldername'>Folder Name</p>
                    <Input className='FolderNameInput'

                        placeholder='Enter Folder Name' />
                </Row>
                <Row className='buttonDiv'>
                    <Col span={8}>
                        <Button onClick={handleCancel} className='userCancelBtn'>
                            Cancel
                        </Button>
                    </Col>
                    <Col offset={1} span={8}>
                        <Button onClick={handleOk} className='addNewUserBtn'>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Modal>

            {/* Main Library Layout */}
            <Row>
                <Col span={14}>
                    <h3 className='sideMainHeading'>Library</h3>
                </Col>
                <Col span={6}>
                    <Input placeholder='Search by document name'
                        onChange={(e) => setSearchInput(e.target.value)}
                        className='searchInput' />

                    <SearchOutlined className='inputSearch' />
                </Col>
                <Col span={4}>
                    <div className='filterClass'>
                        <img src='/Icons/filter.svg' width={20} height={20} alt='' />
                        <h3 className='filterWord'>Filter</h3>
                    </div>
                </Col>
            </Row>

            {/* Folder Display */}
            <Row className='folderRow'>
                {Filtered.map((item) => (
                    <Col className='folderColumnClass' span={5} key={item.folder_id}>
                        <Card className='documentlibraryCards'>
                            <div className='libraryIcons'>
                                <img src='/Icons/folder.svg' alt='' width={50} height={45.57} />
                                <div className='starAndVertIcon'>
                                    <div
                                        className={`Star ${item.is_favourite ? 'selected' : ''}`}
                                        onClick={() => handleToggleFavorite(item)} // Handle favorite toggle
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {item.is_favourite ? '★' : '☆'} {/* Change based on favorite status */}
                                    </div>
                                </div>
                            </div>
                            <h3 className='folderNameText'>{item.folder_name}</h3>
                            <p className="createdTimeText">
                                Created on : <span>
                                    {new Date(item.create_date).toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: 'short', // 'short' gives the 3-letter month abbreviation (e.g., Oct)
                                        year: 'numeric',
                                    }).replace(/ /g, '-')} {/* Replaces spaces between date parts with dashes */}
                                    {' '}
                                    {new Date(item.create_date).toLocaleTimeString('en-GB', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                        hour12: false, // Use 24-hour format
                                    })}
                                </span>
                            </p>

                            <p className='createdTimeText'>Last Modified on: <span>{item.modified_at}</span></p>
                            <h3 className='folderOpenClass' onClick={() => handleFileNavigate(item.folder_id)}>
                                Open <ArrowRightAltIcon className='rightOpenArrow' />
                            </h3>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Library;
