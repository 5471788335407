// reducers.js
import { LOGIN_USER, LOGOUT_USER,AUTH_TOKEN, USER_ACCESS } from './Actions';

const initialState = {
  isAuthenticated: false,
  user: null,
  authToken:null,
  userAccess : []
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };
    case LOGOUT_USER:
      return {  
        ...state,
        isAuthenticated: false,
        user: null
      };
    case AUTH_TOKEN:
        return{
            ...state,
            authToken:action.payload
        }
    case USER_ACCESS:
        return{
            ...state,
            userAccess:action.payload
        }

      
    default:
      return state;
  }
};

export default authReducer;
