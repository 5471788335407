import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Modal,
  message,
  Checkbox,
  DatePicker,
  Upload,
} from "antd";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Review from "../../Document/Review/Review";
import { Stage, Layer, Line } from "react-konva";
// import { Document, Page } from 'react-pdf';
// import { PDFViewer, Document, Page} from '@react-pdf/renderer';
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import {
  AppUrl,
  adobeClientId,
  adobeRedirectUrl,
  redirectUrl,
} from "../../../Constants/Constants";
import moment from "moment";

const AWS = require("aws-sdk");
const { PDFDocument, rgb } = require("pdf-lib");

const Approve = () => {
  let url = window.location.href;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fileId = queryParams.get("fileId");
  const folderId = queryParams.get("folderId");
  // const { fileId, folderId } = useParams();

  const { TextArea } = Input;

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [PageNumber, setPageNumber] = useState(0);
  const USER_DATA = useSelector((state) => state.user);
  console.log(USER_DATA, "userdata");

  const [folderName, setFolderName] = useState("");
  const [fileName, setFileName] = useState("");
  const [comments, setComments] = useState("");

  const [ownerName, setOwnerName] = useState("");
  const [expiryDate, setExpiryDate] = useState(""); // Date is set when checkbox is checked
  const [filePath, setFilePath] = useState("");

  const [eSignBtnLoading, setEsignBtnLoading] = useState(false);
  const [approveBtnLoading, setApproveBtnLoading] = useState(false);
  const [ownerid, setOwnerId] = useState("");
  const [adobeBearer, setAdobeBearer] = useState("");
  const [email, setEmail] = useState("");
  const [signModal, setSignModal] = useState(false);
  const [agreementId, setAgreementId] = useState("");
  const [emailBtnLoading, setEmailBtnLoading] = useState(false);

  const [storeFileId, setStoreFileId] = useState(
    sessionStorage.getItem("fileId")
  );
  const [storeFolderId, setStoreFolderId] = useState(
    sessionStorage.getItem("folderId")
  );

  useEffect(() => {
    if (fileId) {
      setStoreFileId(fileId);
      setStoreFolderId(folderId);
      sessionStorage.setItem("folderId", folderId);
      sessionStorage.setItem("fileId", fileId);
    } else {
      setStoreFileId(sessionStorage.getItem("fileId"));
      setStoreFolderId(sessionStorage.getItem("folderId"));
    }
  }, []);

  console.log(storeFileId, "storeFileId", storeFolderId);

  useEffect(() => {
    const fetchFileData = async () => {
      try {
        const response = await axios.get(
          `${AppUrl}view_one_file?company_id=${USER_DATA.company_id}&user_id=${USER_DATA.user_id}&file_id=${storeFileId}`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${USER_DATA.token}`,
            },
          }
        );

        if (response.status === 200) {
          const data = response.data;
          setFolderName(data.folder_name || "");
          setFileName(data.file_name || "");
          setOwnerName(data.uploaded_by || "");
          setExpiryDate(
            data.document_expiry_date !== ""
              ? moment(data.document_expiry_date || "")
              : ""
          );
          setFilePath(data.file_path);
        } else {
          message.error("Error fetching file details!");
        }
      } catch (error) {
        console.error("Error fetching file data:", error);
      }
    };
    console.log("date", expiryDate);

    fetchFileData();
  }, [USER_DATA.company_id, USER_DATA.user_id, storeFileId, USER_DATA.token]);

  // Handle folder name change
  const handleFolderNameChange = (value) => {
    setFolderName(value);
  };

  // Handle file name input change
  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  // Handle comments input change
  const handleCommentsChange = (e) => {
    setComments(e.target.value);
    console.log(comments, "comments");
  };

  // Handle expiry date change from DatePicker
  const handleExpiryDateChange = (date, dateString) => {
    setExpiryDate(dateString); // Set date string from the DatePicker
  };

  const navigate = useNavigate();

  const AUTH_TOKEN = useSelector((state) => state.authToken);

  const [folderOptions, setFolderOptions] = useState([]);

  useEffect(() => {
    getFolderNames();
  }, []);

  const getFolderNames = () => {
    axios
      .get(
        `${AppUrl}list_of_folder_based_on_company?company_id=${USER_DATA.company_id}`,
        {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data, "optionAPI");
        if (res.data.status === 200) {
          let arr = [];
          for (let i of res.data.folders) {
            let obj = {
              value: i.folder_id,
              label: i.folder_name,
            };
            arr.push(obj);
          }
          setFolderOptions(arr);
        } else {
          setFolderOptions(null);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const s3 = new AWS.S3({
    accessKeyId: "AKIAVMTVZLEFBXHWQL72",
    secretAccessKey: "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",
    region: "us-east-1",
  });

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const [DOCURL, setDOCURL] = useState(
    "https://preciumweb-dev.s3.amazonaws.com/dev/042e34e3-2d19-47ce-bd69-9532fe42d798/59296da0-9aa0-44c4-9ac5-34adcfc970b8/JA-020+Salesforce+Case+Comments+and+Chatter+Functionalities+for+Novartis+Patient+Support+Center.pdf"
  );
  const showModal = (e, val) => {
    const updateFileName = DOCURL.replace(
      /ded2w7tw5rnvn.cloudfront.net/,
      "preciumweb-dev.s3.amazonaws.com"
    );

    setDOCURL(updateFileName);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen(false);
  };

  const onChange1 = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleApprove = async () => {
    // checkAgreementStatus('CBJCHBCAABAASo7URBp47ta_k1mBtOxIYbS8K_ThfnTd')
    checkAgreementStatus(agreementId)
      .then((agreementStatus) => {
        console.log(agreementStatus, "agreementStatusagreementStatus");
        if (agreementStatus === "SIGNED") {
          try {
            const payload = {
              company_id: USER_DATA.company_id,
              folder_name: folderName,
              file_name: fileName,
              approver_id: USER_DATA.user_id,
              comments: comments,
              //   expiry_date: expiryDate !== "" ? new Date(expiryDate) : null,
              file_id: storeFileId,
            };

            axios
              .post(`${AppUrl}approve_review`, payload, {
                headers: {
                  accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${USER_DATA.token}`,
                },
              })
              .then((res) => {
                console.log(res.data, "responseStatus");
                if (res.data.status === 200) {
                  message.success("Approval submitted successfully!");
                  setTimeout(() => {
                    navigate(`/document/files/${storeFolderId}`);
                  }, 2000);
                } else {
                  message.error("Failed to submit approval.");
                  navigate(`/document/files/${storeFolderId}`);
                }
              })
              .catch((err) => {
                message.error(err.message);
              });
          } catch (error) {
            console.error("Error submitting approval:", error);
            message.error("There was an error submitting the approval.");
            navigate(`/document/files/${storeFolderId}`);
          }
        } else {
          message.warning(`Document is ${agreementStatus}`);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const handleReject = async () => {
    try {
      const payload = {
        company_id: USER_DATA.company_id,
        folder_name: folderName,
        file_name: fileName,
        rejector_id: USER_DATA.user_id,
        comments: comments,
        // expiry_date: new Date(expiryDate).toISOString().split("T")[0],
        file_id: fileId,
      };

      const response = await axios.post(`${AppUrl}reject`, payload, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${USER_DATA.token}`,
        },
      });

      if (response.status === 200) {
        message.success("Rejected submitted successfully!");
        navigate(`/document/files/${folderId}`);
      } else {
        message.error("Failed to Reject.");
      }
    } catch (error) {
      console.error("Error submitting Reject:", error);
      message.error("There was an error submitting the Reject.");
    }
  };

  console.log(fileId, "fileIdGetParams");

  // const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  console.log(adobeRedirectUrl, "urlCode");

  const handleAuthenticate = () => {
    const clientId = adobeClientId;
    // const redirectUri = encodeURIComponent(`${adobeRedirectUrl}document/files/approve/${storeFolderId}/${storeFileId}`);
    // const redirectUri = encodeURIComponent(
    //   `${adobeRedirectUrl}document/files/approve/`
    // );
    // const redirectUri = encodeURIComponent(
    //   "https://app.kms.healthitplatform.com/document/files/approve/"
    // );
    const redirectUri = encodeURIComponent(
      "https://master.d2lkva8e7zbrvk.amplifyapp.com/document/files/approve/"
    );
    console.log(redirectUri, "redirectUriredirectUri");
    const responseType = "code";
    const scope =
      "user_login:self+agreement_read:account+agreement_write:account";
    const adobeAuthUrl = `https://secure.in1.adobesign.com/public/oauth/v2?redirect_uri=${redirectUri}&response_type=code&client_id=${clientId}&scope=user_login:self+agreement_read:self+agreement_write:self&state=S6YQD7KDA556DIV6NAU4ELTGSIV26ZNMXDSF7WIEEP0ZLQCLDQ89OYG78C3K9SROC8DXCGRVSGKU1IT1`;

    window.location.href = adobeAuthUrl;
  };

  useEffect(() => {
    if (code !== null) {
      setEsignBtnLoading(true);
      setApproveBtnLoading(true);
      let body = {
        auth_code: code,
      };

      axios
        .post(`${AppUrl}exchange-code`, body, {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log(res, "responseValue");
          if (res.data.status === 200) {
            setAdobeBearer(res.data.access_token);
            setEsignBtnLoading(false);
            setApproveBtnLoading(false);
          } else {
            message.error(`Can't exchange code!`);
            navigate(`/document/files/${storeFolderId}`);
          }
        })
        .catch((err) => {
          message.error(err.message);
          setEsignBtnLoading(false);
          setApproveBtnLoading(false);
          navigate(`/document/files/${storeFolderId}`);
        });
    }
  }, []);

  async function downloadFileFromS3(s3Url) {
    const response = await fetch(s3Url);
    const blob = await response.blob();
    return new File([blob], fileName); // Change the file name and type as needed
  }

  const handleESign = () => {
    setSignModal(true);
  };

  const handleSignModal = async () => {
    setEmailBtnLoading(true);
    if (!email) {
      message.error("Please provide a recipient email.");
      return;
    }

    try {
      const file = await downloadFileFromS3(filePath);
      const formData = new FormData();
      formData.append("File", file);
      const uploadConfig = {
        headers: {
          //Bearer Token
          // 'Authorization': `Bearer 3AAABLblqZhAbIVm7bsezZYxNt3wSYqx0mPhP04D2zDGvCyL6KT-aX5o42tc8KDAccSSVVDDXfnA5TqZxfMwqjm_H-UkTeaKT`,
          Authorization: `Bearer ${adobeBearer}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const uploadResponse = await axios.post(
        "https://api.in1.adobesign.com/api/rest/v6/transientDocuments",
        formData,
        uploadConfig
      );
      const transientDocumentId = uploadResponse.data.transientDocumentId;

      console.log(uploadResponse, "signingDocumentId");

      const agreementData = {
        fileInfos: [
          {
            transientDocumentId: transientDocumentId,
          },
        ],
        name: "Please Sign This Document",
        participantSetsInfo: [
          {
            order: 1,
            role: "SIGNER",
            memberInfos: [
              {
                email: email,
              },
            ],
          },
        ],
        signatureType: "ESIGN",
        state: "IN_PROCESS",
      };
      const agreementConfig = {
        headers: {
          //Bearer Token
          // 'Authorization': `Bearer 3AAABLblqZhAbIVm7bsezZYxNt3wSYqx0mPhP04D2zDGvCyL6KT-aX5o42tc8KDAccSSVVDDXfnA5TqZxfMwqjm_H-UkTeaKT`,
          Authorization: `Bearer ${adobeBearer}`,
          "Content-Type": "application/json",
        },
      };
      const agreementRequest = await axios.post(
        "https://api.in1.adobesign.com/api/rest/v6/agreements",
        agreementData,
        agreementConfig
      );
      const agreementResponse = agreementRequest;
      console.log(agreementResponse.data, "agreementResponse");
      setAgreementId(agreementResponse.data.id);

      let agreementBody = {
        company_id: USER_DATA.company_id,
        file_id: fileId,
        transient_id: agreementResponse.data.id,
      };
      axios
        .get(
          `${AppUrl}transient_id?company_id=${USER_DATA.company_id}&file_id=${storeFileId}&transient_id=${agreementResponse.data.id}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            setSignModal(false);
            setEmailBtnLoading(false);
          }
        })
        .catch((err) => {
          message.error(err.message);
          console.log(err, "transient_id API Py error");
          setEmailBtnLoading(false);
        });

      message.success("Document sent for signing successfully!");
    } catch (err) {
      message.error(err.message);
      setEmailBtnLoading(false);
    }
  };

  async function checkAgreementStatus(agreementId) {
    try {
      const response = await fetch(
        `https://api.in1.adobesign.com/api/rest/v6/agreements/${agreementId}`,
        {
          method: "GET",
          headers: {
            //   'Authorization': `Bearer 3AAABLblqZhDsuFsQY6teGDapw6U2k1aWIpaYrENSagoENMtSjnMZoAszM0Kv-vwHS3MGvKDnSjHWDO8MkvNdNocTBIgItzuL`,
            Authorization: `Bearer ${adobeBearer}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to check agreement status");
      }

      const data = await response.json();
      console.log(data, "agreementStatusResponse");
      return data.status; // This will return the status of the agreement
    } catch (error) {
      console.error("Error checking agreement status:", error);
    }
  }

  const handleCancelModal = () => {
    setSignModal(false);
    setEmail("");
  };

  return (
    <div className="reviewClass">
      <Modal
        className="Modal1"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel1}
        footer={null}
      >
        <div>
          <Document
            file={filePath}
            onLoadSuccess={onDocumentLoadSuccess}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          >
            {PageNumber === 0 ? (
              Array.from({ length: numPages }, (_, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))
            ) : (
              <Page pageNumber={PageNumber} />
            )}
          </Document>
        </div>
      </Modal>
      <Modal
        open={signModal}
        footer={[
          <Button onClick={handleCancelModal}>Cancel</Button>,
          <Button
            loading={emailBtnLoading}
            type="primary"
            onClick={handleSignModal}
          >
            Send
          </Button>,
        ]}
        onOk={handleSignModal}
        onCancel={handleCancelModal}
        title="E-Sign"
      >
        <Input
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Modal>
      <Row>
        <Col span={10}>
          <h3 className="sideMainHeading">Review/Approve</h3>
        </Col>
      </Row>
      <Row className="addUserForm">
        <Col span={3}>
          <h3
            style={{ marginBottom: 65 }}
            className="reviewFormtext"
            value={folderName}
          >
            Folder Name
          </h3>
          <h3
            style={{ marginBottom: 65 }}
            className="reviewFormtext"
            value={fileName}
          >
            File Name
          </h3>
          <h3 style={{ marginBottom: 75 }} className="reviewFormtext">
            Owner Name
          </h3>
          <h3
            style={{ marginBottom: 115 }}
            className="reviewFormtext"
            value={comments}
          >
            Comments
          </h3>
          <h3
            style={{ marginTop: 220 }}
            className="reviewFormtext"
            onChange={handleExpiryDateChange}
          >
            Expiration Date
          </h3>
        </Col>
        <Col span={13}>
          <Select
            className="reviewSelect"
            value={folderName}
            options={folderOptions}
            disabled
            placeholder="Select your Folder Name"
          />
          <Input
            className="reviewInput"
            value={fileName}
            disabled
            placeholder="Enter your File Name"
          />
          <Input
            className="reviewInputUserId"
            value={ownerName}
            disabled
            placeholder="Enter Owner Name"
          />
          <TextArea
            rows={4}
            className="reviewTextArea"
            onChange={handleCommentsChange}
            placeholder="Enter your Comments"
          />

          <Row>
            <Col span={12}>
              {/* <Upload 
                    customRequest={({ onSuccess }) => onSuccess("ok")}
                    > */}
              {code !== null ? (
                <Button
                  className="UploadBTN"
                  loading={eSignBtnLoading}
                  onClick={handleESign}
                >
                  <img src="\Icons\SIgnatureImg.svg" alt="Logo" />
                  E-Sign
                </Button>
              ) : (
                <Button className="UploadBTN" onClick={handleAuthenticate}>
                  <img src="\Icons\SIgnatureImg.svg" alt="Logo" />
                  Authentication for E-Sign
                </Button>
              )}
              {/* </Upload> */}
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col></Col>
          </Row>
          <Row>
            <Col span={24}>
              <DatePicker
                className="reviewInputDate"
                value={expiryDate}
                disabled
                onChange={onChange1}
              />
            </Col>
          </Row>
        </Col>
        <Col offset={1} span={7}>
          <div onClick={showModal} className="MaterialSide">
            <img
              style={{ display: "flex", justifyContent: "center" }}
              src="/Icons/material2.svg"
              alt="Logo"
            />
            <h3 style={{ marginBottom: 65 }} className="reviewFormtext">
              {fileName}
            </h3>
          </div>
        </Col>
      </Row>
      <Row className="addCancelSaveBtnRow">
        <Col span={3}>
          <Button onClick={handleReject} className="userCancelBtn">
            Reject
          </Button>
        </Col>
        <Col offset={1} span={3}>
          <Button
            className="addNewUserBtn"
            loading={approveBtnLoading}
            onClick={handleApprove}
          >
            Approve
          </Button>{" "}
          {/*While approving check for document's sign status*/}
        </Col>
      </Row>
    </div>
  );
};

export default Approve;
