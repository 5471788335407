// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container{
  max-width: 1536px;
  margin: 0 auto;
  /* min-height: 100vh; */
  min-height: 84vh;
  padding: 100px 40px 0px 40px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,uBAAuB;EACvB,gBAAgB;EAChB,4BAA4B;AAC9B","sourcesContent":[".main-container{\n  max-width: 1536px;\n  margin: 0 auto;\n  /* min-height: 100vh; */\n  min-height: 84vh;\n  padding: 100px 40px 0px 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
