// import React, { useState, useRef, useEffect } from 'react';

// const Review = ({ onSignatureCapture, clear, submit }) => {
//   const canvasRef = useRef(null);
//   const [isDrawing, setIsDrawing] = useState(false);
//   const [prevPos, setPrevPos] = useState({ x: 0, y: 0 });
//   const [ clearSign, setClearSign ] = useState(clear);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext('2d');

//     const getRelativePos = (event) => {
//       const rect = canvas.getBoundingClientRect();
//       return {
//         x: event.clientX - rect.left,
//         y: event.clientY - rect.top
//       };
//     };

//     const handleMouseDown = (event) => {
//       setIsDrawing(true);
//       setPrevPos(getRelativePos(event));
//     };

//     const handleMouseMove = (event) => {
//       if (!isDrawing) return;

//       const currentPos = getRelativePos(event);

//       ctx.beginPath();
//       ctx.moveTo(prevPos.x, prevPos.y);
//       ctx.lineTo(currentPos.x, currentPos.y);
//       ctx.strokeStyle = '#000'; // Set stroke color
//       ctx.lineWidth = 3; // Set line width
//       ctx.stroke();

//       setPrevPos(currentPos);
//     };

//     const handleMouseUp = () => {
//       setIsDrawing(false);
//     };

//     canvas.addEventListener('mousedown', handleMouseDown);
//     canvas.addEventListener('mousemove', handleMouseMove);
//     canvas.addEventListener('mouseup', handleMouseUp);

//     return () => {
//       canvas.removeEventListener('mousedown', handleMouseDown);
//       canvas.removeEventListener('mousemove', handleMouseMove);
//       canvas.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, [isDrawing, prevPos]);

//   console.log(clear, 'signatureClear')
//   useEffect(() => {
//     if( clearSign === true ){
//       clearCanvas();
//       setClearSign(false)
//     }

//     if( submit === true ){
//       handleSignatureSubmit();
//     }

//   }, [clear, submit])

//   const clearCanvas = () => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext('2d');
//     ctx.clearRect(0, 0, canvas.width, canvas.height);
//   };

//   const handleMouseEnter = () => {
//     canvasRef.current.style.cursor = 'crosshair';
//   };

//   const handleMouseLeave = () => {
//     canvasRef.current.style.cursor = 'default';
//   };

//   const handleSignatureSubmit = () => {
//     const canvas = canvasRef.current;
//     const signatureImage = canvas.toDataURL('image/png');
//     onSignatureCapture(signatureImage);
//   };

//   return (
//     <div>
//       <p className='modalText' style={{ fontSize: '16px', fontWeight: 600, color: '#2D6FAF' }}>Draw</p>
//       <canvas
//         ref={canvasRef}
//         width={470}
//         height={300}
//         style={{ border: '1px solid #949494', borderRadius: '8px', width: '690px', height: '260px' }}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       />
//       {/* <button onClick={clearCanvas}>Clear</button> */}
//       {/* <button onClick={handleSignatureSubmit}>Submit</button> */}
//     </div>
//   );
// };

// export default Review;

import React, { useState } from 'react';
import { Stage, Layer, Line } from 'react-konva';

const DrawingCanvas = () => {
  const [lines, setLines] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);

  const handleMouseDown = (event) => {
    setIsDrawing(true);
    const pos = event.target.getStage().getPointerPosition();
    setLines([...lines, { points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (event) => {
    if (!isDrawing) return;

    const stage = event.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];

    // Append new points to the last line
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // Update the state to re-render
    setLines([...lines.slice(0, -1), lastLine]);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  return (
    <>
    <p className='modalText' style={{ fontSize: '16px', fontWeight: 600, color: '#2D6FAF' }}>Draw</p>
    <Stage className='reacKonvaMainClass'
      width={700}
      height={300}
      onMouseDown={handleMouseDown}
      onMousemove={handleMouseMove}
      onMouseup={handleMouseUp}
    >
      <Layer>
        {lines.map((line, index) => (
          <Line
            key={index}
            points={line.points}
            stroke="#000"
            strokeWidth={3}
            lineCap="round"
            lineJoin="round"
          />
        ))}
      </Layer>
    </Stage>
    </>
  );
};

export default DrawingCanvas;

// const handleModalOk = async () => {
    //     if (!pdfUrl) {
    //         message.error('Please fetch a PDF before adding the signature.');
    //         return;
    //     }

    //     if (!signatureImage) {
    //         message.error('Please capture your signature before submitting.');
    //         return;
    //     }

    //     try {
    //         // Fetch the PDF file
    //         const pdfBytes = await fetch(pdfUrl).then(response => response.blob());
    //         const pdfData = new FormData();
    //         pdfData.append('file', pdfBytes, 'original.pdf');

    //         // Upload the PDF to your server for processing
    //         const response = await fetch('your-upload-url', {
    //             method: 'POST',
    //             body: pdfData
    //         });

    //         if (!response.ok) {
    //             throw new Error('Failed to upload PDF for processing.');
    //         }

    //         // Process the PDF on your server to add the signature
    //         const result = await response.json();

    //         // Add the signature image to the processed PDF data
    //         result.signatureImage = signatureImage;

    //         // Upload the signed PDF back to the server
    //         const signedPdfData = new FormData();
    //         signedPdfData.append('file', JSON.stringify(result), 'signed.pdf');
    //         const uploadResponse = await fetch('your-upload-url', {
    //             method: 'POST',
    //             body: signedPdfData
    //         });

    //         if (!uploadResponse.ok) {
    //             throw new Error('Failed to upload signed PDF.');
    //         }

    //         message.success('Signature added successfully.');
    //         setIsModalVisible(false);
    //     } catch (error) {
    //         console.error('Error adding digital signature to PDF:', error);
    //         message.error('Error adding digital signature to PDF. See console for details.');
    //     }
    // };
