
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const USER_ACCESS = 'USER_ACCESS';


export const loginUser = (userData) => ({
  type: LOGIN_USER,
  payload: userData
});

export const logoutUser = () => ({
  type: LOGOUT_USER
});

export const authToken =(authCode) => ({
    type: AUTH_TOKEN,
    payload:authCode
})

export const userAccess =(userAccess) => ({
    type: USER_ACCESS,
    payload:userAccess
})
