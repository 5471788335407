// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Star{
    float: right;
}
.documentlibraryCards.ant-card .ant-card-body {
    padding: 15px 10px 1px 20px !important;
}
.documentlibraryCards.ant-card-bordered{
    border: 1px solid #CBCBCB !important;
    border-radius: 5px;
}
.FOlderNameInput{
    height: 48px;
}
.Foldername{
    color: rgba(45, 111, 175, 1);
    font-weight: 600;
    margin-bottom: 2PX;
}
.buttonDiv{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.libraryIcons{
    display: flex;
    justify-content: space-between;
}
.startandVertIcon{
    display: flex;
}
.threeDotIcon{
    cursor: pointer;
}
.assignPara{
    cursor: pointer;
    margin: 0;
}
.Star {
    font-size: 24px;
    color: gray;
  }
  
  .Star.selected {
    color: gold;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Library/Library/Library.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,sCAAsC;AAC1C;AACA;IACI,oCAAoC;IACpC,kBAAkB;AACtB;AACA;IACI,YAAY;AAChB;AACA;IACI,4BAA4B;IAC5B,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,SAAS;AACb;AACA;IACI,eAAe;IACf,WAAW;EACb;;EAEA;IACE,WAAW;EACb","sourcesContent":[".Star{\n    float: right;\n}\n.documentlibraryCards.ant-card .ant-card-body {\n    padding: 15px 10px 1px 20px !important;\n}\n.documentlibraryCards.ant-card-bordered{\n    border: 1px solid #CBCBCB !important;\n    border-radius: 5px;\n}\n.FOlderNameInput{\n    height: 48px;\n}\n.Foldername{\n    color: rgba(45, 111, 175, 1);\n    font-weight: 600;\n    margin-bottom: 2PX;\n}\n.buttonDiv{\n    margin-top: 20px;\n    display: flex;\n    justify-content: center;\n}\n.libraryIcons{\n    display: flex;\n    justify-content: space-between;\n}\n.startandVertIcon{\n    display: flex;\n}\n.threeDotIcon{\n    cursor: pointer;\n}\n.assignPara{\n    cursor: pointer;\n    margin: 0;\n}\n.Star {\n    font-size: 24px;\n    color: gray;\n  }\n  \n  .Star.selected {\n    color: gold;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
