// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folderListDiv{
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
}
.folderListActiveDiv{
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px;
    background: #2D6FAF;
    border-radius: 8px;
}
.folderListDiv h4{
    margin: 4px 0 0 15px;
    color: #235789;
}
.folderListActiveDiv h4{
    margin: 4px 0 0 15px;
    color: #ffffff;
}
.listOfFilderCard{
    background-color: #E7F0F9;
}`, "",{"version":3,"sources":["webpack://./src/Components/Library/DocsList/DocsList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,oBAAoB;IACpB,cAAc;AAClB;AACA;IACI,oBAAoB;IACpB,cAAc;AAClB;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".folderListDiv{\n    display: flex;\n    margin-bottom: 10px;\n    padding: 10px;\n    cursor: pointer;\n}\n.folderListActiveDiv{\n    display: flex;\n    margin-bottom: 10px;\n    cursor: pointer;\n    padding: 10px;\n    background: #2D6FAF;\n    border-radius: 8px;\n}\n.folderListDiv h4{\n    margin: 4px 0 0 15px;\n    color: #235789;\n}\n.folderListActiveDiv h4{\n    margin: 4px 0 0 15px;\n    color: #ffffff;\n}\n.listOfFilderCard{\n    background-color: #E7F0F9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
