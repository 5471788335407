import React from 'react';
import { Document, Page, View, Text } from 'react-pdf';

const PdfViewer = ({ pdfBytes }) => {

    
//     // Convert Uint8Array to Blob
//     const blob = new Blob([pdfBytes], { type: 'application/pdf' });
//     // Create URL for Blob
//     const url = URL.createObjectURL(blob);

//     const base64String = btoa(String.fromCharCode.apply(null, pdfBytes));

//   // Create data URL
//   const dataUrl = `data:application/pdf;base64,${base64String}`;

//   console.log(base64String,'modifieddataUrl')

    return (
        // <PDFViewer width="100%" height="600">
            <Document file={pdfBytes}>
                <Page pageNumber={1} />
            </Document>
        // </PDFViewer>
    );
};

export default PdfViewer;
