import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Modal,
  message,
  Checkbox,
  DatePicker,
  Upload,
} from "antd";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Review from "../../Document/Review/Review";
import { Stage, Layer, Line } from "react-konva";
// import { Document, Page } from 'react-pdf';
// import { PDFViewer, Document, Page} from '@react-pdf/renderer';
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import {
  AppUrl,
  REACT_APP_ACESS_KEY_ID,
  REACT_APP_SECRET_ACESS_KEY,
  REACT_APP_REGION,
} from "../../../Constants/Constants";
import { faLariSign } from "@fortawesome/free-solid-svg-icons";

const AWS = require("aws-sdk");
const { PDFDocument, rgb } = require("pdf-lib");

const { TextArea } = Input;

const AddFile = () => {
  const AUTH_TOKEN = useSelector((state) => state.authToken);
  const USER_DATA = useSelector((state) => state.user);
  const navigate = useNavigate();

  console.log(USER_DATA.user_name, "USER_DATA");

  let url = window.location.href;
  let splittedUrl = url.split("/")[4];
  console.log(splittedUrl, "url");

  const [folderOptions, setFolderOptions] = useState([]);
 const foldername =''



  useEffect(() => {
    getFolderNames();
  }, []);

  const getFolderNames = () => {
    axios
      .get(
        `${AppUrl}list_of_folder_based_on_company?company_id=${USER_DATA.company_id}`,
        {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data, "optionAPI");
        if (res.data.status === 200) {
          let arr = [];
          for (let i of res.data.folders) {
            let obj = {
              value: i.folder_id,
              label: i.folder_name,
            };
            arr.push(obj);
          }
          setFolderOptions(arr);
        } else {
          setFolderOptions(null);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [PageNumber, setPageNumber] = useState(0);
  const [Checked, setChecked] = useState(false);

  console.log(USER_DATA, "userdata");

  const [folderName, setFolderName] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [comments, setComments] = useState("");
  const [expiryDate, setExpiryDate] = useState(""); // Date is set when checkbox is checked
  const [file, setFile] = useState([]);
  const [percent, setPercent] = useState("0");
  const [btnLoading, setBtnLoading] = useState(false);

  // Handle folder name change
  const handleFolderNameChange = (value) => {
    // setFolderName(value);
    message.error("Value can't be chaged");
  };

  // Handle file name input change
  // const handleFileNameChange = (e) => {
  //     setFileName(e.target.value);
  // };

  // Handle comments input change
  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };

  // Handle expiry date change from DatePicker
  const handleExpiryDateChange = (date, dateString) => {
    setExpiryDate(dateString); // Set date string from the DatePicker
    console.log(dateString, "selectedValue");
  };

  // const s3 = new AWS.S3({
  //     accessKeyId: 'AKIAVMTVZLEFBXHWQL72',
  //     secretAccessKey: '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',
  //     region: 'us-east-1'
  // });

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const [DOCURL, setDOCURL] = useState(
    "https://preciumweb-dev.s3.amazonaws.com/dev/042e34e3-2d19-47ce-bd69-9532fe42d798/59296da0-9aa0-44c4-9ac5-34adcfc970b8/JA-020+Salesforce+Case+Comments+and+Chatter+Functionalities+for+Novartis+Patient+Support+Center.pdf"
  );
  const showModal = (e, val) => {
    const updateFileName = DOCURL.replace(
      /ded2w7tw5rnvn.cloudfront.net/,
      "preciumweb-dev.s3.amazonaws.com"
    );

    setDOCURL(updateFileName);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen(false);
  };

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  AWS.config.update({
    region: "us-east-1",
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
      s3ForcePathStyle: true,
    }),
  });

  const s3 = new AWS.S3();

  const uploadFileToS3 = (filess) => {
    console.log(filess, "filessss");
    setBtnLoading(true);
    if (filess.length === 0) {
      setBtnLoading(false);
      message.error("Please Upload Document!");
      return;
    }

    if (folderName === null) {
      setBtnLoading(false);
      message.error("Please select folder to continue!");
      return;
    }

    const uploadPromises = filess.map((file) => {
      // const fileName = `https://trainingnexus.s3.amazonaws.com/DLP/${courseId}/${file.name}`
      const params = {
        Bucket: "trainingnexus",
        Key: `Novartis/${foldername}/${file.name}`,
        Body: file,
      };

      return new Promise((resolve, reject) => {
        s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
          .on("httpUploadProgress", (progress) => {
            const uploadedPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            setPercent(uploadedPercentage);
          })
          .send((err, data) => {
            if (err) {
              reject(err);
              setBtnLoading(false);
            } else {
              resolve(data);
              console.log(data, "s3ResultUrl");
              // setFilePath(data.Location);

              let body = {
                company_id: USER_DATA.company_id,
                user_id: USER_DATA.user_id,
                folder_id: splittedUrl,
                file_path: data.Location,
                file_name: fileName,
                expiry_date_status: Checked,
                document_expiry_date: expiryDate,
                document_decription: "",
                // file_status : "Pending for review & approval"
              };

              axios
                .post(`${AppUrl}upload_file`, body, {
                  headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: "application/json",
                  },
                })
                .then((res) => {
                  console.log(res.data, "uploadFilesResponse");
                  if (res.data.status === 200) {
                    message.success(res.data.response);
                    setTimeout(() => {
                      navigate(`/document/files/${splittedUrl}`);
                    }, 2000);
                  } else {
                    message.error(`Can't upload file`);
                  }
                })
                .catch((err) => {
                  message.error(err.message);
                });
            }
          });
      });
    });

    Promise.all(uploadPromises)
      .then((res) => {
        console.log(res, "reached1");
        setBtnLoading(false);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err, "upload err");
        message.error(err.message);
        setBtnLoading(false);
      });
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        company_id: USER_DATA.company_id,
        user_id: USER_DATA.user_id, // Replace with dynamic user_id if needed
        folder_name: folderName,
        file_name: fileName,
        approver_id: USER_DATA.user_id, // Replace with dynamic approver_id if needed
        document_decription: comments,
        expiry_date: expiryDate,
      };

      const response = await axios.post(
        "http://54.221.26.17:9000/approve_review",
        payload,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        message.success("File submitted successfully!");
        navigate(`/document/files/${splittedUrl}`); // Navigate to the appropriate URL on success
      }
    } catch (error) {
      console.error("Error submitting file:", error);
      message.error("There was an error submitting the file.");
    }
  };
console.log(folderOptions,"optopns")
useEffect(() => {
  if (folderOptions.length > 0) {
    const folder = folderOptions.find(option => option.value === splittedUrl);
    
    if (folder) {
      console.log(folder.label, "folderName");
      setFolderName(folder.label)
    } else {
      console.log("Folder not found");
    }
  }
}, [splittedUrl, folderOptions]);
   

  return (
    <div className="reviewClass">
      <Modal
        className="Modal1"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel1}
        footer={null}
      >
        <div>
          <Document
            file={DOCURL}
            onLoadSuccess={onDocumentLoadSuccess}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          >
            {PageNumber === 0 ? (
              Array.from({ length: numPages }, (_, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))
            ) : (
              <Page pageNumber={PageNumber} />
            )}
          </Document>
        </div>
      </Modal>
      <Row>
        <Col span={10}>
          <h3 className="sideMainHeading">Add New Document</h3>
        </Col>
      </Row>
      <Row className="addUserForm">
        <Col span={3}>
          <h3 style={{ marginBottom: 65 }} className="reviewFormtext">
            Folder Name
          </h3>
          <h3 style={{ marginBottom: 65 }} className="reviewFormtext">
            File Name
          </h3>
          <h3 style={{ marginBottom: 75 }} className="reviewFormtext">
            Owner Name
          </h3>
          {/* <h3 style={{ marginBottom: 115 }} className='reviewFormtext' >Comments</h3> */}
          <h3 style={{ marginBottom: 65 }} className="reviewFormtext">
            Upload Document
          </h3>
          {Checked === true ? (
            <h3 style={{ marginTop: 200 }} className="reviewFormtext">
              Expiration Date
            </h3>
          ) : (
            <></>
          )}
        </Col>
        <Col span={13}>
          <Select
            className="reviewSelect"
            options={folderOptions}
            onChange={handleFolderNameChange}
            value={folderName}
            defaultValue={folderName}
            // disabled
            placeholder="Select your Folder Name"
          />
          <Input
            className="reviewInput"
            defaultValue={fileName}
            value={fileName}
            placeholder="File Name"
          />
          <Input
            className="reviewInputUserId"
            onChange={() => message.error("Value can't be changed")}
            placeholder="Enter Owner Name"
            value={USER_DATA.user_name}
            defaultValue={USER_DATA.user_name}
          />
          {/* <TextArea rows={4} className='reviewTextArea' onChange={handleCommentsChange} value={comments} placeholder='Enter your comments' /> */}

          <Row>
            <Col span={12}>
              <Upload
                fileList={file}
                onRemove={() => setFile([])}
                maxCount={1}
                onChange={(info) => {
                  const fileObj = info.fileList.map(
                    (item) => item.originFileObj
                  );
                  console.log(info, "infoFileNAme");
                  setFile(fileObj);
                  setFileName(info.file.name);
                  // uploadFileToS3(fileObj);
                }}
                customRequest={({ onSuccess }) => onSuccess("ok")}
              >
                <Button className="UploadBTN">
                  <img src="\Icons\uploadImg.svg" alt="Logo" />
                  Upload File
                </Button>
              </Upload>
              <p>Maximum 5MB only PDF</p>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col>
              <Checkbox style={{ marginTop: 20 }} onChange={onChange}>
                Does the document contain an expiration date?
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {Checked === true ? (
                <DatePicker
                  className="reviewInputDate"
                  format="YYYY-MM-DD"
                  onChange={handleExpiryDateChange}
                />
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Col>
        {file.length > 0 ? (
          <Col offset={1} span={7}>
            <div onClick={showModal} className="MaterialSide">
              <img
                style={{ display: "flex", justifyContent: "center" }}
                src="/Icons/material2.svg"
                alt="Logo"
              />
              <h3 style={{ marginBottom: 65 }} className="reviewFormtext">
                {fileName}
              </h3>
            </div>
          </Col>
        ) : (
          <Col offset={1} span={7}>
            <div className="MaterialSide">
              <img
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "200px",
                  width: "200px",
                }}
                src="\Icons\nofile.webp"
                alt="No files"
              />
              <h3 style={{ marginBottom: 65 }} className="reviewFormtext">
                No files found
              </h3>
            </div>
          </Col>
        )}
      </Row>
      <Row className="addCancelSaveBtnRow">
        <Col span={3}>
          <Button
            onClick={() => navigate(`/document/files/${splittedUrl}`)}
            className="userCancelBtn"
          >
            Cancel
          </Button>
        </Col>
        <Col offset={1} span={3}>
          <Button
            className="addNewUserBtn"
            loading={btnLoading}
            onClick={() => uploadFileToS3(file)}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AddFile;
