import { Button, Col, Input, Row, Select, DatePicker, notification } from 'antd'
import React, { useState, useEffect } from 'react'
import './AddUser.css'
import { Switch } from 'antd'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios'
import { AppUrl } from '../../../Constants/Constants';



const AddUser = () => {
    const navigate = useNavigate();

    const [status, setStatus] = useState('Active')
    const [FirstName, setFirstName] = useState('')
    const [Email, setEmail] = useState('')
    const [Designation, setDesignation] = useState('')
    const [jobRole, setJobRole] = useState('');
    const [Role, setRole] = useState('');
    const [LastName, setLastName] = useState('')
    const [Options, setOptions] = useState([])
    const [api, contextHolder] = notification.useNotification();
    const [error, setError] = useState('');
    const [data, setData] = useState([]);
    const [JobOptions, setJobOptions] = useState([])
    const [RoleId, setRoleId] = useState('')
    const [Loading, setLoading] = useState(false)

    const AUTH_TOKEN = useSelector(state => state.authToken);
    const USER_DATA = useSelector(state => state.user);

    console.log(USER_DATA, "token")

    useEffect(() => {
        axios
            .post(`${AppUrl}get_roles`,{
                company_id:USER_DATA?.company_id,
                user_id: USER_DATA?.user_id
            },{
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }})
            .then((res) => {
                console.log(res, "designationOptions")
                setOptions(res.data.response)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    console.log(FirstName, "name", Email, "mail")
    useEffect(() => {
        const savedData = localStorage.getItem('UserDatass');
        if (savedData) {
            setData(JSON.parse(savedData));
        }
    }, []);

    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'Please enter all the fields',
            description:
                'Enter all the input fields',
        });
    };
    const openNotificationWithSuccess = (type) => {
        api[type]({
            message: 'User Added Successfully',
            description:
                'Record Added!!',
        });
    };
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    

    const HandleSubmit = () => {
        if (FirstName == "" || LastName == "" || Email == "" || jobRole == "" || Role == "") {

            openNotificationWithIcon('error')
        }
       
         else if (!validateEmail(Email)) {
            setError('Invalid email format');}
        else {
            setLoading(true)
            let body ={
                company_id:USER_DATA?.company_id,
                first_name:FirstName,
                last_name:LastName,
                user_id:USER_DATA.user_id,
                email:Email,
                jobrole: jobRole,
                user_role: Role.label,
                role_id:JSON.stringify(RoleId)
                
            }
            setError('')
            
            axios
                .post(`${AppUrl}Add_users`,body, {
                    headers: {
                        Authorization: `Bearer ${AUTH_TOKEN}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    console.log(res)
                    openNotificationWithSuccess('success')
                    setLoading(false)
                    navigate('/usermanagement')
                })
                .catch((err) => {
                    console.log(err)
                    setLoading(false)
                })

        }
    }

    const handleCancel = () => {
        navigate('/usermanagement')
    }


    const switchChange = (e) => {
        console.log(`switch to ${e}`);
        if (e === false) {
            setStatus('Inactive');
        } else {
            setStatus('Active');
        }
    }

    useEffect(() => {
        axios
            .get(`${AppUrl}get_all_job_roles?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}`, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                // console.log(res, "designationOptions")
                let arr = []
                for (let i of res.data.job_roles) {
                    let obj = {
                        label: i.job_name,
                        value: i.job_id
                    }
                    arr.push(obj)

                }
                setJobOptions(arr)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    return (
        <div className='addUserClass' >
            {contextHolder}
            <Row>
                <Col span={10}>
                    <h3 className='sideMainHeading'>Add New User</h3>
                </Col>
                <Col offset={10} >
                    <div className='statusWholeDiv'>
                        <h3 className='addUserStatustext'>Status</h3>
                        <Switch className='addUserSwitchClass' defaultChecked onChange={switchChange} />
                        <p style={{ color: status === 'Inactive' ? '#ED1C24' : '#16ab00' }}>{status}</p>
                    </div>
                </Col>
            </Row>
            <Row className='addUserForm'>
                {/* <Col span={3}>
                    <h3 className='addUserFormtext'>User Id</h3>
                </Col> */}
                {/* <Col span={13}>
                    <Input className='addUserInputUserId' placeholder='' defaultValue='NV09298333' />
                </Col> */}
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3
                        className='addUserFormtext'>First Name <span style={{ color: 'red' }}>*</span></h3>
                </Col>
                <Col span={13}>
                    
                    <Input
                        onChange={(e) => setFirstName(e.target.value)}
                        className='addUserInput' placeholder='Enter your First Name' />
                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3
                        className='addUserFormtext'>Last Name<span style={{ color: 'red' }}>*</span></h3>
                </Col>
                <Col span={13}>
                    <Input
                        onChange={(e) => setLastName(e.target.value)}
                        className='addUserInput' placeholder='Enter your Last Name' />
                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3
                        className='addUserFormtext'>Email Id<span style={{ color: 'red' }}>*</span></h3>
                </Col>
                <Col span={13}>
                    <Input

                        onChange={(e) => setEmail(e.target.value)}
                        className='addUserInput' placeholder='Enter your Email Id' />
 {error && <p className='error'>{error}</p>}
                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3

                        className='addUserFormtext'>Job Role<span style={{ color: 'red' }}>*</span></h3>
                </Col>
                <Col span={13}>
                    <Select
                        onChange={(e) => setJobRole(e)}
                        className='addUserSelect' options={JobOptions} placeholder='Select Job Role' />
                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3

                        className='addUserFormtext'>Role<span style={{ color: 'red' }}>*</span></h3>
                </Col>
                <Col span={13}>
                    <Select
                        onChange={(e,val) => {
                        setRoleId(e)
                            setRole(val)}
                        }
                        className='addUserSelect' options={Options} placeholder='Select Role' />
                </Col>
            </Row>
            {/* <Row className='addUserForm'>
                <Col span={3}>
                    <h3 className='addUserFormtext'>User Role</h3>
                </Col>
                <Col span={13}>
                <Select
                    // onChange={(e) => setDesignation(e)}
                     className='addUserSelect' options={designationOptions} placeholder='Select User Role' />
                </Col>
            </Row> */}
            {/* <Row className='addUserForm'>
                <Col span={3}>
                    <h3 
                      
                    className='addUserFormtext'>Job Location</h3>
                </Col>
                <Col span={13}>
                    <Select
                    onChange={(e) => setDesignation(e)}
                     className='addUserSelect' options={designationOptions} placeholder='Select Job Location' />
                </Col>
            </Row> */}
            {/* <Row className='addUserForm'>
                <Col span={3}>
                    <h3

                        className='addUserFormtext'>User Added On</h3>
                </Col>
                <Col span={13}>
                    <DatePicker
                        // onChange={onChange} 
                        className='addUserDatePicker'
                        placeholder='Select user added' />
                </Col>
            </Row> */}
            <Row className='addCancelSaveBtnRow'>
                <Col span={3}>
                    <Button className='userCancelBtn' onClick={handleCancel}>Cancel</Button>
                </Col>
                <Col offset={1} span={3}>
                    <Button onClick={HandleSubmit} loading={Loading} className='addNewUserBtn'>Save</Button>
                </Col>
            </Row>
        </div>
    )
}

export default AddUser