import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Components/Header/Header';
import UserList from './Components/UserManagement/UserList/UserList';
import Login from './Components/Login/Login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AddUser from './Components/UserManagement/AddUser/AddUser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditUser from './Components/UserManagement/EditUser/EditUser';
import Document from './Components/Document/Document';
import RoleManagement from './Components/UserManagement/RoleManagement/RoleManagement';
import Folders from './Components/Document/Folders/Folders';
import Review from './Components/Document/Review/Review';
import DigitalSignatureForm from './Components/Document/Review/DigitalSignatureForm';
import FileReview from './Components/FileReview/FileReview';
import PdfViewer from './Components/FileReview/PDFViewer';
import Library from './Components/Library/Library/Library';
import DocsList from './Components/Library/DocsList/DocsList';
import Archives from './Components/Library/Archives/Archives';
import Chatbots from './Components/AiAssistants/Chatbots';
import CreateNewChatbot from './Components/AiAssistants/CreateNewChatbot';
import userJson from './JSONData/users.json';
import AddFile from './Components/Library/AddFile/AddFile';
import ChatSettings from './Components/AiAssistants/ChatSettings/ChatSettings';
import CompanyList from './Components/SuperAdmin/AddCompany/CompanyList';
import AddCompany from './Components/SuperAdmin/AddCompany/AddCompany';
import FileReviews from './Components/Library/FileReview/FileReviews';
import Auth from './Components/Login/auth';
import UserSetup from './Components/UserManagement/UserSetup/UserSetup';
import ViewUser from './Components/UserManagement/ViewUser/ViewUser';
import LottieAnimation from './Components/SessionTimeout/SessionTimeout';
import SessionTimeout from './Components/SessionTimeout/SessionTimeout';
import Publish from './Components/AiAssistants/Publish'
import Approve from './Components/Library/Approve/Approve';
import {  message,  } from 'antd'

function App() {
  let url = window.location.href;
  let splittedUrl = url.split('/')[3];
  console.log(splittedUrl, "url");

  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem('Profile'));

  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state
  const [isAuthenticated, setIsAuthenticated] = useState(null); // For managing authentication state

  // Load user role from profile
  useEffect(() => {
    if (profile != null) {
      const userMail = profile.email;
      const selectedUser = userJson.users.find(i => i.email === userMail);
      const role = selectedUser?.role_id;
      setUserRole(role);
    }
  }, [url]);

  sessionStorage.setItem('userrole', userRole);

  useEffect(() => {
    sessionStorage.setItem('fileHide', false);
    sessionStorage.setItem('assign', false);
  }, []);

  // Check if user is authenticated
  useEffect(() => {
    const authStatus = JSON.parse(localStorage.getItem('IsAuthenticated'));
    setIsAuthenticated(authStatus);
    
    // Navigate to login if not authenticated
    if (splittedUrl !== 'login'){
    if (!authStatus) {
      setTimeout(() => {
        navigate('/login');
        message.error('Please Login to Continue');
      }, 500);
    }
  }

    setLoading(false); // Set loading to false after checking
  }, [navigate]);

  // If loading, show a spinner or loading message
  if (loading) {
    return <div>Loading...</div>;  // You can replace this with a spinner or custom loading animation
  }

  return (
    <GoogleOAuthProvider clientId="389145997013-40d6aq20qie4f96dn3f244h3q1e9gldf.apps.googleusercontent.com">
      {
        splittedUrl !== 'login' && <Header />
      }
      <div className={splittedUrl === 'login' ? "" : "main-container"}>
        <Routes>
          <Route path='/usermanagement' element={<UserList />} />
          <Route path='/login' element={<Login />} />
          <Route path='/usermanagement/adduser' element={<AddUser />} />
          <Route path='/usermanagement/edituser/:id' element={<EditUser />} />
          <Route path='/usermanagement/viewuser/:id' element={<ViewUser />} />
          <Route path='/rolemanagement' element={<RoleManagement />} />
          <Route path='/document/files/:id' element={<Document />} />
          <Route path='/document' element={<Folders />} />
          <Route path='/document/files/:id/review/:fileId' element={<FileReview />} />
          <Route path='/folder/:id/review/:fileId' element={<FileReviews />} />
          <Route path='/pdf-viewer' element={<PdfViewer />} />
          <Route path='/folder' element={<Library />} />
          <Route path='/folder/:id' element={<DocsList />} />
          <Route path='/chatbots/:id' element={<ChatSettings />} />
          <Route path='/archives' element={<Archives />} />
          <Route path='/document/:filename/addfile' element={<AddFile />} />
          <Route path='/document/files/approve' element={<Approve />} />
          <Route path='/chatbots' element={<Chatbots />} />
          <Route path='/usersetup' element={<UserSetup />} />
          <Route path='/createchatbots/:botId' element={<CreateNewChatbot />} />
          <Route path='/company' element={<CompanyList />} />
          <Route path='/company/addcompany' element={<AddCompany />} />
          <Route path='/sessiontimeout' element={<LottieAnimation />} />
          <Route path='/publish' element={<Publish />} />
        </Routes>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
