import './Header.css'
import { Col, Row, Avatar, Popover, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons';
import { googleLogout } from '@react-oauth/google';
import {
  DownOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppUrl } from '../../Constants/Constants';
import axios from 'axios';
import { applicationAdminData, superAdminData } from '../../AccessData/AccessData';
import { USER_ACCESS, userAccess } from '../../Redux/Actions';

const Header = () => {


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector(state => state.user || "");

  const [pageViewAccess, setPageAccess] = useState([]);
  const [ pageUpdateAccess, setPageUpdateAccess ] = useState([]);

  const AUTH_TOKEN = useSelector(state => state.authToken);


  console.log(userDetails, 'userDetails', pageViewAccess)

  const checkElementPresence = (elements) => {

    if (!Array.isArray(elements)) {
      elements = [elements]; // Convert single element to array
    }

    console.log(elements.some(element => pageViewAccess.includes(element)), 'checkElementPresence', pageViewAccess)
    return elements.some(element => pageViewAccess.includes(element));
  };

  const checkElementUpdatePresence = (elements) => {

    if (!Array.isArray(elements)) {
      elements = [elements]; // Convert single element to array
    }
    console.log(elements.some(element => pageUpdateAccess.includes(element)), 'checkElementUpdatePresence', pageUpdateAccess)
    return elements.some(element => pageUpdateAccess.includes(element));
  };

  useEffect(() => {

    if (userDetails.role !== 'super admin' && userDetails.role !== 'application admin') {

      let body = {
        company_id: userDetails.company_id,
        role_id: JSON.stringify(userDetails.role_id)
      }

      axios.post(`${AppUrl}get_role_permission`, body, {
        headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          Accept: 'application/json'
        }
      })

        .then((res) => {
          console.log(res.data.status, 'rolesWithAccess')
          // if (res.data != []) {
          
            if (res.data.status === 200) {
              const permissionPages = res.data.response.permissions;
              console.log(permissionPages, 'permissionPages')
              const rolesWithViewAccess = permissionPages.filter(item => item.view === true)
                .map(item => item.id);
              const rolesWithUpdateAccess = permissionPages.filter(item => item.update === true)
                .map(item => item.id);
              
              dispatch(userAccess(rolesWithUpdateAccess));

              setPageAccess(rolesWithViewAccess);
              setPageUpdateAccess(rolesWithUpdateAccess);
            } else {
              if (splittedUrl !== 'login'){
              message.error('Please login again to continue!');
              setTimeout(() => {
                navigate('/sessiontimeout')
              }, 2000)
            }
            }
          console.log(JSON.parse(res.data.permissions), 'getRolePermission');
        })
        .catch((err) => console.log(err.message))



    } else if( userDetails.role === 'application admin' ){
      const rolesWithAccess = applicationAdminData.filter(item => item.view === true).map(item => item.id);
      const rolesWithUpdateAccess = applicationAdminData.filter(item => item.update === true).map(item => item.id);
      setPageUpdateAccess(rolesWithUpdateAccess);
      setPageAccess(rolesWithAccess);
      console.log(rolesWithAccess,"pageViewAccess",userAccess(rolesWithAccess))
      dispatch(userAccess(rolesWithUpdateAccess));
    } 
    else {
      console.log(superAdminData , 'superAdminData')
      const rolesWithAccess = superAdminData.filter(item => item.view === true).map(item => item.id);
      const rolesWithUpdateAccess = superAdminData.filter(item => item.update === true).map(item => item.id);
      setPageUpdateAccess(rolesWithUpdateAccess);
      setPageAccess(rolesWithAccess);
      // console.log(rolesWithAccess,"pageViewAccess",userAccess(rolesWithAccess))
      dispatch(userAccess(rolesWithUpdateAccess));
    }

  }, [])




  const url = window.location.href;

  const splittedUrl = url.split('/');

  const currentLocation = splittedUrl[3];

  console.log(currentLocation, 'currentLocation')
  const Profile = localStorage.getItem("Profile");
  const ParsedProfile = JSON.parse(Profile);

  const userRole = sessionStorage.getItem('userrole')

  const handleLogout = () => {
    googleLogout();
    localStorage.setItem("IsAuthenticated", "false");
    sessionStorage.setItem("userrole", "");
    navigate(`login`)
    // localStorage.clear();
    // sessionStorage.clear();

  }
  const handleRoute = (route) => {
    navigate(`/${route}`)
  }
  const ProfileContent = (
    <div>

      <h5 className='Contents'
      //  onClick={handleLogout}
      >
        Profile
      </h5>
      <h5 className='Contents' onClick={handleLogout}>
        Logout
      </h5>
    </div>
  )
  const ProfileTitle = (
    <>
      <Row>
        <Col span={4}>
          <Avatar className='headingAvatar' size="large" icon={<UserOutlined />} />
        </Col>
        <Col offset={2} span={16}>
          <p className='Username'>{Profile ? ParsedProfile.name || ParsedProfile.username : "username"} </p>
          <p className='Email'> {Profile ? ParsedProfile.email : "username@valuehealthsol.com"}</p>
        </Col>
      </Row>
    </>
  )
  const usermanagementContent = (
    <>
      {
        checkElementPresence(4) || checkElementUpdatePresence(4) ?
          <h3 onClick={() => handleRoute('usermanagement')} className={currentLocation === 'usermanagement' ? 'active-Headings' : 'nonActive-subHeadings'}>User List</h3>
          :
          <></>

      }
      {
        checkElementPresence(5) || checkElementUpdatePresence(5) ?
          <h3 onClick={() => handleRoute('rolemanagement')} className={currentLocation === 'rolemanagement' ? 'active-Headings' : 'nonActive-subHeadings'}>Role Management</h3>
          :
          <></>
      }
      {
        checkElementPresence(6) || checkElementUpdatePresence(6) ?
          <h3 onClick={() => handleRoute('usersetup')} className={currentLocation === 'usersetup' ? 'active-Headings' : 'nonActive-subHeadings'}>User Setup</h3>
          :
          <></>
      }
    </>
  )
  const BotsContent = (
    <>
      {
        checkElementPresence(12) || checkElementUpdatePresence(12) ?
          <h3 onClick={() => handleRoute('chatbots')} className={currentLocation === 'chatbots' ? 'active-Headings' : 'nonActive-subHeadings'}>Smart Buddy</h3>
          :
          <></>
      }
      {/* {
        checkElementPresence(13) || checkElementUpdatePresence(13) ?
          <h3 onClick={() => handleRoute('createchatbots')} className={currentLocation === 'createchatbots' ? 'active-Headings' : 'nonActive-subHeadings'}>Create New Smart Buddy</h3>
          :
          <></>
      } */}
      {
        checkElementPresence(14) || checkElementUpdatePresence(14) ?
          <h3 onClick={() => handleRoute('publish')} className={currentLocation === 'publish' ? 'active-Headings' : 'nonActive-subHeadings'}>Ready To Publish</h3>
          :
          <></>
      }
    </>
  )
  const librariesContent = (
    <>
      {
        checkElementPresence(9) || checkElementUpdatePresence(9) ?
          <h3 onClick={() => handleRoute('folder')} className={currentLocation === 'folder' ? 'active-Headings' : 'nonActive-subHeadings'}>Folder</h3>
          :
          <></>
      }
      {
        checkElementPresence(10) || checkElementUpdatePresence(10) ?
          <h3 onClick={() => handleRoute('archives')} className={currentLocation === 'archives' ? 'active-Headings' : 'nonActive-subHeadings'}>Archives</h3>
          :
          <></>
      }
    </>
  )


  return (
    <div className='mainHeader'>
      <Row>
        <Col span={2}>
          <img style={{ marginTop: 10, marginLeft: 40 }} src='/Logo/novartis-logo.svg' alt='Logo' width={46} height={26} />
        </Col>
        <Col span={13} offset={2} >
          <div className='headerTitleDiv' >
            {
              checkElementPresence(1)  || checkElementUpdatePresence(1) ?
                <div onClick={() => handleRoute('')}>
                  <h3 className={currentLocation === '' ? 'active-Headings' : 'nonActive-Headings'}>Dashboard</h3>
                </div>
                :
                <></>
            }
            {
              userDetails.role === "super admin" ?
                <div onClick={() => handleRoute('company')}>
                  <h3 className={currentLocation === 'company' ? 'active-Headings' : 'nonActive-Headings'}>Company</h3>
                </div>
                :
                <></>
            }
            {
              checkElementPresence(4) || checkElementPresence(5) || checkElementPresence(6)  || checkElementUpdatePresence(4) || checkElementUpdatePresence(5) || checkElementUpdatePresence(6) ?
                <div span={2} >
                  <Popover content={usermanagementContent} trigger="hover"  >
                    <h3 className={currentLocation === 'usermanagement' || currentLocation === 'rolemanagement' || currentLocation === 'usersetup' ? 'active-Headings' : 'nonActive-Headings'}>User <DownOutlined className='DownIcon' /></h3>

                  </Popover>
                </div>
                :
                <></>
            }
            {
              checkElementPresence(7) || checkElementUpdatePresence(7) ?
                <div span={2} onClick={() => handleRoute('document')}>
                  <h3 className={currentLocation === 'document' ? 'active-Headings' : 'nonActive-Headings'}>Workflow </h3>
                </div>
                :
                <></>
            }
            {
              checkElementPresence(9) || checkElementPresence(10) || checkElementUpdatePresence(9) || checkElementUpdatePresence(10) ?
                <div span={2}>
                  <Popover content={librariesContent} trigger="hover"  >
                    <h3 className={currentLocation === 'archives' || currentLocation === 'folder' ? 'active-Headings' : 'nonActive-Headings'}>Libraries <DownOutlined className='DownIcon' /></h3>
                  </Popover>
                </div>
                :
                <></>
            }
            {
              checkElementPresence(12) || checkElementPresence(13) || checkElementPresence(14) || checkElementUpdatePresence(12) || checkElementUpdatePresence(13) || checkElementUpdatePresence(14) ?
                <div span={2} >
                  <Popover content={BotsContent} trigger="hover"  >

                    <h3 className={currentLocation === 'chatbots' || currentLocation === 'createchatbots' ? 'active-Headings' : 'nonActive-Headings'}>AI Assistants <DownOutlined className='DownIcon' /></h3>
                  </Popover>
                </div>
                :
                <></>

            }

          </div>
        </Col>
        <Col span={2} offset={2}>
          <Popover content={ProfileContent} trigger="click" title={ProfileTitle} >
            <Avatar className='Avatar' size="large" icon={<UserOutlined />} />
          </Popover>
        </Col>
        <Col span={3}>
          <h4 className='Username'>{userDetails.user_name} </h4>
          <p className='userRoleText'>{userDetails.role}</p>
        </Col>
      </Row>
    </div>
  )
}

export default Header