import { Button, Col, Input, Row, Select, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { Switch } from 'antd'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { AppUrl } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';


const EditUser = () => {
    const navigate = useNavigate();

    const [status, setStatus] = useState('')
    const [data, setData] = useState([]);
    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    const [Email, setEmail] = useState('')
    const [Designation, setDesignation] = useState('')
    const [Options, setOptions] = useState([])
    const [Loading, setLoading] = useState(false);
    const [jobRoleId, setJobRoleId] = useState('');
    const [RoleId, setRoleId] = useState('');
    const [Role, setRole] = useState('')
    const [userId, setUserId] = useState('');
    const [jobRole, setjobRole] = useState('')
    const [JobOptions, setJobOptions] = useState([])
    let url = window.location.href;
    let splittedUrl = url.split('/')[5];


    const AUTH_TOKEN = useSelector(state => state.authToken);
    const USER_DATA = useSelector(state => state.user);


    const { id } = useParams();
    const userDetails = useSelector(state => state.user);
    console.log(status, "status")

    const addObject = () => {
        setLoading(true)
        axios.put(`${AppUrl}edit_user_based_on_company?company_id=${userDetails.company_id}&user_id=${id}&first_name=${FirstName}&last_name=${LastName}&email_id=${Email}&jobrole_id=${jobRoleId}&status=${status}&role=${RoleId}`, {}, {
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                console.log(res.data, 'responseOfEdit');
                if (res.data.status === 200) {
                    message.success('User details updated successfully!');
                    setLoading(false)
                    setTimeout(() => {
                        navigate('/usermanagement')
                    }, 1000)
                }
            })
            .catch(err => {
                setLoading(false)
                console.log(err.message)
            }
            )

    };


    useEffect(() => {
        axios.get(`${AppUrl}user_details_view?company_id=${userDetails.company_id}&user_id=${id}`, {
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                console.log(res.data.response, 'edituserDetails')

                const response = res.data.response;

                if (res.data.status === 200) {
                    // setFirstName(response[0].first_name);
                    // setLastName(response[0].last_name);
                    // setEmail(response[0].email);
                    // setRoleId(response[0].role_id);
                    // setUserId(response[0].user_id);
                    // setRole(response[0].role)
                    // setStatus(response[0].user_status)
                    // setJobRoleId(response[0].jobrole_id)
                }

            })
            .catch((err) => console.log(err.message))
    }, [])



    useEffect(() => {
        setLoading(true)
        axios
            .get(`${AppUrl}user_list_based_on_company?company_id=${USER_DATA?.company_id}`, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                // setData(res.data)
                console.log(res, "response")
                if (res.status === 201) {
                    navigate('/sessiontimeout')
                } else {
                    for (let i of res.data.response) {
                        if (i.user_id === splittedUrl) {

                            setData(i);
                            setFirstName(i.first_name);
                            setLastName(i.last_name);
                            setEmail(i.email);
                            setRoleId(i.role_id);
                            setUserId(i.user_id);
                            setRole(i.role)
                            setStatus(i.status)
                            setJobRoleId(i.jobrole_id)

                        }
                    }

                    setLoading(false)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])
    console.log(data, "data")
    useEffect(() => {
        axios
            .post(`${AppUrl}get_roles`, {
                company_id: USER_DATA?.company_id,
                user_id: USER_DATA?.user_id
            }, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                // console.log(res, "designationOptions")
                setOptions(res.data.response)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    useEffect(() => {
        axios
            .get(`${AppUrl}get_all_job_roles?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}`, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                let arr = []
                for (let i of res.data.job_roles) {
                    let obj = {
                        label: i.job_name,
                        value: i.job_id
                    }
                    arr.push(obj)

                }
                setJobOptions(arr)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    console.log(status, "chan")

    return (
        <div className='addUserClass' >
            {data.length != 0 ?
                <>
                    <Row>
                        <Col span={10}>
                            <h3 className='sideMainHeading'>Edit User</h3>
                        </Col>
                        <Col offset={10} >
                            <div className='statusWholeDiv'>
                                <h3 className='addUserStatustext'>Status</h3>
                                <Switch
                                    value={status}
                                    className='addUserSwitchClass'
                                    defaultChecked={data.status}
                                    onChange={(value) => setStatus(value)} />
                                <p style={{ color: status === false ? '#ED1C24' : '#16ab00' }}>{status === false ? "Inactive" : "Active"}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>User Id</h3>
                        </Col>
                        <Col span={13}>
                            <Input
                                className='addUserInput' placeholder='' value={userId}
                                // defaultValue='NV09298333' 
                                disabled />
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>First Name</h3>
                        </Col>
                        <Col span={13}>
                            <Input
                                value={FirstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className='addUserInput' placeholder='Enter your First Name' />
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>Last Name</h3>
                        </Col>
                        <Col span={13}>
                            <Input
                                value={LastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className='addUserInput' placeholder='Enter your Last Name' />
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>Email Id</h3>
                        </Col>
                        <Col span={13}>
                            <Input
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                                className='addUserInput' placeholder='Enter your Email Id' />
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>Job Role</h3>
                        </Col>
                        <Col span={13}>
                            <Select
                                className='addUserSelect'
                                options={JobOptions}
                                value={JobOptions.find(option =>
                                    // console.log(option.value === jobRoleId,"opt",option.value, "jobrole", jobRoleId)
                                    option.value === jobRoleId


                                )}
                                placeholder='Select Job Role'
                                onChange={(val) => {
                                    console.log(val, 'dropDownValue')
                                    setJobRoleId(val)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='addUserForm'>
                        <Col span={3}>
                            <h3 className='addUserFormtext'>User Role</h3>
                        </Col>
                        <Col span={13}>
                            <Select
                                className='addUserSelect'
                                options={Options}
                                value={Options.find(option => option.value === RoleId)}
                                placeholder='Select User Role'
                                onChange={(val, item) => {
                                    console.log(val, 'dropDownValue', item)
                                    setRoleId(val)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='addCancelSaveBtnRow'>
                        <Col span={3}>
                            <Button className='userCancelBtn' onClick={() => navigate('/usermanagement')}>Cancel</Button>
                        </Col>
                        <Col offset={1} span={3}>
                            <Button loading={Loading} onClick={addObject} className='addNewUserBtn'>Save</Button>
                        </Col>
                    </Row>
                </>
                : <>
                </>}
        </div>
    )
}

export default EditUser