import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Space, Modal, Input, Switch, message, Checkbox } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import './RoleManagement.css'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { AppUrl } from '../../../Constants/Constants';
import { fullAccessData, permissionData } from '../../../AccessData/AccessData';
import { render } from '@testing-library/react';
import { checkElementUpdatePresence } from '../../../CommonFunctions/CommonFunctions';


const { Column } = Table;
const { TextArea } = Input;

const roleTable = [
  {
    "s.no": 1,
    "user_role": "User role 1"
  },
  {
    "s.no": 2,
    "user_role": "User role 2"
  },
]


const initialData = [
  {
    id : 1,
    pageName : 'Dashboard',
    description :'This is dashboard',
    view : false,
    update : false
  },
  // {
  //   id : 2,
  //   pageName : 'Company', 
  //   description : 'This is company',
  //   view : false,
  //   update : false
  // },
  {
    id : 3,
    pageName : 'User', 
    description : 'This is user',
    view : false,
    update : false
  },
  {
    id : 4,
    pageName : 'User List',
    description : 'This is user list',
    view : false,
    update : false
  },
  {
    id: 5,
    pageName: 'Role Management',
    description : 'This is Role Management',
    view : false,
    update : false
  },
  {
    id: 6,
    pageName: 'User Setup',
    description : 'This is User Setup',
    view : false,
    update : false
  },
  {
    id: 7,
    pageName: 'Workflow',
    description : 'This is Workflow',
    view : false,
    update : false
  },
  {
    id: 8,
    pageName: 'Libraries',
    description : 'This is Libraries',
    view : false,
    update : false
  },
  {
    id: 9,
    pageName: 'Folder',
    description : 'This is Folder',
    view : false,
    update : false
  },
  {
    id: 10,
    pageName: 'Archives',
    description : 'This is Archives',
    view : false,
    update : false
  },
  {
    id: 11,
    pageName: 'AI Assistants',
    description : 'This is AI Assistants',
    view : false,
    update : false
  },
  {
    id: 12,
    pageName: 'Smart Buddy',
    description : 'This is Smart Buddy',
    view : false,
    update : false
  },
  {
    id: 13,
    pageName: 'Create New Smart Buddy',
    description : 'This is Create New Smart Buddy',
    view : false,
    update : false
  },
  {
    id: 14,
    pageName: 'Ready to publish',
    description : 'This is Ready to publish',
    view : false,
    update : false
  },
  {
    id: 15,
    pageName: 'Add file',
    description : 'This is Add file',
    view : false,
    update : false
  },{
    id: 16,
    pageName: 'Create Bot',
    description : 'This will cerate a bot',
    view : false,
    update : false
  },
]

const RoleManagement = () => {

  

  const [addRoleModal, setAddRoleModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [switched, setSwitched] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [errMsg, setErrMsg] = useState(false);
  const [access, setAccess] = useState(fullAccessData);
  const [tableData, setTableData] = useState([]);
  const [modalRoleId, setModalRoleId] = useState('');
  const [ addRoleInitialData, setAddRoleInitialData ] = useState(initialData);
  const [ addRoleDescription, setAddRoleDescription ] = useState('');
  const [ updateRoleName, setUpdateRoleName ] = useState('');
  const [ udpateRoleDescription, setUpdateRoleDescription ] = useState('');
  const [ addRoleUpdateData, setAddRoleUpdateData ] = useState([]);
  


  const USER_DATA = useSelector(state => state.user);
  const AUTH_TOKEN = useSelector(state => state.authToken);
  const USER_ACCESS = useSelector(state => state.userAccess);


  const openAddRoleModal = () => {
    setAddRoleModal(true);
    setRoleName('');
                 // Reset the role name
    setAddRoleDescription('');
    setErrMsg(false);
    setAddRoleInitialData(initialData)
  }

  const saveRoleName = () => {
    let body = {
      created_by: USER_DATA.user_id,
      company_id: USER_DATA.company_id,
      user_role: roleName,
      permission : JSON.stringify(addRoleInitialData),
      description : addRoleDescription
    }
    console.log(body,"bodyprint")

    if (roleName != '') {
      axios.post(`${AppUrl}roles`, body, {
        headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          Accept: 'application/json'
        }
      })
        .then((res) => {
          console.log(res.data, 'saveRoleResponse');
          if (res.data.status === 200) {
            setAddRoleModal(false);
            setAddRoleInitialData(initialData);
            getAllUserRole();
            setAddRoleDescription("");
            message.success(res.data.response);
          } else {
            message.error(res.data.response);
            setAddRoleModal(false);
          }
        }) .catch(( err ) => {
          message.error(err.message);
        })
    } else {
      setErrMsg(true);
      // setAddRoleModal(false);
    }
  }

  const getAllUserRole = () => {
    let body = {
      company_id: USER_DATA.company_id,
      user_id : USER_DATA.user_id
    }

    axios.post(`${AppUrl}get_roles`, body, {
      headers: {
        Authorization: `Bearer ${AUTH_TOKEN}`,
        Accept: 'application/json'
      }
    })
      .then((res) => {
        console.log(res, 'responseDataRoles')
        let response = res.data.response;
        if (res.data != []) {
          let arr = [];
          for (let i = 0; i < response.length; i++) {
            let obj = {
              "s.no": i + 1,
              "user_role": response[i].label,
              "role_id": response[i].value
            }
            arr.push(obj);
          }
          setTableData(arr);
        } else{
          setTableData([]);
        }
      })
      .catch( err => {
        message.error(err.message);
        console.log(err, 'Error Message!')
      })
  }


  useEffect(() => {

    getAllUserRole();

  }, [])


  const handleCheckboxChange = (id, field, value) => {
    console.log(id, '-------->', field, '----->', value, 'checkBoxValues' );

    const newData = addRoleInitialData.map(item => {
      console.log(item.id, 'mapValueItem')
      if (item.id === id) {
        return { ...item, [field]: value };
      }
      return item;
    });  
    setAddRoleInitialData(newData);

    
  }
  const handleUpdateCheckboxChange = (id, field, value) => {
    console.log(id, '-------->', field, '----->', value, 'checkBoxValues' );

    const newData = addRoleUpdateData.map(item => {
      console.log(item.id, 'mapValueItem')
      if (item.id === id) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setAddRoleUpdateData(newData);

    
  }
  console.log(addRoleUpdateData, 'addRoleInitialData')

  const permissionColumn = [
    {
      title: 'Pages',
      dataIndex: 'pages',
      key: 'pages',
      render : ( _, text) => {
        return(
        <>
        <h4 className='tablePageName'>{text.pageName}</h4>
        <p className='tableDescription'>{text.description}</p>
        </>
      )
    }
    },
    {
      title: 'View',
      dataIndex: 'view',
      key: 'view',
      render : (_, record) => (
        <>
        <Checkbox checked={record.view} 
        onChange={(e) => handleCheckboxChange(record.id, 'view', e.target.checked)}
        />
        </>
      )
    },
    {
      title: 'Update',
      dataIndex: 'update',
      key: 'update',
      render : (_, record) => {
        console.log(record.update, 'updateView')
        return(
        <>
        <Checkbox checked={record.update} 
        onChange={(e) => handleCheckboxChange(record.id, 'update', e.target.checked)}
        />
        </>
      )}
    },
  ]

  const updatePermissionColumn = [
    {
      title: 'Pages',
      dataIndex: 'pages',
      key: 'pages',
      render : ( _, text) => {
        return(
        <>
        <h4 className='tablePageName'>{text.pageName}</h4>
        <p className='tableDescription'>{text.description}</p>
        </>
      )
    }
    },
    {
      title: 'View',
      dataIndex: 'view',
      key: 'view',
      render : (_, record) => (
        <>
        <Checkbox checked={record.view} 
        onChange={(e) => handleUpdateCheckboxChange(record.id, 'view', e.target.checked)}
        />
        </>
      )
    },
    {
      title: 'Update',
      dataIndex: 'update',
      key: 'update',
      render : (_, record) => {
        console.log(record.update, 'updateView')
        return(
        <>
        <Checkbox checked={record.update} 
        onChange={(e) => handleUpdateCheckboxChange(record.id, 'update', e.target.checked)}
        />
        </>
      )}
    },
  ]

  const cancelAddRoleModal = () => {
    setAddRoleModal(false);
    setErrMsg(false);
    setRoleName('');
    setAddRoleInitialData(initialData)
  }

  const cancelPermissionModal = () => {
    setModalRoleId('');
    setPermissionModal(false);
    setSwitched([])
    setAccess(fullAccessData)
  }

  const openPermissionModal = (e) => {
   
    setModalRoleId(e.role_id);
    setPermissionModal(true);

    let body = {
      company_id : USER_DATA.company_id,
      role_id : JSON.stringify(e.role_id)
    }

    axios.post(`${AppUrl}get_role_permission`, body, {
      headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          Accept: 'application/json'
      }
  })

  .then(( res ) => {

    console.log(res.data, 'responserolePermissions' );

    if ( res.data.status == 200 ){
      console.log(res.data);
      setUpdateRoleName(res.data.response.role);
      setUpdateRoleDescription(res.data.response.description);
      setAddRoleUpdateData(res.data.response.permissions);
    }

    // if( typeof res.data.permissions === 'object'  ){
    //   console.log(JSON.parse(res.data.permissions), 'responserolePermissions', typeof res.data)
    // } else {
    //   console.log('responserolePermissions is yet to be selected!')
    // }
  }) .catch( err => {
      message.error(err.message);
  })

  }

  const switchChange = (access, id) => {
    setSwitched(prevIds => {
      if (access) {
        // Check if ID already exists to avoid duplicates
        if (!prevIds.includes(id)) {
          return [...prevIds, id];
        }
      } else {
        // console.log(prevIds.filter(item => item !== id), 'prevIds')
        return prevIds.filter(item => item !== id);
      }
      return prevIds; // Return previous state if no change needed
    });
  }


  useEffect(() => {

    permissionData.forEach(item => {
      if (switched.includes(item.id)) {
        item.access = true;
      } else {
        item.access = false;
      }
    });

    setAccess(fullAccessData);
  }, [switched])


  console.log(access, 'permissionDataChanged');
console.table(addRoleUpdateData)
  const permissionSave = () => {

    if (updateRoleName !== "") {

      let body = {
        company_id : USER_DATA.company_id,
        role_id : JSON.stringify(modalRoleId),
        permission : JSON.stringify(addRoleUpdateData),
        role_name : updateRoleName,
        description : udpateRoleDescription
      }

      axios.post(`${AppUrl}role_permission`, body, {
        headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          Accept: 'application/json'
        }
      })
        .then((res) => {
          console.log(res.data, 'permissionSavedData')
          if (res.data.status == 200) {
            setModalRoleId('');
            setPermissionModal(false);
            setAddRoleUpdateData([]);
            getAllUserRole();
            message.success(res.data.response);
          } else{
            message.error('Please assign any pages to continue!');
            setPermissionModal(false);
          }
        }) 
        .catch(( err ) => {
          console.log(err.message);
          message.error(err.message);
          setPermissionModal(false);
        })
        
      } else {
      setPermissionModal(false);
      setAddRoleUpdateData([]);
    }
  }


  
  console.log(checkElementUpdatePresence(USER_ACCESS, 5),'not Applicable');



  

  console.log(switched, 'switchedState')

  return (
    <div>
      <Modal className='addRoleModal' title='Add User Role' open={addRoleModal} footer={false} onCancel={cancelAddRoleModal}>
        <h3 className='jobInputWord'>Role Name</h3>
        <Input placeholder='Enter Role Name' className='searchInput' value={roleName} onChange={(e) => setRoleName(e.target.value)} />
        {
          errMsg === true && roleName === '' ?
            <p className='fieldMissErr'>Please fill out the field</p>
            :
            <></>
        }
        <h3 className='jobInputWord'>Description</h3>
        <TextArea rows={4} placeholder='Description' 
        onChange={(e) => setAddRoleDescription(e.target.value)}
        value={addRoleDescription}
        className='searchTextInput' />
        <h3 className='jobInputWord'>Permission</h3>
        <Table columns={permissionColumn} dataSource={addRoleInitialData} pagination={false} className='pagePermissionTable' />
        <div className='addRoleModalBtns'>
          <Button className='addNewRoleCancelBtn' onClick={cancelAddRoleModal}>Cancel</Button>
          <Button className='addNewRoleSaveBtn' onClick={saveRoleName} >Save</Button>
        </div>
      </Modal>
      <Modal className='permissionModal' title={`${checkElementUpdatePresence(USER_ACCESS, 5) ? 'Set' : 'View'} Permission`} open={permissionModal} footer={false} onCancel={cancelPermissionModal}>
      <h3 className='jobInputWord'>Role Name</h3>
        <Input placeholder='Enter Role Name' className='searchInput' disabled={checkElementUpdatePresence(USER_ACCESS, 5) ? false : true} value={updateRoleName} onChange={(e) => setUpdateRoleName(e.target.value)} />
        {
          errMsg === true && updateRoleName === '' ?
            <p className='fieldMissErr'>Please fill out the field</p>
            :
            <></>
        }
        <h3 className='jobInputWord'>Description</h3>
        <TextArea rows={4} placeholder='Description' 
        value={udpateRoleDescription}
        disabled={checkElementUpdatePresence(USER_ACCESS, 5) ? false : true}
        onChange={(e) => setUpdateRoleDescription(e.target.value)}
        className='searchTextInput' />
        <h3 className='jobInputWord'>Permission</h3>
        <Table columns={updatePermissionColumn} dataSource={addRoleUpdateData} pagination={false} className='pagePermissionTable' />
        {
          checkElementUpdatePresence(USER_ACCESS, 5) ? 
          <div className='addRoleModalBtns'>
          <Button className='addNewRoleCancelBtn' onClick={cancelPermissionModal}>Cancel</Button>
          <Button className='addNewRoleSaveBtn' onClick={permissionSave} >Save</Button>
        </div>
          :
          <></> 
        }
        {/* <h3 className='jobInputWord' style={{ marginBottom: 20 }} >System Admin Functionalities</h3>
        {
          access.map((item) => {
            // console.log(item, 'itemAccess')
            return (
              <div key={item.id} className='permissionModalContents'>
                <Switch className='addUserSwitchClass' defaultChecked={item.access}
                  onChange={(access) => switchChange(access, item.id)}
                />
                <p className='permissionNames'>{item.role}</p>
              </div>
            )
          })
        }
        <div className='addRoleModalBtns'>
          <Button className='addNewRoleCancelBtn' onClick={cancelPermissionModal}>Cancel</Button>
          <Button className='addNewRoleSaveBtn' onClick={permissionSave} >Save</Button>
        </div> */}
      </Modal>
      <Row>
        <Col span={10}>
          <h3 className='sideMainHeading'>Role Management</h3>
        </Col>
      </Row>
      {
        checkElementUpdatePresence(USER_ACCESS, 5) ?
      <Row className='addUserRoleBtn'>
        <Col span={3}>
          <Button className='addNewUserBtn' onClick={openAddRoleModal} ><PlusOutlined className='plusIconClass' /> Add User Role</Button>
        </Col>
      </Row>
        :
        <></>
      }
      <Row>
        <Col span={24}>
          <Table
            dataSource={tableData}
            className='roleManagementTable' >
            <Column title="S no" dataIndex="s.no" key="s.no" align='center' />
            <Column title="User Role" dataIndex="user_role" key="user_role" align='center'
              render={(text) => (
                <Space size='middle'>
                  <h3 className='tableNormalText' style={{ textTransform: 'capitalize' }} >{text}</h3>
                </Space>
              )}
            />
            <Column title='Action' key="action"
              align='center'
              render={(_, record) => (
                <Space size="middle">
                  <Button className='viewEditBtn'
                    onClick={() => openPermissionModal(record)}
                  >{checkElementUpdatePresence(USER_ACCESS, 5) ? 'Set' : 'View'} Permission</Button>
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </div>
  )
}

export default RoleManagement