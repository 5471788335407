import { useState } from 'react'
import { Document, pdfjs, Page } from 'react-pdf'

const DocumentView = ({ props }) => {
    const [numPages, setNumPages] = useState(null);
    const [PageNumber, setPageNumber] = useState(0);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    console.log(props, 'propsValue')
    return (
        <div>
            <Document
                file={props}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {
                    PageNumber === 0 ? (
                        Array.from({ length: numPages }, (_, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))
                    )
                        :
                        <Page pageNumber={PageNumber} />


                }
            </Document>
        </div>
    )
}

export default DocumentView