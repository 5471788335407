import React, { useState, useEffect } from 'react'
import { Button, Col, Input, Row, Space, Table, Modal, Checkbox, Skeleton,notification, message } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { AppUrl } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { checkElementUpdatePresence } from '../../../CommonFunctions/CommonFunctions';
const { Column } = Table;




const UserSetup = () => {
    const navigate = useNavigate();
    const CheckboxGroup = Checkbox.Group;
    const [modalOpen, setModalOpen] = useState(false);
    const [FolderModal, setFolderModal] = useState(false)
    const [addRoleModal, setAddRoleModal] = useState(false);
    const [JobName, setJobName] = useState('')
    const [Data, setData] = useState([])
    const [Loading, setLoading] = useState(false)
    const [Switched, setSwitched] = useState('')
    const [FolderData, setFolderData] = useState([]);
    const [JobId, setJobId] = useState('')
    const [grantedFolders, setGrantedFolders] = useState([]);


    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [APILOADING, setAPILOADING] = useState(false)

    const AUTH_TOKEN = useSelector(state => state.authToken);
    const USER_DATA = useSelector(state => state.user);
    const USER_ACCESS = useSelector(state => state.userAccess);
    const handleAddRoles = () => {

    }
    // const handleEditNavigate = (e) => {
    //     // navigate(`/usermanagement/edituser/${e.key}`)
    //     console.log(e,"event")
    //     setJobId(e.job_id)
    //     setFolderModal(true)
    // }

    const handleEditNavigate = (e) => {
        if( checkElementUpdatePresence(USER_ACCESS, 6) ){
            setJobId(e.job_id);
    
        axios.get(`${AppUrl}view_folder_permission?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}&job_role_id=${e.job_id}`, {
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                Accept: 'application/json'
            }
        })
        .then((res) => {
            // Assuming the response contains folder_id and folder_name like the example you provided
            const grantedFolderIds = res.data?.response.map(folder => folder.folder_id) || [];
            
            // Set the granted folders and open modal
            setGrantedFolders(grantedFolderIds);  
            setCheckedList(grantedFolderIds);  
            setIndeterminate(grantedFolderIds.length > 0 && grantedFolderIds.length < FolderData.length);
            setCheckAll(grantedFolderIds.length === FolderData.length);
            
            // Ensure FolderData is loaded correctly
            if (FolderData.length > 0) {
                setFolderModal(true);
                console.log("Modal should now open");
            } else {
                console.error("FolderData is empty, cannot open modal");
            }
        })
        .catch((err) => {
            console.error("Error fetching folder permissions:", err);
        });
        } else{
            message.error(`You don't have the access to edit`);
        }
        
    };
    

    const onChange = (folderId) => {
        const currentIndex = checkedList.indexOf(folderId);
        const newCheckedList = [...checkedList];
    
        if (currentIndex === -1) {
            // Add the folder ID if it is not already in the list
            newCheckedList.push(folderId);
        } else {
            // Remove the folder ID if it is already in the list
            newCheckedList.splice(currentIndex, 1);
        }
    
        setCheckedList(newCheckedList);
    
        // Update indeterminate and checkAll state based on the new list
        setIndeterminate(newCheckedList.length > 0 && newCheckedList.length < FolderData.length);
        setCheckAll(newCheckedList.length === FolderData.length);
    };

    console.log(checkedList, "list")
    // const onCheckAllChange = (e) => {
    //     setCheckedList(e.target.checked ? FolderData.map(item => item.key) : []);
    //     setIndeterminate(false);
    //     setCheckAll(e.target.checked);
    // };

    const onCheckAllChange = (e) => {
        // Map over folder_id to select all folder IDs
        setCheckedList(e.target.checked ? FolderData.map(item => item.folder_id) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const isChecked = (key) => checkedList.indexOf(key) !== -1;


    const openAddRoleModal = () => {
        setAddRoleModal(true);
    }

    const cancelAddRoleModal = () => {
        setJobName("")
        setAddRoleModal(false)
    }

    const HandleSubmit = () => {

        if (!JobName.trim()) {
            notification.error({
                message: 'Error',
                description: 'Role name cannot be empty.',
            });
            return;
        }
    
        const isDuplicate = Data.some((role) => role.job_name.toLowerCase() === JobName.trim().toLowerCase());
    
        if (isDuplicate) {
            notification.error({
                message: 'Error',
                description: 'Role name already exists.',
            });
            return;
        }

        let body = {
            job_name: JobName,
            company_id: USER_DATA?.company_id,
            user_id: USER_DATA?.user_id
        }
        console.log(body,"bpdyprinted")
        axios
            .post(`${AppUrl}add_job_role`, body, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                console.log(res, "response")
                setJobName("")
                setAPILOADING(!APILOADING)
                setAddRoleModal(false)
            })
            .catch((err) => {

            })
    }

    useEffect(() => {
        axios
            .get(`${AppUrl}list_of_folder_based_on_company?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}`, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                console.log(res, "chan")
                if(res.data.status == 201) {
                    navigate('/sessiontimeout')
                }else {

                    setFolderData(Array.isArray(res.data.folders) ? res.data.folders : []);
                }
            })
            .catch((err) => {

            })
    }, [])
    useEffect(() => {
        setLoading(true)
        axios
            .get(`${AppUrl}get_all_job_roles?company_id=${USER_DATA?.company_id}&user_id=${USER_DATA?.user_id}`, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                setData(res.data.job_roles)
                setLoading(false)
                console.log(res, "jobRoles")
            })
            .catch((err) => {
                console.log(err)
            })
    }, [APILOADING])

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIconSuccess = (type) => {
      console.log("getsSuccess");
      api["success"]({
        message: "Success!!!",
        description: type,
      });
    };
    const FolderSubmit = () => {
        // Filter out folders that are already granted
        const updatedFolderIds = [...new Set([...grantedFolders, ...checkedList])];

    
        // Log to check for duplicate or incorrect folder IDs
        console.log("New Folder IDs to be sent:", updatedFolderIds);
    
        // Check if there are any folders to add
        if (updatedFolderIds.length === 0) {
            notification.info({
                message: 'No Changes',
                description: 'No new folders selected for permission.',
            });
            return; // No API call needed, as no new folders were selected
        }
    
        // Prepare request body
        let body = {
            user_id: USER_DATA?.user_id,
            company_id: USER_DATA?.company_id,
            folder_id: checkedList,  // Only send new folders
            job_role_id: JobId,
            assignee_id: USER_DATA?.user_id,
        };
    
        // Make the API request to update the folder permissions
        axios
            .post(`${AppUrl}folder_permission`, body, {
                headers: {
                    Authorization: `Bearer ${AUTH_TOKEN}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                console.log("API response:", res);
                if (res.data.status === 200) {
                    openNotificationWithIconSuccess('Folder permissions added successfully');
                    setFolderModal(false); // Close the modal after successful submission
                } else {
                    console.error("Error in response:", res.data);
                    notification.error({
                        message: 'Error',
                        description: 'Failed to update folder permissions.'
                    });
                }
            })
            .catch((err) => {
                console.error("Error saving folder permissions:", err);
                notification.error({
                    message: 'Error',
                    description: 'Failed to update folder permissions due to an error.'
                });
            });
    };
    
    return (
        <div className='UserSetupMain'>
            {contextHolder}

            <Modal className='addRoleModal' title='Add Job Role' open={addRoleModal} footer={false} onCancel={cancelAddRoleModal}>
                <h3 className='jobInputWord'>Role Name</h3>
                <Input placeholder='Enter Role Name'
                    value={JobName}
                    onChange={(e) => setJobName(e.target.value)}
                    className='searchInput' />
                <div className='addRoleModalBtns'>
                    <Button className='addNewRoleCancelBtn' onClick={cancelAddRoleModal}>Cancel</Button>
                    <Button className='addNewRoleSaveBtn' onClick={HandleSubmit}>Save</Button>
                </div>
            </Modal>


            <Modal title='Folder Acess' open={FolderModal} footer={false} onCancel={() => setFolderModal(false)}>
                {/* <div className='FolderDIv'> */}
                <Row>
                    <Col span={24}>
                        <Checkbox style={{ float: 'right' }}
                            indeterminate={indeterminate}
                            
                            onChange={onCheckAllChange}>Select All
                            </Checkbox>

                    </Col>
                </Row>
                <Row>
                    {FolderData.map((item) => (
                        <Col key={item.folder_id}>
                            <div className='FolderDIv'>
                                <img style={{ justifyContent: 'start' }} src='/Icons/Capa_1.svg' alt='Logo' width={60} height={40} />

                                <Checkbox
    style={{ marginTop: 20, marginLeft: 10 }}
    onChange={() => onChange(item.folder_id)}
    checked={isChecked(item.folder_id)}
>
    {item.folder_name}
</Checkbox>
                            </div>
                        </Col>
                    ))
                    }
                </Row>
                <div className='addRoleModalBtns'>
                        <Button className='addNewRoleCancelBtn' onClick={() => setFolderModal(false)}>Cancel</Button>
                        <Button className='addNewRoleSaveBtn' onClick={FolderSubmit}>Save</Button>
                    </div>
                {/* </div> */}
            </Modal>
            <Row>
                <Col span={10}>
                    <h3 className='sideMainHeading'>User Setup</h3>
                </Col>
                <Col span={6}>
                    <Input placeholder='Search' className='searchInput'

                    />
                    <SearchOutlined className='inputSearch' />
                </Col>
                <Col span={4}>
                    <div className='filterClass'>
                        <img src='/Icons/filter.svg' width={20} height={20} alt='' />
                        <h3 className='filterWord'>Filter</h3>
                    </div>
                </Col>
                {
                    checkElementUpdatePresence(USER_ACCESS, 6) ?
                <Col span={3}>
                    <Button className='addNewUserBtn' onClick={openAddRoleModal}><PlusOutlined className='plusIconClass' /> Add Job Role</Button>
                </Col>
                    :
                    <></>
                }
            </Row>
            <Row className='userManagementTableRow'>
                <Col span={24}>
                    {
                        Loading ?
                            <Skeleton active />
                            :
                            <Table dataSource={Data} className='userManagementTable'>
                                <Column title="S no" dataIndex="s.no" key="s.no" align='center' />

                                <Column title="Job Role" dataIndex="job_name" key="job_name" align='center'
                                    render={(text) => (
                                        <Space size='middle'>
                                            <h3 className='tableNormalText'>{text}</h3>
                                        </Space>
                                    )}
                                />

                                <Column
                                    title="Folder Acess"
                                    key="action"
                                    align='center'
                                    render={(_, record) => (

                                        <Button
                                            style={{ width: 230 }}
                                            onClick={() => handleEditNavigate(record)}>Select</Button>

                                    )}
                                />
                            </Table>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default UserSetup