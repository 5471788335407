// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatbotCards.ant-card-bordered{
    border: 1px solid #CBCBCB !important;
    border-radius: 5px;
}
.ChatbotCards{
    background-color: rgba(247, 250, 253, 1);
}
.CreateChatbot {
    height: 35px;
    gap: 16px;
    background-color: #ed1c24;
    color: #fff;
}

.CreateChatbot:hover {
    color: #ed1c24 !important;
    background-color: #fff !important;
    border: 1px solid #ed1c24 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/AiAssistants/Chatbot.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,kBAAkB;AACtB;AACA;IACI,wCAAwC;AAC5C;AACA;IACI,YAAY;IACZ,SAAS;IACT,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,iCAAiC;IACjC,oCAAoC;AACxC","sourcesContent":[".ChatbotCards.ant-card-bordered{\n    border: 1px solid #CBCBCB !important;\n    border-radius: 5px;\n}\n.ChatbotCards{\n    background-color: rgba(247, 250, 253, 1);\n}\n.CreateChatbot {\n    height: 35px;\n    gap: 16px;\n    background-color: #ed1c24;\n    color: #fff;\n}\n\n.CreateChatbot:hover {\n    color: #ed1c24 !important;\n    background-color: #fff !important;\n    border: 1px solid #ed1c24 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
