// Finding URL from the response 
export function urlify(text) {
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;;
    return text.replace(urlRegex, function(url) {
      // return '<a href="' + url + '" target="_blank">' + url + '</a>';
      return '<a href="' + url + '" target="_blank">Click here to go</a>';
    })
  }

 export const parseUrlsToLinks = (text) => {
    return text.replace(/(https?:\/\/[^\s]+)/g, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  export const checkElementUpdatePresence = (userAccess, elements) => {
  
  if (!Array.isArray(elements)) {
    elements = [elements]; // Convert single element to array
  }
  console.log(elements.some(element => userAccess.includes(element)), 'checkElementPresence', userAccess)
  return elements.some(element => userAccess.includes(element));

  };