import { Button, Col, Input, Row, Select, DatePicker, message } from 'antd'
import React, { useState, useEffect } from 'react'
// import './AddUser.css'
import { Switch, Upload } from 'antd'
import { useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';
import { AppUrl, REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from '../../../Constants/Constants';
import axios from 'axios';
import AWS from 'aws-sdk';
import './AddCompany.css'

const designationOptions = [
    {
        value: 'application admin',
        label: 'Application Admin',
    }
]

const AddCompany = () => {
    const navigate = useNavigate();

    const [status, setStatus] = useState(true)
    const [companyName, setCompanyName] = useState('')
    const [FirstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [Email, setEmail] = useState('')
    const [Designation, setDesignation] = useState('')
    const [jobRole, setJobRole] = useState('application-admin');
    const [data, setData] = useState([]);
    const [file, setfile] = useState({});
    const [arrayFiles, setarrayFiles] = useState("");
    const [companyId, setCompanyId] = useState('');
    const [clickedSave, setClickSave] = useState(false);

    console.log(companyId, 'companyId')
    const dispatch = useDispatch();

    const AUTH_TOKEN = useSelector(state => state.authToken);


    const s3 = new AWS.S3();

    AWS.config.update({
        region: 'us-east-1',
        credentials: new AWS.Credentials({
            accessKeyId: REACT_APP_ACESS_KEY_ID,
            secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
            s3ForcePathStyle: true,
        }),
    });

    useEffect(() => {

        axios.post(`${AppUrl}get_company_id`, {
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                console.log(res.data, 'poojaKumari')

                if (res.response != '') {
                    setCompanyId(res.data.response);
                } else {
                    navigate('/login');
                }
            })

    }, [])

    useEffect(() => {

        console.log(file, "file")

        if (file.length > 0) {

            const fileNames = file.map((i) => `https://novatris.s3.amazonaws.com/Company/${companyId}/${i.name}`);
            console.log(fileNames, "arrayfiles")
            setarrayFiles(fileNames[0]);
        }


    }, [file]);


    console.log(jobRole, "jobRole", Email, "mail")


    useEffect(() => {
        axios.get(`${AppUrl}get_company`, {
            headers: {
                Authorization: `Bearer ${AUTH_TOKEN}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                console.log(res, 'responseGetCompany')
            }).catch((err) => {
                console.log(err.message)
            })
    }, [])


    useEffect(() => {
        const savedData = localStorage.getItem('UserDatass');
        if (savedData) {
            setData(JSON.parse(savedData));
        }
    }, []);

    const saveCompany = () => {

        setClickSave(true);
        if (FirstName != '' && lastName != '' && Email != '' && companyName != '') {

            if (file.length > 0) {

                const uploadPromises = file.map((file) => {
                    const params = {
                        Bucket: 'novatris',
                        Key: `Company/${companyId}/${file.name}`,
                        Body: file,
                    };

                    return new Promise((resolve, reject) => {
                        s3.upload(params, { partSize: 5 * 1024 * 1024, queueSize: 1 })
                            .on('httpUploadProgress', (progress) => {
                                const uploadedPercentage = Math.round((progress.loaded / progress.total) * 100);
                                // setPercent(uploadedPercentage);
                            })
                            .send((err, data) => {
                                if (err) {
                                    reject(err);
                                } else {
                                    resolve(data);

                                    // setParts(updatedParts);
                                    console.log(data.Location, "chan")
                                }
                            });
                    });
                });

                Promise.all(uploadPromises)
                    .then(() => {
                        console.log(uploadPromises, "reached1");

                        let body = {
                            f_name: FirstName,
                            l_name: lastName,
                            email: Email,
                            company: companyName,
                            role: jobRole,
                            logo: arrayFiles,
                            status: status,
                            company_id: companyId
                        }


                        axios.post(`${AppUrl}add_company`, body, {
                            headers: {
                                Authorization: `Bearer ${AUTH_TOKEN}`,
                                Accept: 'application/json'
                            }
                        })

                            .then((res) => {
                                console.log(res, 'addCompanyPost');
                                if (res.data.status === 200) {
                                    message.success('Company Registered!');
                                    setTimeout(() => {
                                        navigate('/company')
                                    }, 2000)
                                } else {
                                    message.success(`Can't create company. Please try again!`);

                                    setTimeout(() => {
                                        navigate('/company')
                                    }, 2000)
                                }
                            })


                    })
                    .catch((err) => {
                        console.log(err, "upload err");
                    });


            }
            else {

                let body = {
                    f_name: FirstName,
                    l_name: lastName,
                    email: Email,
                    company: companyName,
                    role: jobRole,
                    logo: arrayFiles,
                    status: status,
                    company_id: companyId
                }


                axios.post(`${AppUrl}add_company`, body, {
                    headers: {
                        Authorization: `Bearer ${AUTH_TOKEN}`,
                        Accept: 'application/json'
                    }
                })

                    .then((res) => {
                        console.log(res, 'addCompanyPost');
                        if (res.data.status === 200) {
                            message.success('Company Registered!');
                            setTimeout(() => {
                                navigate('/company')
                            }, 2000)
                        } else {
                            message.success(`Can't create company. Please try again!`);

                            setTimeout(() => {
                                navigate('/company')
                            }, 2000)
                        }
                    })

            }
        }


    };

    console.log(arrayFiles, "arrayfiles")


    const handleCancel = () => {
        navigate('/company')
    }


    const switchChange = (e) => {
        console.log(`switch to ${e}`);
        setStatus(e);
    }

    return (
        <div className='addUserClass' >
            <Row>
                <Col span={10}>
                    <h3 className='sideMainHeading'>Add New Company</h3>
                </Col>
                <Col offset={10} >
                    <div className='statusWholeDiv'>
                        <h3 className='addUserStatustext'>Status</h3>
                        <Switch className='addUserSwitchClass' defaultChecked onChange={switchChange} />
                        <p style={{ color: status === 'Inactive' ? '#ED1C24' : '#16ab00' }}>{status === true ? 'Active' : 'Inactive'}</p>
                    </div>
                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3
                        className='addUserFormtext'>Company Name</h3>
                </Col>
                <Col span={13}>
                    <Input
                        onChange={(e) => setCompanyName(e.target.value)}
                        className='addUserInput' placeholder='Enter your Company Name' />
                    {
                        clickedSave === true && companyName === '' ?
                            <p className='fieldMissErr'>Please fill out the field</p>
                            :
                            <></>
                    }
                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3
                        className='addUserFormtext'>First Name</h3>
                </Col>
                <Col span={13}>
                    <Input
                        onChange={(e) => setFirstName(e.target.value)}
                        className='addUserInput' placeholder='Enter your First Name' />
                    {
                        clickedSave === true && FirstName === '' ?
                            <p className='fieldMissErr'>Please fill out the field</p>
                            :
                            <></>
                    }
                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3
                        className='addUserFormtext'>Last Name</h3>
                </Col>
                <Col span={13}>
                    <Input
                        onChange={(e) => setLastName(e.target.value)}
                        className='addUserInput' placeholder='Enter your Last Name' />
                    {
                        clickedSave === true && lastName === '' ?
                            <p className='fieldMissErr'>Please fill out the field</p>
                            :
                            <></>
                    }
                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3
                        className='addUserFormtext'>Email Id</h3>
                </Col>
                <Col span={13}>
                    <Input

                        onChange={(e) => setEmail(e.target.value)}
                        className='addUserInput' placeholder='Enter your Email Id' />
                    {
                        clickedSave === true && Email === '' ?
                            <p className='fieldMissErr'>Please fill out the field</p>
                            :
                            <></>
                    }

                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3

                        className='addUserFormtext'>Role</h3>
                </Col>
                <Col span={13}>
                    <Select
                        onChange={(e) => setJobRole(e)}
                        className='addUserSelect' defaultValue={jobRole} options={designationOptions} placeholder='Select Role' />
                </Col>
            </Row>
            <Row className='addUserForm'>
                <Col span={3}>
                    <h3

                        className='addUserFormtext'>Logo</h3>
                </Col>
                <Col span={13}>
                    <Upload
                        maxCount={1}
                        onRemove={() => setarrayFiles('')}
                        customRequest={({ onSuccess }) => onSuccess("ok")}
                        onChange={(info) => {
                            console.log(info, "info")
                            const fileObj = info.fileList.map((item) => item.originFileObj);
                            setfile(fileObj)
                        }}
                    >
                        <Button className='logoUpload' icon={<UploadOutlined className='uploadLogoIcon' />}>Upload</Button>
                    </Upload>
                </Col>
            </Row>
            <Row className='addCancelSaveBtnRow'>
                <Col span={3}>
                    <Button className='userCancelBtn' onClick={handleCancel}>Cancel</Button>
                </Col>
                <Col offset={1} span={3}>
                    <Button onClick={saveCompany} className='addNewUserBtn' >Save</Button>
                </Col>
            </Row>
        </div>
    )
}

export default AddCompany