import React, { useState, useEffect } from 'react'
import './Login.css'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Input, Button, message, Typography } from 'antd'
import { useDispatch } from 'react-redux';
import { authToken, loginUser } from '../../Redux/Actions';
import {
    // googleLogout, 
    useGoogleLogin
} from '@react-oauth/google';
import axios from 'axios';
import { AppUrl } from '../../Constants/Constants';
import getUserInfo from './auth'
import Auth from './auth';

const Login = () => {


    const { Title } = Typography;
    //google login 
    const navigate = useNavigate();
    const [user, setUser] = useState([]);
    const [Email, setEmail] = useState('')
    const [error, setError] = useState('');
    const [OTPVisible, setOTPVisible] = useState(false)
    const [OTP, setOTP] = useState('')
    const [UserOTP, setUserOTP] = useState('')
    const [Loading, setLoading] = useState(false)

    const [profile, setProfile] = useState([]);

    const dispatch = useDispatch();

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            setUser(codeResponse)
            const loginDetails = setUser(codeResponse);
            // setUser(loginDetails.name); 
        },
        onError: (error) => console.log('Login Failed:', error)
    });
    useEffect(() => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setProfile(res.data);
                    console.log(res.data, "responseData")
                    localStorage.setItem("Profile", JSON.stringify(res.data));
                    localStorage.setItem("IsAuthenticated", "true");
                    let body = {
                        email: res.data.email,
                        name: res.data.name,
                        id: res.data.id,
                        picture: res.data.picture
                    }
                    axios
                        .post(`${AppUrl}google_login`, body)
                        .then((res) => {
                            console.log(res, "response11")
                            if(res.data.status === 200){
                                dispatch(authToken(res.data.token));
                                dispatch(loginUser(res.data));
                                navigate('/')
                            }else{
                                message.error('Email id not registered')
                            }
                         

                        })
                        .catch((err) => console.log(err))

                })
                .catch((err) => console.log(err));
        }
    },
        [user])

    const handleSubmit = (e) => {
        if (e == 'mail') {
            if (Email === '') {
                setError('Email is required');
            } else if (!validateEmail(Email)) {
                setError('Invalid email format');
            } else {
                setError('');
                setLoading(true)
                let body = {
                    email: Email
                };
                axios
                    .post(`${AppUrl}login`, body)
                    .then((res) => {
                        console.log(res,"chan");
                        if (res.data.status === 200) {
                            setOTPVisible(true);
                            message.success(res.data.response)
                            dispatch(authToken(res.data.token));
                            dispatch(loginUser(res.data));
                            setProfile(res.data);
                            setLoading(false)
                            localStorage.setItem("Profile", JSON.stringify(res.data));
                            localStorage.setItem("IsAuthenticated", "true");
                            setOTP(res.data.otp)
                        } else {
                            message.error(res.data.response);
                            setLoading(false)
                        }
                    })
                    .catch((err) => {
                        message.error(err.message);
                        setLoading(false)
                    });
            }

        } else {
            if (parseInt( UserOTP) === OTP) {
                message.success('User Loggedin successfully')
                navigate('/')

            } else {
                message.error('Invalid OTP');
            }
        }
    }

    const onChange = (text) => {
        console.log('onChange:', text);
        setUserOTP(text)
      };
      const sharedProps = {
        onChange,
      };
    // console.log(UserOTP,"userOTP")

    return (
        <div className='Login-main'>
            <Row>
                <Col span={14}>
                    <div className='Welcome-Div'>

                        <h1 className='WelcomeTxt'>
                            Welcome
                        </h1>
                        <p className='contentTxt'>
                            Welcome to our knowledge management platform, where<br />
                            creativity and information collide.
                        </p>
                    </div>
                    <img className='LoginImg' src='/Logo/Login_image.svg' alt='Logo' />
                </Col>
                <Col span={10}>
                    <div className='logindiv'>

                        <div className='logoDiv'>

                            <img className='logo' src='/Logo/regeneron.png' alt='Logo' width={200} height={40} />
                        </div>

                        {
                            OTPVisible ?
                                <>
                                    <h3 style={{ justifyContent: 'center', display: 'flex', fontSize: 16, marginTop: '7%' }}>
                                        Enter OTP
                                    </h3>
                                    <Input.OTP className='OTPinput' {...sharedProps}  />
                                    <Button onClick={() => handleSubmit("otp")} className='ContinueBtn'>
                                        Continue
                                    </Button>
                                </>     
                                :
                                <>
                                    <h3 style={{ justifyContent: 'center', display: 'flex', fontSize: 16, marginTop: '7%' }}>
                                        Sign In
                                    </h3>
                                    <Input placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} className='loginInput' />
                                    {error && <p className='error'>{error}</p>}
                                    <Button onClick={() => handleSubmit("mail")} loading={Loading} className='ContinueBtn'>
                                        Continue
                                    </Button>
                                </>
                        }


                        <h3 style={{ justifyContent: 'center', display: 'flex', fontSize: 16, marginTop: 22 }}>
                            Or continue with
                        </h3>
                        <div>

                            <Button onClick={() => login()} className='SSOBtn' type=''>

                                Sign in with SSO
                            </Button>
                            {/* <Auth/> */}
                            <img className='CloudImg' src='/CloudKey.svg' alt='Logo' />
                        </div>
                        <div className='logoDiv'>

                            <Button className='troubleBtn' type='link'>
                                Facing trouble in Signing In?
                            </Button>
                        </div>
                        <div className='logoDiv'>

                            <p className='privacyPolicy'>
                                This page is protected by reCAPTCHA and the Google
                                <br />
                                <Button className='privacyPloicyBtn' type='link'>
                                    Privacy Policy
                                </Button>
                                and
                                <Button className='privacyPloicyBtn' type='link'>
                                    Terms of Service
                                </Button>
                                apply.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Login