// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lottie-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .lottie-animation {
    width: 600px; /* Adjust size as needed */
    /* height: 500px; */
    margin-bottom: 20px; /* Space between animation and button */
  }
  
  .button-container {
    margin-top: 20px; /* Adjust the distance between the Lottie animation and the button */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/SessionTimeout/SessionTimeout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,YAAY,EAAE,0BAA0B;IACxC,mBAAmB;IACnB,mBAAmB,EAAE,uCAAuC;EAC9D;;EAEA;IACE,gBAAgB,EAAE,oEAAoE;EACxF","sourcesContent":[".lottie-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .lottie-animation {\n    width: 600px; /* Adjust size as needed */\n    /* height: 500px; */\n    margin-bottom: 20px; /* Space between animation and button */\n  }\n  \n  .button-container {\n    margin-top: 20px; /* Adjust the distance between the Lottie animation and the button */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
