// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.approvalBtn{
    color: #fff;
    background-color: #ED1C24;
    font-size: 16px;
    font-weight: 700;
    height: 36px;
    border-radius: 9px;
    width: 50%;
    margin-top: 10px;
    float: right;
}`, "",{"version":3,"sources":["webpack://./src/Components/Document/Documents.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".approvalBtn{\n    color: #fff;\n    background-color: #ED1C24;\n    font-size: 16px;\n    font-weight: 700;\n    height: 36px;\n    border-radius: 9px;\n    width: 50%;\n    margin-top: 10px;\n    float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
