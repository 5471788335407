export const initialData = [
  {
    id : 1,
    pageName : 'Dashboard',
    description :'This is dashboard',
    view : false,
    update : false
  },
  // {
  //   id : 2,
  //   pageName : 'Company', 
  //   description : 'This is company',
  //   view : false,
  //   update : false
  // },
  {
    id : 3,
    pageName : 'User', 
    description : 'This is user',
    view : false,
    update : false
  },
  {
    id : 4,
    pageName : 'User List',
    description : 'This is user list',
    view : false,
    update : false
  },
  {
    id: 5,
    pageName: 'Role Management',
    description : 'This is Role Management',
    view : false,
    update : false
  },
  {
    id: 6,
    pageName: 'User Setup',
    description : 'This is User Setup',
    view : false,
    update : false
  },
  {
    id: 7,
    pageName: 'Workflow',
    description : 'This is Workflow',
    view : false,
    update : false
  },
  {
    id: 8,
    pageName: 'Libraries',
    description : 'This is Libraries',
    view : false,
    update : false
  },
  {
    id: 9,
    pageName: 'Folder',
    description : 'This is Folder',
    view : false,
    update : false
  },
  {
    id: 10,
    pageName: 'Archives',
    description : 'This is Archives',
    view : false,
    update : false
  },
  {
    id: 11,
    pageName: 'AI Assistants',
    description : 'This is AI Assistants',
    view : false,
    update : false
  },
  {
    id: 12,
    pageName: 'Smart Buddy',
    description : 'This is Smart Buddy',
    view : false,
    update : false
  },
  {
    id: 13,
    pageName: 'Create New Smart Buddy',
    description : 'This is Create New Smart Buddy',
    view : false,
    update : false
  },
  {
    id: 14,
    pageName: 'Ready to publish',
    description : 'This is Ready to publish',
    view : false,
    update : false
  },
  {
    id: 15,
    pageName: 'Add file',
    description : 'This is Add file',
    view : false,
    update : false
  },{
    id: 16,
    pageName: 'Create Bot',
    description : 'This will cerate a bot',
    view : false,
    update : false
  },
]

export const applicationAdminData = [
  {
    id : 1,
    pageName : 'Dashboard',
    description :'This is dashboard',
    view : true,
    update : false
  },
  {
    id : 2,
    pageName : 'Company', 
    description : 'This is company',
    view : false,
    update : false
  },
  {
    id : 3,
    pageName : 'User', 
    description : 'This is user',
    view : true,
    update : true
  },
  {
    id : 4,
    pageName : 'User List',
    description : 'This is user list',
    view : true,
    update : true
  },
  {
    id: 5,
    pageName: 'Role Management',
    description : 'This is Role Management',
    view : true,
    update : true
  },
  {
    id: 6,
    pageName: 'User Setup',
    description : 'This is User Setup',
    view : true,
    update : true
  },
  {
    id: 7,
    pageName: 'Workflow',
    description : 'This is Workflow',
    view : true,
    update : false
  },
  {
    id: 8,
    pageName: 'Libraries',
    description : 'This is Libraries',
    view : true,
    update : false
  },
  {
    id: 9,
    pageName: 'Folder',
    description : 'This is Folder',
    view : true,
    update : false
  },
  {
    id: 10,
    pageName: 'Archives',
    description : 'This is Archives',
    view : true,
    update : false
  },
  {
    id: 11,
    pageName: 'AI Assistants',
    description : 'This is AI Assistants',
    view : true,
    update : false
  },
  {
    id: 12,
    pageName: 'Smart Buddy',
    description : 'This is Smart Buddy',
    view : true,
    update : false
  },
  {
    id: 13,
    pageName: 'Create New Smart Buddy',
    description : 'This is Create New Smart Buddy',
    view : true,
    update : false
  },
  {
    id: 14,
    pageName: 'Ready to publish',
    description : 'This is Ready to publish',
    view : true,
    update : false
  },
  {
    id: 15,
    pageName: 'Add file',
    description : 'This is Add file',
    view : true,
    update : false
  },{
    id: 16,
    pageName: 'Create Bot',
    description : 'This will cerate a bot',
    view : true,
    update : false
  },
]

export const permissionData = [
    {
      id: 1,
      role: 'Dashboard',
      access: false
    },
    {
      id: 2,
      role: 'Company',
      access: false
    },
    {
      id: 3,
      role: 'User',
      access: false
    },
    {
      id: 4,
      role: 'User List',
      access: false
    },
    {
      id: 5,
      role: 'Role Management',
      access: false
    },
    {
      id: 6,
      role: 'User Setup',
      access: false
    },
    {
      id: 7,
      role: 'Workflow',
      access: false
    },
    {
      id: 8,
      role: 'Libraries',
      access: false
    },
    {
      id: 9,
      role: 'Folder',
      access: false
    },
    {
      id: 10,
      role: 'Archives',
      access: false
    },
    {
      id: 11,
      role: 'AI Assistants',
      access: false
    },
    {
      id: 12,
      role: 'Smart Buddy',
      access: false
    },
    {
      id: 13,
      role: 'Create New Smart Buddy',
      access: false
    },
    {
      id: 14,
      role: 'Ready to publish',
      access: false
    },
  ];

  export const fullAccessData = [
    {
      id : 1,
      pageName : 'Dashboard',
      description :'This is dashboard',
      view : true,
      update : true
    },
    // {
    //   id : 2,
    //   pageName : 'Company', 
    //   description : 'This is company',
    //   view : true,
    //   update : true
    // },
    {
      id : 3,
      pageName : 'User', 
      description : 'This is user',
      view : true,
      update : true
    },
    {
      id : 4,
      pageName : 'User List',
      description : 'This is user list',
      view : true,
      update : true
    },
    {
      id: 5,
      pageName: 'Role Management',
      description : 'This is Role Management',
      view : true,
      update : true
    },
    {
      id: 6,
      pageName: 'User Setup',
      description : 'This is User Setup',
      view : true,
      update : true
    },
    {
      id: 7,
      pageName: 'Workflow',
      description : 'This is Workflow',
      view : true,
      update : true
    },
    {
      id: 8,
      pageName: 'Libraries',
      description : 'This is Libraries',
      view : true,
      update : true
    },
    {
      id: 9,
      pageName: 'Folder',
      description : 'This is Folder',
      view : true,
      update : true
    },
    {
      id: 10,
      pageName: 'Archives',
      description : 'This is Archives',
      view : true,
      update : true
    },
    {
      id: 11,
      pageName: 'AI Assistants',
      description : 'This is AI Assistants',
      view : true,
      update : true
    },
    {
      id: 12,
      pageName: 'Smart Buddy',
      description : 'This is Smart Buddy',
      view : true,
      update : true
    },
    {
      id: 13,
      pageName: 'Create New Smart Buddy',
      description : 'This is Create New Smart Buddy',
      view : true,
      update : true
    },
    {
      id: 14,
      pageName: 'Ready to publish',
      description : 'This is Ready to publish',
      view : true,
      update : true
    },
    {
      id: 15,
      pageName: 'Add file',
      description : 'This is Add file',
      view : true,
      update : true
    },
    {
      id: 16,
      pageName: 'Create Bot',
      description : 'This will cerate a bot',
      view : true,
      update : true
    },
  ];
  export const superAdminData = [
    {
      id : 1,
      pageName : 'Dashboard',
      description :'This is dashboard',
      view : true,
      update : true
    },
    {
      id : 2,
      pageName : 'Company', 
      description : 'This is company',
      view : true,
      update : true
    },
    {
      id : 3,
      pageName : 'User', 
      description : 'This is user',
      view : false,
      update : false
    },
    {
      id : 4,
      pageName : 'User List',
      description : 'This is user list',
      view : false,
      update : false
    },
    {
      id: 5,
      pageName: 'Role Management',
      description : 'This is Role Management',
      view : false,
      update : false
    },
    {
      id: 6,
      pageName: 'User Setup',
      description : 'This is User Setup',
      view : false,
      update : false
    },
    {
      id: 7,
      pageName: 'Workflow',
      description : 'This is Workflow',
      view : false,
      update : false
    },
    {
      id: 8,
      pageName: 'Libraries',
      description : 'This is Libraries',
      view : false,
      update : false
    },
    {
      id: 9,
      pageName: 'Folder',
      description : 'This is Folder',
      view : false,
      update : false
    },
    {
      id: 10,
      pageName: 'Archives',
      description : 'This is Archives',
      view : false,
      update : false
    },
    {
      id: 11,
      pageName: 'AI Assistants',
      description : 'This is AI Assistants',
      view : false,
      update : false
    },
    {
      id: 12,
      pageName: 'Smart Buddy',
      description : 'This is Smart Buddy',
      view : false,
      update : false
    },
    {
      id: 13,
      pageName: 'Create New Smart Buddy',
      description : 'This is Create New Smart Buddy',
      view : false,
      update : false
    },
    {
      id: 14,
      pageName: 'Ready to publish',
      description : 'This is Ready to publish',
      view : false,
      update : false
    },
    {
      id: 15,
      pageName: 'Add file',
      description : 'This is Add file',
      view : false,
      update : false
    },
    {
      id: 16,
      pageName: 'Create Bot',
      description : 'This will cerate a bot',
      view : false,
      update : false
    },
  ];